import {ApplicationState} from "../index";
import {RelativeDto, RelativeState} from "./types";
import {createSelector} from "reselect";

export const selectRelative = (state: ApplicationState): RelativeState => state.relative;

export const isRelativeDataComplete = (state: RelativeState): boolean => {
    return Object.keys(state).reduce((prev: boolean, key: string) => {
        if (!prev) {
            return prev;
        }
        // @ts-ignore
        return !!state[key];
    }, true);
};

const mapRelativeToServer = (state: RelativeState): RelativeDto => {
    const { firstName, lastName, phone, date } = state;
    return {
        firstName,
        lastName,
        phoneNumber: phone,
        date
    };
};

export const isRelativeDataValid = (state: RelativeState): boolean => {
    return !!(state.firstName && state.lastName);
};

export const relativeDataComplete = createSelector(
    selectRelative,
    isRelativeDataValid
);

export const getRelativeDataDto = createSelector(
    selectRelative,
    mapRelativeToServer
);
