import React from 'react';
import LogoIcon from '../../assets/img/app_logo.png';
import LanguageSelectorComponent from "../LanguageSelector";

const HeaderComponent: React.FC = () => {
    return (
        <header className="pt-10 p-relative">
            <div className="logo-wrapper flex flex-item--fullwidth justify-center">
                <img src={LogoIcon} alt=""/>
            </div>
            <div className="header-language-selector pt-10 pt-0-md no-print">
                <LanguageSelectorComponent />
            </div>
        </header>
    );
};

export default HeaderComponent;
