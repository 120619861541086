import {SurveyActionTypes, SurveyAnswersState, SurveyDataState, SurveyState, SurveyTypes} from "./types";

const surveyState: SurveyState = {
    isQuestionnaireFetched: false,
    errorMsg: '',
    isFailedFetchingData: false,
    isFailedSavingData: false,
    isFetching: false,
    isAnswersSavedSuccessfully: false,
    showFreeResults: false
};

const surveyDataState: SurveyDataState = {
    sections: [],
    questionerId: ''
};

const answersInitialState: SurveyAnswersState = {};
// const answersInitialState: SurveyAnswersState = {
//   '66264cf3dfd4c35ced7c59c6': 'no',
//   '66264d7fdfd4c35ced7c59c9': 'yes-somewhat-sometimes',
//   '66264dafdfd4c35ced7c59cc': 'yes-pronounced-often',
//   '66264deddfd4c35ced7c59cf': 'dont-know-cant-be-assessed',
//   '66264e17dfd4c35ced7c59d2': 'yes-pronounced-often',
//   '66264e44dfd4c35ced7c59d5': 'yes-pronounced-often',
//   '66264e74dfd4c35ced7c59d8': 'yes-pronounced-often',
//   '66264e9edfd4c35ced7c59db': 'yes-pronounced-often',
//   '66264ed0dfd4c35ced7c59de': 'yes-somewhat-sometimes',
//   '66264ef5dfd4c35ced7c59e1': 'yes-somewhat-sometimes',
//   '66265134dfd4c35ced7c59e4': 'no',
//   '662651f1dfd4c35ced7c59e7': 'yes-pronounced-often',
//   '66265267dfd4c35ced7c59ed': 'dont-know-cant-be-assessed',
//   '66265245dfd4c35ced7c59ea': 'yes-somewhat-sometimes',
//   '66265288dfd4c35ced7c59f0': 'yes-somewhat-sometimes',
//   '662652a6dfd4c35ced7c59f3': 'dont-know-cant-be-assessed',
//   '662652c6dfd4c35ced7c59f6': 'no',
//   '662652ebdfd4c35ced7c59f9': 'yes-somewhat-sometimes',
//   '6626532edfd4c35ced7c59fc': 'yes-pronounced-often',
//   '6626536edfd4c35ced7c59ff': 'dont-know-cant-be-assessed',
//   '66265391dfd4c35ced7c5a02': 'dont-know-cant-be-assessed',
//   '662653c3dfd4c35ced7c5a05': 'yes-pronounced-often',
//   '662653eddfd4c35ced7c5a08': 'yes-somewhat-sometimes',
//   '66265416dfd4c35ced7c5a0b': 'no',
//   '66265438dfd4c35ced7c5a0e': 'yes-somewhat-sometimes',
//   '66265470dfd4c35ced7c5a11': 'no',
//   '66265497dfd4c35ced7c5a14': 'yes-somewhat-sometimes',
//   '6626550cdfd4c35ced7c5a17': 'yes-pronounced-often',
//   '66265523dfd4c35ced7c5a1a': 'dont-know-cant-be-assessed',
//   '66265539dfd4c35ced7c5a1d': 'yes-pronounced-often',
//   '66265553dfd4c35ced7c5a20': 'no',
//   '66265570dfd4c35ced7c5a23': 'yes-somewhat-sometimes',
//   '66265582dfd4c35ced7c5a26': 'yes-pronounced-often',
//   '66265594dfd4c35ced7c5a29': 'dont-know-cant-be-assessed',
//   '662655a4dfd4c35ced7c5a2c': 'yes-pronounced-often',
//   '662655b5dfd4c35ced7c5a2f': 'yes-somewhat-sometimes',
//   '662655c8dfd4c35ced7c5a32': 'no',
//   '662655e1dfd4c35ced7c5a35': 'yes-somewhat-sometimes',
//   '662655ffdfd4c35ced7c5a38': 'yes-somewhat-sometimes',
//   '66265627dfd4c35ced7c5a3b': 'yes-pronounced-often',
//   '66265637dfd4c35ced7c5a3e': 'yes-somewhat-sometimes',
//   '66265670dfd4c35ced7c5a44': 'no',
//   '66265647dfd4c35ced7c5a41': 'yes-pronounced-often',
//   '66265686dfd4c35ced7c5a47': 'yes-pronounced-often',
//   '6626569ddfd4c35ced7c5a4a': 'yes-pronounced-often',
//   '662656c0dfd4c35ced7c5a4d': 'yes-somewhat-sometimes',
//   '662656dcdfd4c35ced7c5a50': 'yes-pronounced-often',
//   '662656ffdfd4c35ced7c5a53': 'more-than-five-years-ago',
//   '6626574bdfd4c35ced7c5a56': 'sudden-change-thereafter-insidious-deterioration',
//   '662659b2dfd4c35ced7c5a59': 'often',
//   '66265a05dfd4c35ced7c5a5c': 'text4',
//   '66265a21dfd4c35ced7c5a5f': 'text5',
//   '66265a36dfd4c35ced7c5a62': 'text6',
//   '66265a4edfd4c35ced7c5a65': 'text7',
//   '66265a66dfd4c35ced7c5a68': 'text8',
//   '66265a7cdfd4c35ced7c5a6b': 'difficult-to-discuss-with-patienten',
//   '66265ad8dfd4c35ced7c5a6e': 'partner',
//   '66265b38dfd4c35ced7c5a71': 'what a big comment'
// };

export function SurveyReducer(
    state = surveyState,
    action: SurveyTypes
): SurveyState {
    switch(action.type) {
        case SurveyActionTypes.GET_SURVEY_DATA:
            return {
                ...state,
                isFetching: true
            };
        case SurveyActionTypes.SET_SURVEY_ERROR_MSG:
            return {
                ...state,
                errorMsg: action.errorMsg
            };
        case SurveyActionTypes.POST_SUCCESS_SURVEY_QUESTIONS:
            return {
                ...state,
                isQuestionnaireFetched: true,
                isFetching: false
            };
        case SurveyActionTypes.POST_FAIL_SURVEY_QUESTIONS:
            return {
                ...state,
                isFetching: false,
                isFailedFetchingData: true,
            };
        case SurveyActionTypes.SAVE_SURVEY_ANSWERS_TO_SERVER:
            return {
                ...state,
                isFetching: true,
                isFailedSavingData: false,
                errorMsg: '',
                showFreeResults: false
            };
        case SurveyActionTypes.POST_ANSWERS_TO_SERVER_SUCCESS:
            return {
                ...state,
                isQuestionnaireFetched: false,
                errorMsg: '',
                isFailedFetchingData: false,
                isFailedSavingData: false,
                isFetching: false,
                isAnswersSavedSuccessfully: true,
            };
        case SurveyActionTypes.POST_ANSWERS_TO_SERVER_FAIL:
            return {
                ...state,
                isFetching: false,
                isFailedSavingData: true,
            };

            // yield put(logout())
            // yield put(clearRelativeData())
            // yield put(clearAnswersState())
            // yield put(clearQuestionsState())
        case SurveyActionTypes.SHOW_FREE_RESULTS:
            return {
                ...state,
                isQuestionnaireFetched: false,
                errorMsg: '',
                isFailedFetchingData: false,
                isFailedSavingData: false,
                isFetching: false,
                showFreeResults: true,
            };
        default:
            return state;
    }
}

export function SurveyDataReducer(
    state = surveyDataState,
    action: SurveyTypes
): SurveyDataState {
    switch(action.type) {
        case SurveyActionTypes.SET_SURVEY_QUESTIONS:
            return {
                ...state,
                sections: action.data.sections,
                questionerId: action.data.id
            };
        case SurveyActionTypes.CLEAR_QUESTIONS:
            return {
                sections: [],
                questionerId: ''
            };
        default:
            return state;
    }
}

export function SurveyAnswersReducer(
    state = answersInitialState,
    action: SurveyTypes
): SurveyAnswersState {
    switch(action.type) {
        case SurveyActionTypes.ANSWER_REGULAR_QUESTION:
            return {
                ...state,
                [action.payload.id]: action.payload.value
            }
        case SurveyActionTypes.CLEAR_ANSWERS:
            return answersInitialState;
        default:
            return state;
    }
}

