import React from 'react'
import { useDispatch, useSelector } from "react-redux"
import { Redirect, RouteComponentProps, useHistory } from "react-router-dom"
import { saveSurveyAnswersToServer, sendTrackingEvent, showFreeResults } from "../../store/Survey/action"
import {
  getCurrentSurveyValidity,
  getEntireSurveyValidity,
  isQuestionnaireReady,
  sectionsSelector
} from "../../store/Survey/surveyStateSelectors"
import { translate } from "../../store/Translations/translations"
import LoaderComponent from "../../components/Loader"
import SectionsProgressBarComponent from "../../components/SectionsProgressBar"
import SurveySectionComponent from '../../components/SurveySection'
import { RouteNames } from "../../types/routes-pathes"
import ButtonToSearchPatientComponent from "../../components/StartPageToSearch"
import { resetNotification } from "../../store/App/action"
import { ApplicationState } from "../../store"
import { TrackingEventType } from "../../sdk";

interface RouteParams {
  sectionId: string;
}

const isFreeSurvey = window.location.hostname === "localhost" || window.location.host === "symtomenkaten.se" || window.location.host === "test-enkat.gerassolutions.com";

const SurveyPage: React.FC<RouteComponentProps<RouteParams>> = (props: RouteComponentProps<RouteParams>) => {
  const {match} = props
  const currentSectionId: string = match.params.sectionId
  const dispatch = useDispatch()
  const validity = useSelector(getEntireSurveyValidity)
  const sectionValidity = useSelector(getCurrentSurveyValidity)
  const sections = useSelector(sectionsSelector)
  const section = sections[+currentSectionId - 1] || null
  const hasPrev = +currentSectionId !== 1
  const hasNext = +currentSectionId < sections.length
  const sectionIds = sections.map((item) => item.slug)

  const isHTTPPending = useSelector((state: ApplicationState) => state.app.isHTTPPending)
  const isQuestionnaireFetched = useSelector(isQuestionnaireReady)
  const isAnswersSavedSuccessfully = useSelector((state: ApplicationState) => state.survey.isAnswersSavedSuccessfully)
  if (!isHTTPPending && isAnswersSavedSuccessfully) {
    return <Redirect to={RouteNames.FinalSuccess}/>
  }

  const history = useHistory()
  const body = document.querySelector('html') as HTMLElement
  const navigateToPrev = (): void => {
    if (hasPrev) {
      history.push(`${RouteNames.Survey}/${+currentSectionId - 1}`)
      body.scrollTo(0, 0)
    }
  }
  const navigateToNext = (): void => {
    if (hasNext && sectionValidity[section?.slug]) {
      history.push(`${RouteNames.Survey}/${+currentSectionId + 1}`)
      body.scrollTo(0, 0)
    }
  }
  const saveSurvey = (): void => {
    if (!validity) {
      return
    }
    dispatch(resetNotification())
    if (isFreeSurvey) {
      dispatch(showFreeResults())
      dispatch(sendTrackingEvent(TrackingEventType.FREE_SURVEY_COMPLETE))
      return
    }
    dispatch(saveSurveyAnswersToServer())
  }

  return (
    <div className="flex-item--grow">
      {
        isHTTPPending &&
        <LoaderComponent/>
      }
      {
        !isHTTPPending && isQuestionnaireFetched && section &&

        <div className="pt-20">
          <SectionsProgressBarComponent {...{sectionIds, currentSectionId: section.slug}} />
          <SurveySectionComponent section={section} navigateToPrev={hasPrev ? navigateToPrev : null}
                                  navigateToNext={hasNext ? navigateToNext : null}/>
          {!hasNext &&
            <div className="t_center pt-40">
              {validity &&
                <button className="t_uppercase main-cta_btn ph-40"
                        onClick={saveSurvey}
                        type="button">{translate('survey.buttons.save')}</button>
              }
              {!validity &&
                <button className="t_uppercase main-cta_btn cta-disabled ph-40"
                        type="button">{translate('survey.buttons.save')}</button>
              }
            </div>
          }
        </div>
      }
      {
        !isHTTPPending && !isQuestionnaireFetched &&
        <div className="t_center pv-40 flex-item--grow flex-item--fullwidth">
          <h1 className="h2 t_error">{translate('startPage.heading.noQuestionsFromServer')}</h1>
          <p className="h3 pv-40">{translate('startPage.heading.noQuestionsTip2')}</p>
          <ButtonToSearchPatientComponent/>
        </div>
      }
    </div>
  )
}

export default SurveyPage
