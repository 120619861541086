import React, { useEffect } from 'react';
import SymptomSurveyResults from "../../components/SymptomSurveyResults/SymptomSurveyResults";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../store";
import { getCurrentLanguage } from "../../store/Translations/translations";
import { QuestionerAnswersDTOOutput, QuestionerDTO, AnswerDTO, QuestionerType, ObjectId } from "../../sdk";
import './FreeResults.scss';
import useTranslate from "../../hooks/useTranslate";

const FreeResults: React.FC<any> = (): React.ReactElement => {
  const translateLocalazy = useTranslate()
  const surveyData = useSelector((state: ApplicationState) => state.surveyData);
  const relativeData = useSelector((state: ApplicationState) => state.relative);
  const surveyAnswersData = useSelector((state: ApplicationState) => state.surveyAnswers);
  const selectedLanguage = useSelector((state: ApplicationState) => getCurrentLanguage(state));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleDownloadPDF = () => {
    window.print();
  };

  // Map the data from Redux to the format expected by SymptomSurveyResults
  const mapToSymptomSurveyProps = () => {
    // Create the QuestionerDTO object from surveyData
    const questioner: QuestionerDTO = {
      id: surveyData.questionerId,
      sections: surveyData.sections,
      // Adding necessary properties for QuestionerDTO
      title: "",
      slug: "",
      kind: QuestionerType.SYMPTOM_SURVEY,
      description: "",
      image: "",
      screenOrder: []
    };

    // Map survey answers from object format to array format
    // Use the raw answer values without wrapping them in an object
    const answers: AnswerDTO[] = Object.entries(surveyAnswersData).map(([screen, value]) => ({
      screen: screen as ObjectId,
      value: value as any // Use the raw value directly
    }));

    // Create the QuestionerAnswersDTOOutput object
    const survey: QuestionerAnswersDTOOutput = {
      questioner,
      completed: true,
      answers,
      viewedParts: [],
      visibleToPatient: true,
      // Set current date
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString()
    };

    // Mock patient data
    const patient = {
      firstName: "",
      lastName: ""
    };

    const relative = {
      firstName: relativeData.firstName,
      lastName: relativeData.lastName,
      date: relativeData.date
    };

    return {
      survey,
      patient,
      relative,
      selectedLanguage,
      freeVersion: true
    };
  };

  const symptomSurveyProps = mapToSymptomSurveyProps();

  return (
    <div className="free-results-container">
      <div className="download-button-container no-print">
        <button onClick={handleDownloadPDF} className="download-pdf-button">
          {translateLocalazy("hcp.Download PDF")}
        </button>
      </div>
      <SymptomSurveyResults {...symptomSurveyProps} />
    </div>
  );
}

export default FreeResults;
