/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SavePdfForPatientDto } from '../models/SavePdfForPatientDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PdfService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Convert html to pdf
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public convertHtmlToPdf(
        requestBody: SavePdfForPatientDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/pdf/convert-to-pdf',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Save pdf for patient
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public savePdfForPatient(
        requestBody: SavePdfForPatientDto,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/pdf/save-pdf-for-patient',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
