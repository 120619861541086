/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUserByAdminDto } from '../models/CreateUserByAdminDto';
import type { FindUserAdminDto } from '../models/FindUserAdminDto';
import type { PatientProfileDTO } from '../models/PatientProfileDTO';
import type { UpdateUserByAdminDto } from '../models/UpdateUserByAdminDto';
import type { UserDto } from '../models/UserDto';
import type { UserFindResponseDto } from '../models/UserFindResponseDto';
import type { UserIdDto } from '../models/UserIdDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UserAdminService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get archived patients
     * @returns UserFindResponseDto
     * @throws ApiError
     */
    public getArchivedPatients(): CancelablePromise<UserFindResponseDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user-admin/archived',
        });
    }
    /**
     * @param id
     * @returns PatientProfileDTO
     * @throws ApiError
     */
    public getPatientProfile(
        id: string,
    ): CancelablePromise<PatientProfileDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user-admin/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Update patient
     * @param id
     * @param requestBody
     * @returns UserDto
     * @throws ApiError
     */
    public updatePatient(
        id: string,
        requestBody: UpdateUserByAdminDto,
    ): CancelablePromise<UserDto> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/user-admin/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Instantly delete already archived user
     * @param id
     * @returns UserIdDto
     * @throws ApiError
     */
    public deleteArchivedPatient(
        id: string,
    ): CancelablePromise<UserIdDto> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/user-admin/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @returns UserDto
     * @throws ApiError
     */
    public getSimplePatientProfile(
        id: string,
    ): CancelablePromise<UserDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user-admin/{id}/simple',
            path: {
                'id': id,
            },
        });
    }
    /**
     * Create new patient
     * @param requestBody
     * @returns UserDto
     * @throws ApiError
     */
    public createPatient(
        requestBody: CreateUserByAdminDto,
    ): CancelablePromise<UserDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user-admin',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns UserFindResponseDto
     * @throws ApiError
     */
    public findPatientsByAdmin(
        requestBody: FindUserAdminDto,
    ): CancelablePromise<UserFindResponseDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user-admin/find',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param id
     * @returns UserIdDto
     * @throws ApiError
     */
    public archivePatient(
        id: string,
    ): CancelablePromise<UserIdDto> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/user-admin/{id}/archive',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @returns UserIdDto
     * @throws ApiError
     */
    public restoreAchievedPatient(
        id: string,
    ): CancelablePromise<UserIdDto> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/user-admin/{id}/restore',
            path: {
                'id': id,
            },
        });
    }
}
