import { SectionDTO, TrackingEventType } from "../../sdk";

export enum SurveyActionTypes {
   GET_SURVEY_DATA = '@@survey/GET_SURVEY_DATA',
   SET_SURVEY_QUESTIONS = '@@survey/SET_SURVEY_QUESTIONS',
   POST_SUCCESS_SURVEY_QUESTIONS = '@@survey/POST_SUCCESS_SURVEY_QUESTIONS',
   POST_FAIL_SURVEY_QUESTIONS = '@@survey/POST_FAIL_SURVEY_QUESTIONS',
   SET_SURVEY_ERROR_MSG = '@@survey/SET_SURVEY_ERROR_MSG',
   ANSWER_REGULAR_QUESTION = '@@survey/ANSWER_REGULAR_QUESTION',
   SAVE_REGULAR_QUESTION_ANSWER = '@@survey/SAVE_REGULAR_QUESTION_ANSWER',
   SAVE_SURVEY_ANSWERS_TO_SERVER = '@@survey/SAVE_SURVEY_ANSWERS_TO_SERVER',
   SEND_TRACKING_EVENT = '@@survey/SEND_TRACKING_EVENT',
   SHOW_FREE_RESULTS = '@@survey/SHOW_FREE_RESULTS',
   CLEAR_ANSWERS = '@@survey/CLEAR_ANSWERS',
   CLEAR_QUESTIONS = '@@survey/CLEAR_QUESTIONS',
   POST_ANSWERS_TO_SERVER_SUCCESS = '@@survey/POST_ANSWERS_TO_SERVER_SUCCESS',
   POST_ANSWERS_TO_SERVER_FAIL = '@@survey/POST_ANSWERS_TO_SERVER_FAIL'
}

export interface SurveyState {
    isQuestionnaireFetched: boolean;
    errorMsg: string;
    isFailedFetchingData: boolean;
    isFetching: boolean;
    isFailedSavingData: boolean;
    isAnswersSavedSuccessfully: boolean;
    showFreeResults: boolean;
}

export interface SurveyDataState {
    sections: SectionDTO[];
    questionerId: string
}

export interface SurveyAnswersState {
    [key: string]: string;
}

export interface SurveyDataGet {
    type: SurveyActionTypes.GET_SURVEY_DATA;
}

export interface SurveyDataFetchSuccess {
    type: SurveyActionTypes.POST_SUCCESS_SURVEY_QUESTIONS;
}

export interface SurveyDataSet {
    type: SurveyActionTypes.SET_SURVEY_QUESTIONS;
    data: any;
}

export interface SurveyDataFetchFail {
    type: SurveyActionTypes.POST_FAIL_SURVEY_QUESTIONS;
}

export interface SurveyDataErrorMsg {
    type: SurveyActionTypes.SET_SURVEY_ERROR_MSG;
    errorMsg: string;
}

export interface AnswerRegularQuestion {
    type: SurveyActionTypes.ANSWER_REGULAR_QUESTION;
    payload: {id: string, value: string};
}

export interface SaveRegularQuestionAnswer {
    type: SurveyActionTypes.SAVE_REGULAR_QUESTION_ANSWER;
    payload: RegularAnswerPayload;
}

export interface SaveSurveyAnswersToServer {
    type: SurveyActionTypes.SAVE_SURVEY_ANSWERS_TO_SERVER;
}

export interface ShowFreeResults {
  type: SurveyActionTypes.SHOW_FREE_RESULTS;
}

export interface SendTrackingEvent {
  type: SurveyActionTypes.SEND_TRACKING_EVENT;
  payload: TrackingEventType
}

export interface ClearAnswersState {
    type: SurveyActionTypes.CLEAR_ANSWERS;
}

export interface ClearQuestionsState {
    type: SurveyActionTypes.CLEAR_QUESTIONS;
}

export interface SaveSurveyAnswersFail {
    type: SurveyActionTypes.POST_ANSWERS_TO_SERVER_FAIL;
}

export interface SaveSurveyAnswersSuccess {
    type: SurveyActionTypes.POST_ANSWERS_TO_SERVER_SUCCESS;
}

export interface RegularAnswerPayload {
    sectionId: string;
    questionId: string;
    questionKey: number;
    questionType: number;
    answerId: string;
    answerValue: number | string;
    answerText: string;
}

export type SurveyTypes = SurveyDataGet |
    SurveyDataSet |
    SurveyDataFetchSuccess |
    SurveyDataFetchFail |
    SurveyDataErrorMsg |
    AnswerRegularQuestion |
    SaveRegularQuestionAnswer |
    SaveSurveyAnswersToServer |
    ClearAnswersState |
    ClearQuestionsState |
    SaveSurveyAnswersFail |
    ShowFreeResults |
    SaveSurveyAnswersSuccess |
    SendTrackingEvent;
