/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ObjectId } from './ObjectId';
export type AppointmentDto = {
    id?: ObjectId;
    status: AppointmentDto.status;
    user?: ObjectId;
    bookingId?: string;
    date: string;
};
export namespace AppointmentDto {
    export enum status {
        RESERVED = 'reserved',
        PAID = 'paid',
    }
}

