const trans = {
  'accessDeniedPage.header': 'Du saknar tyvärr behörighet till denna sida',
  'accessDeniedPage.helpMessage': 'Vänligen kontrollera länken',
  'da': 'Danish',
  'date': 'Datum för ifyllande',
  'en': 'English',
  'errors.generalAuthPNRError': 'Detta personnummer kan inte användas för autentisering',
  'errors.generalAuthTokenError': 'Länken kan inte användas för autentisering',
  'errors.invalidOrNoToken': 'Länken är inte aktuell eller otillräcklig',
  'errors.invalidPNRError': 'Felaktigt personnummer eller felaktigt format',
  'errors.noCredsToSaveError': 'Otillräckliga eller ej korrekta uppgifter',
  'errors.noPatientError': 'Ingen information funnen',
  'errors.noPNRError': 'Inget personnummer inskrivet',
  'errors.noUserWithPNR': 'Ingen information hittas för detta personnummer',
  'errors.someDataIsNotValidError': 'Ej korrekta uppgifter',
  'errors.tokenWasAlreadyAnswered': 'Det finns redan ett svar för denna länk',
  'errors.unexpectedError': 'Ett oväntat fel inträffade',
  'errors.unknownError': 'Ett oväntat fel inträffade',
  'firstName': 'Ditt förnamn (anhörigs förnamn om du är vårdpersonal)',
  'form.error.emailFormat': 'Vänligen kontrollera e-post',
  'form.error.mustNotEmpty': 'Fältet kan inte vara tomt',
  'form.error.persNumberFormat': 'Vänligen kontrollera personnumret',
  'form.hint.fieldIsOptional': 'Valfritt',
  'form.hint.mustNotEmpty': 'Obligatorisk',
  'form.placeholder.select': 'Välj ditt svar...',
  'form.placeholder.typeHere': 'Skriv här...',
  'hcp.Absence attack': 'Frånvaroattacker',
  'hcp.account.search.placeholder': 'Sök genom att skriva namn...',
  'hcp.accounts': 'Konton',
  'hcp.actions': 'Handlingar',
  'hcp.activateTestsInApp': 'Aktivera i app',
  'hcp.Add care provider for referral / consultation': 'Använd vårdgivar-id för remittering (t.ex. GSAPP)',
  'hcp.Add comment': 'Lägg till anteckning och signera',
  'hcp.Add note': 'Lägg till anteckning',
  'hcp.Add recommendation': 'Lägg till rekommendation',
  'hcp.Add summary': 'Lägg till anteckning',
  'hcp.Add-voucher': 'Ny kod',
  'hcp.add_referal': 'Lägg till',
  'hcp.add_sign_note_button': 'Lägg till / signera',
  'hcp.addComment': 'Lägg till en kommentar',
  'hcp.admin': 'Admin',
  'hcp.administrator': 'Adminstratör',
  'hcp.age': 'Ålder',
  'hcp.Aggressivness': ' Aggressivitet',
  'hcp.agreement': 'Jag accepterar',
  'hcp.agreement_1': 'Jag accepterar',
  'hcp.agreement_2': 'Användarvillkoren',
  'hcp.agreement_3': 'Sekretessvillkoren',
  'hcp.agreement_4': 'Forskningsvillkoren',
  'hcp.alcoholNoteRisk': 'Kvinnor som får 3 poäng eller högre dricker över rekommenderade gränser och har ökade risker att ta skada, medan män måste ha 4 poäng eller högre för detta. Källa: <i>Survey of Healthcare Experiences of Patients, 2008, VA Office of Quality and Performance.</i><br /><br />När punkterna alla är baserade på Q1 (med 0 poäng för Q2 och Q3), kan det antas att patienten dricker under rekommenderade gränser och vidare undersökning bör göras med resultat från de senaste månaderna för att bekräfta noggrannhet',
  'hcp.Alkohol (Audit-C) och rökning': 'Alcohol (Audit-C) and smoking',
  'hcp.all': 'Alla',
  'hcp.All data related to this user will be erased. This action can be undone.': 'Alla data relaterad till denna användare kommer att raderas. Detta kan reverseras senare.',
  'hcp.allAccounts': 'Alla konton',
  'hcp.Allmänt': 'General',
  'hcp.allPatients': 'Alla patienter',
  'hcp.Alzheimer\'s disease with cerebrovascular injury - AD + VaD': 'Alzheimers sjukdom med cerebrovaskulär skada - AD + VaD ',
  'hcp.Alzheimer\'s disease, mild dementia - Early onset': 'Alzheimers sjukdom, mild demens - Tidig debut',
  'hcp.Alzheimer\'s disease, mild-moderate dementia - Late onset': 'Alzheimers sjukdom, mild-medelsvår demens - Sen debut',
  'hcp.Alzheimer\'s disease, prodromal - Amnestic MCI': 'Alzheimers sjukdom, prodromal - Amnestisk MCI',
  'hcp.amnesis': 'Anamnes',
  'hcp.anamnesis': 'Anamnes',
  'hcp.Anamnesis': 'Anamnes',
  'hcp.anamnesisFlex': 'Anamnes',
  'hcp.anamnesisFlexNew': 'Anamnes',
  'hcp.anamnesisFlexNew.categories.habits': 'Levnadsvanor',
  'hcp.anamnesisFlexNew.categories.medical': 'Sjukdomshistorik',
  'hcp.anamnesisFlexNew.categories.screening': 'Screening',
  'hcp.anamnesisFlexNew.categories.social': 'Livsstil & Symtom',
  'hcp.anamnesisSettings': 'Sektioner i Anamnes',
  'hcp.and': 'och',
  'hcp.Android_version': 'Android app',
  'hcp.Answer': 'Patientens svar',
  'hcp.answer': 'Svar',
  'hcp.Answer was not provided!': ' Inget svar angivet',
  'hcp.answer_skipped_idk': 'Hoppat över eftersom de svarade "Jag vet inte" på den förra frågan.',
  'hcp.answer_skipped_no': 'Hoppat över eftersom de svarade "Nej" på den förra frågan.',
  'hcp.Answered by': 'Besvarats av',
  'hcp.answered_as_not_relevant': 'Svar: Vet ej / ej relevant',
  'hcp.Anxiety': ' Ångest ',
  'hcp.Appetite control': ' Aptitkontroll',
  'hcp.appointment-with': 'Möte med',
  'hcp.appointments': 'Möten',
  'hcp.April': 'April',
  'hcp.Archive user': 'Arkivera användare',
  'hcp.Are you sure you want to archive this user?': 'Är du säker på att du vill arkivera användaren?',
  'hcp.Are you sure you want to completely delete this user?': 'Är du säker på att du vill radera denna användare?',
  'hcp.Are you sure you want to delete this user?': 'Är du säker på att du vill radera användaren?',
  'hcp.Are you sure you want to restore this user?': 'Är du säker på att du vill återställa användaren?',
  'hcp.assistant_nurse': 'Undersköterska',
  'hcp.Associated symptoms': 'Associerade Symtom',
  'hcp.At': 'Vid',
  'hcp.attempt': 'Uppgift',
  'hcp.Attempt': 'task',
  'hcp.atTime': 'Klockan',
  'hcp.August': 'Augusti',
  'hcp.availability': 'Tillgänglighet',
  'hcp.Availability': 'Tillgänglighet',
  'hcp.availability-page.avails': 'Tillgängliga tider',
  'hcp.availability-page.text': 'Välj plusikonen för att ange din tillgänglighet för vald dag. Om du tar bort tillgängligheten för en plats, som redan har ett patientmöte inbokat, kommer inte patientens möte att tas bort. Bokade patientmöten måste tas bort manuellt.',
  'hcp.availability-page.title': 'Mina möten',
  'hcp.available times': 'Tillgängliga tider',
  'hcp.Average of similar healthy patients': 'Genomsnitt för liknande friska patienter',
  'hcp.back': 'Tillbaka',
  'hcp.Balance': ' Balans',
  'hcp.bankId': 'Bank-ID',
  'hcp.basicInformation': 'Generell information',
  'hcp.Behaviour': 'Beteende ',
  'hcp.birthDate': 'Födelsedatum',
  'hcp.Bladder control': ' Blåskontroll',
  'hcp.Body perception': ' Kroppsuppfattning',
  'hcp.booked meetings': 'Bokade möten',
  'hcp.calendar': 'Kalender',
  'hcp.Call': 'Ring',
  'hcp.Call now': 'Ring nu',
  'hcp.Cam': 'Kam',
  'hcp.cancel': 'Avbryt',
  'hcp.Care provider with access to patient account': 'Vårdgivare med åtkomst till patientkonto',
  'hcp.careGiver': 'Vårdpersonal',
  'hcp.Category': 'Kategori',
  'hcp.change': 'Ändring',
  'hcp.child': 'Barn',
  'hcp.clear': 'återställ',
  'hcp.clinical_pharmacologist': 'Klinisk farmakolog',
  'hcp.close-panel': 'Stäng panel',
  'hcp.coding test hint': '1 poäng per 10 korrekta svar',
  'hcp.cognitive': 'Kognitivt test (GSCT)',
  'hcp.Cognitive': 'Kognitivt test (GSCT)',
  'hcp.Cognitive Disease': 'Kognitiv sjukdom',
  'hcp.Cognitive test': 'Kognitivt test',
  'hcp.Cognitive test completed': 'Genomfört kognitivt test',
  'hcp.Collapse': 'Minimera',
  'hcp.common_error_message': 'An unknown error occurred.',
  'hcp.Complete': 'Färdig',
  'hcp.Completed': 'Färdig',
  'hcp.Computability': ' Räkneförmåga',
  'hcp.Confirm': 'Bekräfta',
  'hcp.Confirm new password': 'Bekräfta nytt lösenord',
  'hcp.Confirm password': 'Bekräfta lösenord',
  'hcp.Confusion episodes': ' Förvirringsepisoder',
  'hcp.Consent obtained': 'Samtycke erhållet',
  'hcp.Construction': ' Konstruktion',
  'hcp.contacts': 'Kontakter',
  'hcp.contacts.heading.clinicalAssessmentsAndEvidence': 'Kliniska bedömningar & evidens',
  'hcp.contacts.heading.otherInquiries': 'Övriga ärenden',
  'hcp.contacts.heading.patientAdministrationAndAccounts': 'Administration & patientdata',
  'hcp.contacts.heading.technologyProductPatientData': 'Teknik, produkt & patientdata',
  'hcp.contacts.position.chiefMedicalOfficer': 'Kliniskt ansvarig läkare',
  'hcp.contacts.position.headOfClinicalOperationsAndImplementations': 'Ansvarig för implementeringar',
  'hcp.contacts.position.headOfTechnologyAndProductDevelopment': 'Ansvarig för teknik och produkt',
  'hcp.contactSupport.btn': 'Kontakta support',
  'hcp.contactSupport.text': 'Kontakta oss och vi kommer återkomma så snart som möjligt',
  'hcp.Correct': 'Korrekt',
  'hcp.country': 'Land',
  'hcp.country-sv': 'Sweden',
  'hcp.country-us': 'USA',
  'hcp.country-zh': 'China',
  'hcp.Course': 'Symtomförlopp',
  'hcp.Create': 'Skapa',
  'hcp.create_fm': 'Skapa konto för at genomföra Symtomenkät',
  'hcp.createCareGiveAccount': 'Skapa konto för vårdpersonal',
  'hcp.createdAt': 'Registreringsdatum',
  'hcp.createNewAccount': 'Skapa konto',
  'hcp.createNewAccountSuccessMessage': 'Ett konto är skapat',
  'hcp.createNewPatient': 'Skapa patient',
  'hcp.createNewPatientSuccessMessage': 'Du har skapat en ny patient',
  'hcp.da': 'Danish',
  'hcp.date': 'Datum',
  'hcp.Date answered': 'Datum besvarat',
  'hcp.dateAdded': 'Datum tillagd',
  'hcp.dateScheduled': 'Schemalagt datum',
  'hcp.Day': 'Dag',
  'hcp.December': 'December',
  'hcp.Delete user': 'Radera användare',
  'hcp.deleteAccount': 'Arkivera konto',
  'hcp.deletedAccounts': 'Arkiverade konton',
  'hcp.deletedPatients': 'Arkiverade patienter',
  'hcp.deletedPatients.counter.plural': 'Arkiverade patienter',
  'hcp.deletedPatients.counter.singular': 'Arkiverad patient',
  'hcp.deletePatient': 'Arkivera patient',
  'hcp.Delusions': ' Vanföreställningar',
  'hcp.Dementia': 'Demens',
  'hcp.Depression': ' Depression ',
  'hcp.deselect-all-and-save': 'Avmarkera allt och spara',
  'hcp.Details': 'Detaljer',
  'hcp.diagnosis': 'Diagnos',
  'hcp.diastolic-pressure': 'Mitt vanliga diastoliska tryck',
  'hcp.doctor': 'Läkare',
  'hcp.documents': 'Stöttande dokument',
  'hcp.documents.researchAndEvidence': 'Forskning och evidens',
  'hcp.Download PDF': 'Ladda ned PDF',
  'hcp.download_pdf_report': 'PDF-rapport',
  'hcp.downloaded': 'nedladdad',
  'hcp.downloadReportModalBtn': 'Ladda ned',
  'hcp.Downloads': 'Nedladdningar',
  'hcp.Draw': 'Rita',
  'hcp.Duration in years': 'Symtom varaktighet (år)',
  'hcp.edit history': 'Editeringshistorik',
  'hcp.Edit transcription': 'Redigera transkription',
  'hcp.Edit-answer': 'Ändra svar',
  'hcp.editAccount': 'Ändra konto',
  'hcp.editAccountSuccessMessage': 'Ändringar sparade',
  'hcp.editor': 'Redigerare',
  'hcp.editPatient': 'Ändra patient',
  'hcp.editPatientDetails': 'Administrera',
  'hcp.editPatientSuccessMessage': 'Ändringar sparade',
  'hcp.ef': 'Exekutiva funktioner',
  'hcp.ef-description': 'Verkställande funktion avser din förmåga till högre orderfunktioner, såsom planering, organisering och utvärdering etc.',
  'hcp.email': 'E-post',
  'hcp.Email': 'E-postadress',
  'hcp.emailInstructions': 'Emailinstruktioner',
  'hcp.emailLoginCodeInstructions': 'Patients login kod',
  'hcp.emailLoginInstructions': 'Emailinstruktioner och inloggningsuppgifter',
  'hcp.emailNote': 'Notera: Säkerställ att emailkorrespondens med patienten är godkänd',
  'hcp.emailSettings': 'Email settings',
  'hcp.Emotional Instability': ' Emotionell Instabilitet',
  'hcp.Emotional variation': 'Emotionell Variation',
  'hcp.en': 'English',
  'hcp.endocrinologist': 'Endokrinolog',
  'hcp.Enter-code': 'Enter code',
  'hcp.EnterRelativeEmail': 'Vänligen skriv in e-post till den du vill skicka en inbjudan',
  'hcp.EnterRelativePhoneNumber': 'Vänligen skriv in mobilnummer till den du vill skicka en inbjudan',
  'hcp.error_confirm': 'Lösenorden måste överenstämma',
  'hcp.error_password': 'Lösenordet måste innehålla minst 8 tecken samt speciella symboler (såsom ! och ?)',
  'hcp.errors.loginForm.invalidPNRFormat': 'Felaktigt personnummer',
  'hcp.errors.loginForm.invalidPNRLength': 'Ange personnummer med 12 siffror',
  'hcp.excelReport': 'Ladda ned rapport som Excel',
  'hcp.Expect': 'Rätt svar',
  'hcp.familyFormNote1': 'För att använda Geras Solutions app och utföra Symtomenkäten behöver du skapa ett konto.',
  'hcp.familyFormNote2': ' Vi behöver ditt godkännande för att hantera din information nedan.',
  'hcp.familyMember': 'Anhörig',
  'hcp.familySurvey': 'Family Survey',
  'hcp.February': 'Februari',
  'hcp.Female': 'Kvinna',
  'hcp.female': 'Kvinna',
  'hcp.Field must not be empty': 'Detta fält är obligatoriskt',
  'hcp.File was generated. Please click button below to download it': 'File was generated. Please click button below to download it.',
  'hcp.Filter patients by name': 'Filtrera patienter efter namn',
  'hcp.filter-patients-form.gender.title': 'Filtrera efter kön',
  'hcp.filter-patients-form.reset-filter': 'Återställ filter till standard',
  'hcp.filter-patients-form.status.title': 'Filtrera patienter efter status',
  'hcp.filter.accounts.admins': 'Admins',
  'hcp.filter.accounts.all': 'Alla konton',
  'hcp.filter.accounts.doctors': 'Läkare',
  'hcp.filter.accounts.nurses': 'Sjuksköterskor',
  'hcp.filter.accounts.superAdmins': 'Super Admins',
  'hcp.filter.all': 'Alla patienter',
  'hcp.filter.appointments': 'Har kommande möten',
  'hcp.filter.cognitive': 'Slutfört test',
  'hcp.filter.month': 'Månad',
  'hcp.filter.recommendations': 'Har rekommendationer',
  'hcp.filter.week': 'Vecka',
  'hcp.filter.year': 'År',
  'hcp.filters': 'Filter',
  'hcp.Fine motor skills': ' Finmotorik',
  'hcp.First name': 'Förnamn',
  'hcp.firstName': 'Förnamn',
  'hcp.focusNoteRisk': 'Detta baseras på validerad ADHD-självrapporteringsskala (ASRS), där varje fråga har sina specifika bedömningskriterier.Fråga 1-3 ger 1 poäng vardera om patienten svarat "Ibland" eller mer ofta. Fråga 4-6 ger 1 poäng vardera om patienten svarat "Ofta" eller "Väldigt ofta". Vid en total poäng på 4 eller mer uppvisar patienten symtom som i hög grad överensstämmer med ADHD hos vuxna och motiverar vidare utredning.',
  'hcp.Forget your password?': 'Glömt ditt lösenord?',
  'hcp.Forgot Password': 'Glömt lösenord',
  'hcp.Friday': 'Fredag',
  'hcp.friend': 'Vän',
  'hcp.Frontal': 'Frontal',
  'hcp.Frontal temporal dementia, behavioral variant - FTDbv': 'Frontaltemporal demens, beteendevariant - FTDbv',
  'hcp.Fysisk hälsa': 'Somatic status',
  'hcp.gender': 'Kön',
  'hcp.generalInformation': 'Allmän',
  'hcp.Generating PDF file process starting...': 'Generering av PDF-fil påbörjad...',
  'hcp.geras_forget_pass': 'Byt ditt lösenord här',
  'hcp.Go to video call': 'Gå till videosamtalet',
  'hcp.greetingComponent.text': 'Sök, skapa eller följ en patients teststatus',
  'hcp.greetingComponent.title': 'Hej',
  'hcp.Hallucinations': ' Hallucinationer',
  'hcp.Hand on screen': 'Hand på skärmen',
  'hcp.Have you obtained verbal consent from the patient to create an account on their behalf?': 'Har du fått muntlig samtycke från patienten att skapa ett konto på patientens vägnar?',
  'hcp.HCP_version': 'Portal',
  'hcp.headerCreatePatient': 'Skapa ny patient',
  'hcp.headerCreatePatientSteps.step1': 'Patientinformation',
  'hcp.headerCreatePatientSteps.step2': 'Välj moment för patient',
  'hcp.headerCreatePatientSteps.step3': 'Skräddarsy anamnes för patienten',
  'hcp.heavy-alcohol': 'stark alkohol',
  'hcp.height': 'Längd',
  'hcp.hide preliminary results': 'Dölj preliminära resultat för patienter',
  'hcp.high': 'Hög',
  'hcp.home': 'Hem',
  'hcp.Humor': 'Humor ',
  'hcp.idk': 'Jag vet inte',
  'hcp.ieWarning': 'Den här funktionen är inte tillgänglig i din nuvarande webbläsare. Använd en nyare version av Edge, Firefox, Chrome eller Safari för att använda den här funktionen.',
  'hcp.If': 'Om',
  'hcp.image': 'Bild',
  'hcp.Incorrect': 'Felaktigt',
  'hcp.Indicative reference library': 'Vägledande referensbibliotek',
  'hcp.information_gathering': 'Information gathering',
  'hcp.initialQuestionnaire': 'Inledande frågor - Testa hjärnhälsan',
  'hcp.Initiative': 'Initiativ ',
  'hcp.Insight': 'Insikt ',
  'hcp.institution': 'Vårdgivare',
  'hcp.institutionSettings': 'Shared insitution',
  'hcp.iOS_version': 'iOS app',
  'hcp.January': 'Januari',
  'hcp.journalNotes': 'Journalanteckningar',
  'hcp.July': 'Juli',
  'hcp.June': 'Juni',
  'hcp.l': 'Språk',
  'hcp.l-description': 'Orientering avser din förmåga att lokalisera dig själv inom tid och omgivning.',
  'hcp.landingPage.tile.cta': 'Skapa Patient & Skicka Test',
  'hcp.landingPage.tile.heading.createPatient': 'Skapa ny patient',
  'hcp.landingPage.tile.heading.searchPatient': 'Sök efter patient',
  'hcp.landingPage.tile.searchInputPlaceholder': 'Namn, efternamn, personnummer…',
  'hcp.Language understanding': ' Språkförståelse',
  'hcp.Language variation': 'Språkvariation',
  'hcp.Last name': 'Efternamn',
  'hcp.Last Update': ' uppdatering',
  'hcp.lastName': 'Efternamn',
  'hcp.latest score': 'Senaste testresultat',
  'hcp.latest-patients': 'Tidigare patienter',
  'hcp.Leave call': 'Lämna samtalet',
  'hcp.Lewybodydemens - DLB': 'Lewybodydemens - DLB',
  'hcp.light-alcohol': 'svag alkohol',
  'hcp.Logging in...': 'Loggar in...',
  'hcp.Logic': 'Logik ',
  'hcp.Login': 'Logga in',
  'hcp.Login with': 'Logga in med',
  'hcp.loginCode.expires': 'Utgår',
  'hcp.loginCode.prolong': 'Förnya loginkod',
  'hcp.loginCode.refreshSuccessMessage': 'Loginkod har uppdaterats',
  'hcp.loginCode.title': 'Loginkod',
  'hcp.loginForm.errors.pnrIsNotValid': 'Använda 12 siffror i ditt personnummer',
  'hcp.loginForm.errors.pwdIsInvalid': 'Felaktigt lösenord',
  'hcp.logout': 'Logga ut',
  'hcp.low': 'Låg',
  'hcp.Male': 'Man',
  'hcp.male': 'Man',
  'hcp.manager': 'Manager',
  'hcp.March': 'Mars',
  'hcp.Mark as correct': 'Markera som korrekt',
  'hcp.Mark as incorrect': 'Markera som felaktigt',
  'hcp.mark-as-read': 'Markera som läst',
  'hcp.max': 'Max',
  'hcp.max_if_all_answered': 'max. om allt besvarat',
  'hcp.maxScoreExceeded': 'Maximum score is 59',
  'hcp.May': 'Maj',
  'hcp.MCI.abbreviationDecoding': 'Lindrig kognitiv funktionsnedsättning',
  'hcp.Medicin': 'Medication',
  'hcp.medicinNoteRisk': 'Se Socialstyrelsens lista över <a href="https://www.socialstyrelsen.se/globalassets/sharepoint-dokument/dokument-webb/ovrigt/aldre-olampliga-lakemedel-for-aldre-lista.pdf" target="_blank">Läkemedel som bör undvikas till äldre</a> samt <a href="https://www.socialstyrelsen.se/globalassets/sharepoint-dokument/artikelkatalog/ovrigt/2018-12-37.pdf" target="_blank">Diagnostisk checklista för förbättrad läkemedelsanvändning hos äldre</a>.<br /><br />Läkemedel med antikolinerga effekter: Akineton, Alimemazin, Amitriptylin, Anafrani,l Atarax, Atropin, Buscopan, Calma, Clozapine, Detrusitol, Ditropan, Durbis, Egazil, Emselex, Glykopyrroniumbromid, Hydroxizin, Hydroxyzine, Kentera, Ketogan, Klomipramin, Leponex, Lergigan, Levomepromazine, Ludiomil, Metylskopolamin, Morfin-Skopolamin, Norflex, Norgesic, Nozinan, Oxikodon-hyoscin, Oxybutynin, Pargitan, Postafen, Proklorperazin, Prometazin, Robinul, Saroten, Scopoderm, Sensaval, Sialanar, Spasmofen, Tavegyl, Theralen, Tolterodin, Tolterodine, TOVIAZ, Truxal, Vesicare och Vesikur',
  'hcp.medium-heavy-alcohol': 'mellanstark alkohol',
  'hcp.Meeting with': 'Mötet med',
  'hcp.meetings': 'Möten',
  'hcp.Meetings': 'Möten',
  'hcp.meetings today': 'Dagens Möten',
  'hcp.meetings-page.title': 'Mina möten',
  'hcp.Memory': 'Minne',
  'hcp.Mental hälsa (PHQ-9)': 'Mental Health/Depression (PHQ-9)',
  'hcp.mentalNoteRisk': 'Fråga 9: "Har du tankar kring att det skulle vara bättre om du var död eller att du skulle skada dig på något sätt?" har besvarats positivt. Genomför bedömning av suicidrisk.',
  'hcp.Mic': 'Mic',
  'hcp.mildRisk': 'Lindrig',
  'hcp.Mimic': ' Mimik',
  'hcp.min': 'Min',
  'hcp.minimalRisk': 'Minimalt eller ingen',
  'hcp.minutes': 'minuter',
  'hcp.modal.bankId.description': 'För att logga in, starta Mobilt BankID på din enhet',
  'hcp.modal.bankId.title': 'Öppna Mobile BankID',
  'hcp.modal.confirmation.refreshLoginCode': 'Vill du verkligen utföra den här åtgärden?',
  'hcp.moderateRisk': 'Måttlig',
  'hcp.moderateSeverRisk': 'Ganska svår',
  'hcp.Monday': 'Måndag',
  'hcp.Month': 'Månad',
  'hcp.Muscle tone': ' Muskeltonus',
  'hcp.name': 'Namn',
  'hcp.Naming ability': ' Benämningsförmåga',
  'hcp.neuropsychologist': 'Neuropsykolog',
  'hcp.New password': 'Nytt lösenord',
  'hcp.new_physiotherapist': 'Sjukgymnast',
  'hcp.newAccount': 'Nytt konto',
  'hcp.newPatient': 'Ny patient',
  'hcp.next meeting': 'Nästa möte',
  'hcp.Next meeting in': 'Nästa möte om',
  'hcp.Next meeting with': 'Nästa mötet med',
  'hcp.No': 'Nej',
  'hcp.No answer': 'Inget svar',
  'hcp.No meeting found': 'Hittade inga möten',
  'hcp.No more meetings for today': ' Inga fler bokade möten idag',
  'hcp.no results': 'Inga resultat',
  'hcp.No upcoming meetings': 'Inga bokade möten',
  'hcp.no-avail-set-for-this-time': 'Ingen tillgänglighet för denna tid',
  'hcp.no-patients': 'Inga patienter finns',
  'hcp.no-referrals-currently': 'Det finns inga remisser just nu',
  'hcp.Normal': 'Normal',
  'hcp.Not completed': 'Inte färdigt',
  'hcp.Not Requested': 'Ej Efterfrågad',
  'hcp.not_relevant': 'Inte relevant',
  'hcp.note': 'Notering',
  'hcp.note-form.text': 'Anteckningarna visas enbart för specialister, inte för patienten',
  'hcp.note_type_1': 'Det maximala antalet poäng för denna övning är 3 poäng. Alla försök kommer att bedömas',
  'hcp.note_type_10': 'Det maximala antalet poäng för denna övning är 2 poäng. Alla försök kommer att bedömas',
  'hcp.note_type_11': 'Det maximala antalet poäng för denna övning är 5 poäng. Alla försök kommer att bedömas',
  'hcp.note_type_12': 'Det maximala antalet poäng för denna övning är 2 poäng. Alla försök kommer att bedömas',
  'hcp.note_type_13': 'Det maximala antalet poäng för denna övning är 6 poäng. Det andra försöket kommer att bedömas.Det första försöket är en testomgång.',
  'hcp.note_type_14': 'Det maximala antalet poäng för denna övning är 5 poäng. Alla försök kommer att bedömas',
  'hcp.note_type_15': 'Det maximala antalet poäng för denna övning är 5 poäng. Alla försök kommer att bedömas',
  'hcp.note_type_16': 'Det maximala antalet poäng för denna övning är 5 poäng. Alla försök kommer att bedömas',
  'hcp.note_type_2': 'Det maximala antalet poäng för denna övning är 1 poäng. Endast de bästa försöken kommer att bedömas',
  'hcp.note_type_3': 'Det maximala antalet poäng för denna övning är 5 poäng. Alla försök kommer att bedömas',
  'hcp.note_type_4': 'Det maximala antalet poäng för denna övning är 1 poäng. Alla svar behöver vara korrekta för att klara 1 poäng.',
  'hcp.note_type_5': 'Det maximala antalet poäng för denna övning är 5 poäng. Alla svar behöver vara korrekta för att klara 1 poäng. Endast det första försöket kommer att bedömas.',
  'hcp.note_type_6': 'Det maximala antalet poäng för denna övning är 4 poäng. Alla försök kommer att bedömas',
  'hcp.note_type_7': 'Det maximala antalet poäng för denna övning är 4 poäng. Alla försök kommer att bedömas',
  'hcp.note_type_8': 'Det maximala antalet poäng för denna övning är 5 poäng. Alla försök kommer att bedömas',
  'hcp.note_type_9': 'Det maximala antalet poäng för denna övning är 5 poäng. Endast detta försök kommer att bedömas.',
  'hcp.NotEmptyAndValid': 'Fältet måste innehålla en e-postadress',
  'hcp.notes': 'Anteckningar',
  'hcp.November': 'November',
  'hcp.nurse': 'Sjuksköterska',
  'hcp.o': 'Orientering',
  'hcp.o-description': 'Språk avser din förmåga att förstå och uttrycka muntligt och skriftligt språk och hitta ord samt namn efter kategori eller ljud.',
  'hcp.Obesity Class I': 'Fetma Klass I',
  'hcp.Obesity Class II': 'Fetma Klass II',
  'hcp.Obesity Class III': 'Fetma Klass III',
  'hcp.October': 'Oktober',
  'hcp.of': 'av',
  'hcp.Operating speed': ' Rörelsetempo',
  'hcp.Opinion': 'Omdöme ',
  'hcp.other': 'Övrigt',
  'hcp.other-relationship': 'Annan relation',
  'hcp.Overweight': 'Övervikt',
  'hcp.p-1': 'Ja, uttalat / ofta = 2p',
  'hcp.p-2': 'Ja, något / ibland = 1p',
  'hcp.p-3': 'Nej = 0p',
  'hcp.p-4': 'Vet inte / kan inte bedöma = Inte relevant',
  'hcp.parent': 'Förälder',
  'hcp.Parietal / Temporal': 'Parietal / Temporal',
  'hcp.Parkinson\'s disease with dementia - PDD': 'Parkinsons sjukdom med demens - PDD',
  'hcp.partner': 'Partner',
  'hcp.Password': 'Lösenord',
  'hcp.passwordNote': 'Ditt lösenord måste innehålla minst åtta tecken och innehålla minst 1 specialtecken',
  'hcp.past available times': 'Tidigare tillgängliga tider',
  'hcp.past meetings': 'Tidigare möten',
  'hcp.Patient left test ahead of time': 'Test avbrutet av patient',
  'hcp.patient-appointments': 'Patientmöten',
  'hcp.patient.note.edit': 'Ändra anteckning',
  'hcp.patient.questionnaire-result.completed': 'Färdig',
  'hcp.patient.questionnaire-result.not-completed': 'Inte färdigt',
  'hcp.patient.questionnaire-result.title': 'Uppgiftshistorik för patientens fördiagnos',
  'hcp.patientAccount': 'Patientinformation',
  'hcp.patientAccount.newPatient.patientTasks.cognitiveTest': 'Kognitivt test (GSCT)',
  'hcp.patientAccount.newPatient.patientTasks.deselectAll': 'Avmarkera alla',
  'hcp.patientAccount.newPatient.patientTasks.medicalHistory': 'Anamnes',
  'hcp.patientAccount.newPatient.patientTasks.selectAll': 'Välj alla',
  'hcp.patientAccount.newPatient.patientTasks.symptomSurvey': 'Symtomenkät',
  'hcp.patientEmails': 'Email till patient',
  'hcp.Patientens koncentration - ADHD/ADD (ASRS)': 'Patient’s ability to focus - ADHD/ADD (ASRS)',
  'hcp.Patientens upplevda symtom': 'Perceived cognitive symptoms',
  'hcp.patientForm.errors.emailIsNotValid': 'Email ej giltig',
  'hcp.patientForm.errors.pnrIsNotValid': 'Personnummer måste vara 12 siffror',
  'hcp.patientInfo.patientStatus.assessmentReviewed': 'Utlåtande granskat',
  'hcp.patientInfo.patientStatus.complementaryInfo': 'Komplettering',
  'hcp.patientInfo.patientStatus.informationGathering': 'Informationsinsamling',
  'hcp.patientInfo.patientStatus.prelAssessment': 'Prel. utlåtande',
  'hcp.patientInfo.patientStatus.reportGenerated': 'Rapport genererad',
  'hcp.patientInfo.patientStatus.resultsNotReviewed': 'Resultat ej granskat',
  'hcp.patientInfo.patientStatus.reviewedResults': 'Granskade resultat',
  'hcp.patientPhoneNumber': 'Patients mobilnummer',
  'hcp.patientPhoneNumber.lastSentTextMessage': 'Senast skickat sms',
  'hcp.patientPhoneNumber.noMobileNumber': 'Inget mobilnummer tillgängligt',
  'hcp.patientPhoneNumber.sendLoginCode': 'Loginkod & länk till app',
  'hcp.patientPhoneNumber.successMessage': 'Textmeddelandet har nu skickats till patient',
  'hcp.patientPhoneNumber.title': 'SMS till patient',
  'hcp.patients': 'Patienter',
  'hcp.patientsProfile': ' Patientens profil',
  'hcp.patientTasks': 'Patientens steg',
  'hcp.pdf.age': 'Ålder',
  'hcp.pdf.ageLowercase': 'ålder',
  'hcp.pdf.anamnesis': 'Anamnes',
  'hcp.pdf.cognitive': 'Kognitivt Test (GSCT)',
  'hcp.pdf.cognitive.copyright': '*Digital Screening for Cognitive Impairment – A Proof ofConcept Study V. Bloniecki1,2, G. Hagman1,3, M. Ryden3 ,M. Kivipelto1,3,4,5,6, The Journal of Prevention of Alzheimer’s Disease – JPAD, in press Jan 2021,CorrespondingAuthor: Victor Bloniecki, Karolinska Institute, Karolinska University Hospital, Eugeniavägen',
  'hcp.pdf.cognitive.header': 'Utlåtande - Digital utredning av kognitiva funktioner',
  'hcp.pdf.cognitive.instruction-1': '<b>SCI</b> = Subjektiv kognitiv funktionsnedsättning, testpersonen upplever försämring av minne och andra kognitivafunktioner men testning indikerar inte nedsatt kognitiv förmåga, <b>MCI</b> = Lindrig kognitiv funktionsnedsättning.Med det menas att en viss nedsättning av minnesfunktioner kan ses men den är inte så uttalad att det i nuläget talarför demenssjukdom/kognitiv sjukdom. <b>Misstänkt kognitiv sjukdom</b> = testresultat förenligt med kognitiv funktionsnedsättningtill följd av demenssjukdom men andra möjliga behandlingsbara orsaker måste uteslutas',
  'hcp.pdf.cognitive.instruction-1.title': 'Förkortningar',
  'hcp.pdf.cognitive.instruction-2': '<b>SCI:</b> &#62; 45.0 poäng, 17:00 – 19:00 min<br/><b>MCI:</b> 35.5 – 45.0 poäng, 19:00 – 24:00 min<br/><b>Misstänkt kognitiv sjukdom:</b> &#60;35.5 poäng, 28:00 – 48:00 min',
  'hcp.pdf.cognitive.instruction-2.title': 'Gränsvärden',
  'hcp.pdf.createdThrough': 'Skapat genom',
  'hcp.pdf.diagnos': 'Diagnos',
  'hcp.pdf.initialQuestionnaire': 'Inledande frågor – Testa hjärnhälsan',
  'hcp.pdf.main': 'Underlag',
  'hcp.pdf.name': 'Namn',
  'hcp.pdf.recommendations': 'Rekommendation',
  'hcp.pdf.summary': 'Sammanfattat resultat och bedömning',
  'hcp.pdf.symptomSurvey': 'Symtomenkäten',
  'hcp.pdf.symptomSurvey.allFreeTextAnswers': 'Alla fritextssvar',
  'hcp.pdf.symptomSurvey.allMultipleChoice': 'Alla flervalsalternativ',
  'hcp.pdf.symptomSurvey.symptomSurveyPage': 'Symtomenkätssidan',
  'hcp.pdfReport': 'Generera rapport',
  'hcp.personalNumber': 'Personnummer',
  'hcp.phone': 'Telefonnummer',
  'hcp.physiotherapist': 'Fysioterapeut',
  'hcp.Planning': 'Planering ',
  'hcp.Planning memory 1': 'Planeringsminne 1',
  'hcp.Planning memory 2': 'Planeringsminne 2',
  'hcp.Play drawing process': 'Spela upp ritprocess',
  'hcp.Please obtain verbal consent from the patient to create an account on their behalf.': 'Vänligen erhåll ett muntligt samtycke från patienten för att skapa ett konto på patientens vägnar.',
  'hcp.Please wait': 'Vänligen vänta',
  'hcp.Please, click on the image below if you already have an app': 'Var god klicka på klicka på knappen nedanför om du redan har appen',
  'hcp.Please, click on the image below to download app': 'Var god klicka på knappen nedanför för att ladda ned appen',
  'hcp.pnumberFormat': 'ÅÅÅÅMMDD-XXXX',
  'hcp.Points': 'Poäng',
  'hcp.policy': 'Sekretessvillkor',
  'hcp.Posterior cortical atrophy - PCA': 'Posterior kortikal atrofi - PCA ',
  'hcp.Practical ability': ' Praktisk förmåga',
  'hcp.previousMeetings': 'Tidigare möten',
  'hcp.Primary progressive non-fluid aphasia - PNFA': 'Primär progressiv icke-flytande afasi - PNFA',
  'hcp.profile-view-doctor': 'Dr.',
  'hcp.profile-view-neuropsychologist': 'Dr.',
  'hcp.profile-view-new_physiotherapist': 'SG.',
  'hcp.profile-view-nurse': 'Ssk.',
  'hcp.profile-view-psychologist': 'Dr.',
  'hcp.profile-view-therapist': 'AT',
  'hcp.Proximity memory 1': 'Korttidsminne 1',
  'hcp.Proximity memory 2': 'Korttidsminne 2',
  'hcp.psychologist': 'Psykolog',
  'hcp.Question was skipped due to previous question answer': 'Frågan hoppades över på grund av tidigare svar på fråga nr ',
  'hcp.questionnaire': 'Frågeformulär',
  'hcp.Questionnaire': 'Frågeformulär',
  'hcp.Questions': 'Frågor',
  'hcp.quick_pick_button': 'Snabbval',
  'hcp.radiologist': 'Radiolog',
  'hcp.Reaction time': 'Reaktionstid',
  'hcp.Recognition ability': ' Igenkänningsförmåga',
  'hcp.recommendation': 'Rekommendation',
  'hcp.Recording not found': 'Inspelning hittades inte',
  'hcp.Recording of answers': 'Inspelning av svar',
  'hcp.referrals': 'Remisser',
  'hcp.Regional Dysfunktionsprofil Patient': 'Regional Dysfunktionsprofil',
  'hcp.Relationship': 'Relation',
  'hcp.relationship': 'Välj relation',
  'hcp.relativeDegrees': '["Partner","Förälder","Barn","Syskon","Annan relation","Vän"]',
  'hcp.Remote memory': 'Långtidsminne',
  'hcp.report-header': 'Preliminära resultat',
  'hcp.report-header-cognitive': 'Kognitiva funktioner',
  'hcp.report-high': 'Hög',
  'hcp.report-low': 'Låg',
  'hcp.report-tasks': 'Testmoment',
  'hcp.reportExport': 'Rapport export',
  'hcp.Requested': 'Efterfrågad',
  'hcp.requestFamilyPhoneLabel': 'Närståendes mobilnummer',
  'hcp.requestFamilySurvey': 'Symtomenkät',
  'hcp.Required': 'Obligatorisk',
  'hcp.Reset counts': 'Raderade pilar',
  'hcp.Reset password': 'Återställ lösenord',
  'hcp.Responsiveness': ' Reaktionsförmåga',
  'hcp.Restore user': 'Återställ användare',
  'hcp.restoreAccount': 'Återställ konto',
  'hcp.restorePatient': 'Återställ patient',
  'hcp.restorePWDForm.errors.emailIsInvalid': 'Felaktig e-post',
  'hcp.Results': 'Resultat',
  'hcp.Results for Review': 'Tillgänglig',
  'hcp.riskIndicator': 'Riskindikator',
  'hcp.riskIndicators': 'Riskindikatorer',
  'hcp.riskInterval': 'Riskindikator',
  'hcp.riskLegend': 'Notering: Se klinisk studie under "Dokument" för mer info',
  'hcp.role': 'Roll',
  'hcp.Room orientation': ' Rumsorientering',
  'hcp.Saturday': 'Lördag',
  'hcp.save': 'Spara',
  'hcp.save-and-send': 'Spara och skicka',
  'hcp.SCI.abbreviationDecoding': 'Subjektiv kognitiv funktionsnedsättning',
  'hcp.search': 'Sök',
  'hcp.search.results': 'Resultat',
  'hcp.searchPatient': 'Sök patient...',
  'hcp.seconds': 'sekunder',
  'hcp.Select profile': 'Välj profil',
  'hcp.Select the recommended path': 'Välj rekommendation för nästa steg',
  'hcp.select-all-and-save': 'Välj allt och spara',
  'hcp.Select-discount': 'Select discount',
  'hcp.selectCommentType': 'Välj typ av kommentar',
  'hcp.selectCountry': 'Välj land',
  'hcp.selectGender': 'Välj kön',
  'hcp.selectLanguage': 'Välj språk',
  'hcp.selectRole': 'Välj roll',
  'hcp.Semantic dementia - SD': 'Semantisk demens - SD',
  'hcp.send': 'Skicka',
  'hcp.Send': 'Skicka',
  'hcp.Send symptom survey to relative': 'Skicka symtomenkät till närstående',
  'hcp.Sending email...': 'Skickar email...',
  'hcp.sendSymptomSurveySuccessMessage': 'Symtomenkät skickad',
  'hcp.sendToPatientModalBtn': 'Dela med patient',
  'hcp.sentToPatient': 'skickad till patient',
  'hcp.September': 'September',
  'hcp.setNewPassword': 'Bekräfta lösenord',
  'hcp.Severely underweight': 'Allvarlig undervikt',
  'hcp.severRisk': 'Mycket Hög',
  'hcp.Shape sense': ' Formsinne',
  'hcp.show preliminary results': 'Visa preliminära resultat till patienter',
  'hcp.show_all_records': 'Visa allt',
  'hcp.showArchivedTests': 'Se arkiverade tester',
  'hcp.showNewTests': 'Återgå till nya tester',
  'hcp.sibling': 'Syskon',
  'hcp.sidePanel.navigation.contact': 'Kontakt',
  'hcp.Sleep disturbance': ' Sömnstörning',
  'hcp.specialist': 'Specialist',
  'hcp.specialistComments': 'Kommentarer',
  'hcp.specialistReviewing': 'Visningshistorik',
  'hcp.Speech clarity': ' Taltydlighet',
  'hcp.speech_therapist': 'Logoped',
  'hcp.start': 'Starta',
  'hcp.startPage.button.createPatient': 'Skapa patient',
  'hcp.startPage.searchHint': 'Sök patient',
  'hcp.starts-in': 'startar om',
  'hcp.status': 'Status',
  'hcp.stm': 'Minne',
  'hcp.stm-description': 'Korttidsminne avser din förmåga att hålla en liten mängd information i åtanke under en kort tid.',
  'hcp.Stress sensitivity': ' Stresskänslighet',
  'hcp.stressNoteRisk': 'Den som får över 18 poäng kan vara i riskzonen för alternativt vara drabbad av utmattningssyndrom. Då rekommenderas det att patienten tar kontakt med sin vårdcentral/företagshälsovård eller chef för att diskutera testresultatet. Någon finare uppdelning av antalet poäng har forskarna inte gjort.',
  'hcp.Subcortical': 'Subkortikal',
  'hcp.summary_results_and_assessment': 'Sammanfattat resultat och bedömning',
  'hcp.Sunday': 'Söndag',
  'hcp.superAdmin': 'Super admin',
  'hcp.survey': 'Enkät',
  'hcp.Suspected': 'Misstänkt',
  'hcp.sv': 'Svenska',
  'hcp.symptom survey': 'Symtomenkät',
  'hcp.Symptom Survey for relatives': 'Symtomenkät för anhöriga',
  'hcp.symptom_survey.riskLegend.hint': 'Summerad poäng visas i relation till de frågor som är besvarade av anhörig. Höga poäng indikerar flera områden med försämrad kognitiv status. Svar ”Vet inte / kan inte bedöma” räknas inte med. Maximal poäng då samtliga frågor besvaras visas inom parentes.',
  'hcp.SymptomSurvey_version': 'Symtomenkäten',
  'hcp.systolic-pressure': 'Mitt vanliga systoliska tryck',
  'hcp.termsAndConditions': 'Användarvillkor',
  'hcp.Test duration': 'Testtid',
  'hcp.testSettings': 'Application tests settings',
  'hcp.testStatus.abortedOrError': 'Avbrutet',
  'hcp.testStatus.done': 'Resultat tillgängliga',
  'hcp.testStatus.initiated': 'Påbörjat',
  'hcp.testStatus.notSent': 'Ej aktiverat',
  'hcp.testStatus.sent': 'Aktiverat',
  'hcp.testStatus.viewed': 'Granskat',
  'hcp.testStatus.viewedWithError': 'Avbrutet / Granskat',
  'hcp.Text chat': 'Textchatt',
  'hcp.text-chat': 'Textchatt',
  'hcp.Text-message history': 'SMS historik',
  'hcp.textMessageToPatient.BrainHealth': 'Hjärnhälsa',
  'hcp.textMessageToPatient.BrainHealth.messageExample': 'Via denna länk kan du ta del av nyttig information kring hjärnhälsa:</br>https://www.minnesmottagningen.se/hall-hjarnan-frisk/hallhjarnanfrisk </br>Obs! Du kan inte svara på detta sms',
  'hcp.textMessageToPatient.FeedBack': 'Begär feedback',
  'hcp.textMessageToPatient.FeedBack.messageExample': 'Det vore värdefullt för oss om du ville svara på några korta frågor om din kontakt med oss. Frågorna tar max 2 minuter att besvara, svaren är helt anonyma.</br>Du svarar på frågorna genom att klicka på denna länk:</br>https://forms.gle/1vvzJU5Z6qfidV9J7</br>Vänligen,</br>Minnesmottagningen.se</br>Obs! Du kan inte svara på detta sms',
  'hcp.textMessageToPatient.loginCode.messageExample': 'Ladda ned app - minnesmottagningen.se/app</br>Logga in med kod: XXXXX</br>Obs! Du kan inte svara på detta sms',
  'hcp.textMessageToPatient.MissedAppointment': 'Missat videomöte',
  'hcp.textMessageToPatient.MissedAppointment.messageExample': 'Du har uteblivit från ditt bokade videomöte idag.</br>För att undvika ytterligare debitering, vänligen kontakta oss för ombokning av din mötestid.</br>Du når oss på 010-750 07 26 mån-fre kl. 09-15 eller via  info@minnesmottagningen.se.</br>Vänligen,</br>Minnesmottagningen.se</br>Obs! Du kan inte svara på detta sms',
  'hcp.textMessageToPatient.NoFreeCard': 'Inget gratiskort - betalningslänk',
  'hcp.textMessageToPatient.NoFreeCard.messageExample': 'Utebliven patientavgift.</br>Vid bokning av tid har frikort felaktigt angivits.</br>Vi ber dig därför betala patientavgift på 300 kronor via följande länk:</br>https://buy.stripe.com/4gw17fa4r8eG8ScfZ3</br>Vänligen,</br>Minnesmottagningen.se</br>Obs! Du kan inte svara på detta sms',
  'hcp.textMessageToPatient.RefundForPatient': 'Återbetalning till patient - på grund av frikort',
  'hcp.textMessageToPatient.RefundForPatient.messageExample': 'Återbetalning av patientavgift.</br>Vi kommer att göra en återbetalning av patientavgiften på 300 kronor eftersom det vid vår kontroll visade sig att du har giltigt frikort.</br>Observera att det kan ta några dagar innan återbetalningen syns på den betalmetod du valde vid bokning av din tid.</br>Vänligen,</br>Minnesmottagningen.se</br>Obs! Du kan inte svara på detta sms',
  'hcp.textMessageToPatient.ReminderForMeeting': 'Mötespåminnelse',
  'hcp.textMessageToPatient.ReminderForMeeting.messageExample': 'Mötespåminnelse.</br>Ditt videomöte börjar alldeles strax.</br>Logga in här för att starta videomötet:</br>Minnesmottagningen.se/patientportal</br>Vänligen,</br>Minnesmottagningen.se</br>Obs! Du kan inte svara på detta sms',
  'hcp.textMessageToPatient.ReviewedByDoctor': 'Analyserat av läkare',
  'hcp.textMessageToPatient.ReviewedByDoctor.messageExample': 'Dina svar och ditt testresultat har nu granskats av läkare.</br>Resultat, bedömning och rekommendation får du på ditt inbokade videomöte.</br>Vänligen,</br>Minnesmottagningen.se</br>Obs! Du kan inte svara på detta sms',
  'hcp.textMessageToPatient.TestReminder': 'Testpåminnelse',
  'hcp.textMessageToPatient.TestReminder.messageExample': 'Behov av komplettering.</br>Det finns ofullständiga moment som behöver kompletteras. Logga in i appen för att göra klart de delar som kvarstår.</br> www.Minnesmottagningen.se/app </br>Obs! Du kan inte svara på detta sms',
  'hcp.textMessageToPatient.ThankYou': 'Slutförd minnesutredning',
  'hcp.textMessageToPatient.ThankYou.messageExample': 'Information finns nu att hämta i patientportalen via följande länk:</br>www.minnesmottagningen.se/patientportal</br>Obs! Du kan inte svara på detta sms',
  'hcp.textMessageToPatient.TriedToReachOut': 'Försökte nå ut',
  'hcp.textMessageToPatient.TriedToReachOut.messageExample': 'Vi har försökt nå dig.</br>Vänligen kontakta oss på info@minnesmottagningen.se eller ring 010-750 07 26 mån-fre kl. 9-15.</br>Vänligen,</br>Minnesmottagningen.se</br>Obs! Du kan inte svara på detta sms',
  'hcp.Thank you for choosing Geras Solutions!': 'Tack för att du har valt Geras Solutions!',
  'hcp.The email to the patient has now been sent': 'Email har nu skickats',
  'hcp.therapist': 'Arbetsterapeut',
  'hcp.Thought tempo': ' Tanketempo',
  'hcp.threeDots': '...',
  'hcp.Thursday': 'Torsdag',
  'hcp.Time perception 1': 'Tidsuppfattning 1',
  'hcp.Time perception 2': 'Tidsuppfattning 2',
  'hcp.Time to take test': 'Tidsåtgång för test',
  'hcp.time-used': 'Tid för att bli klar',
  'hcp.title': 'Patientinformation',
  'hcp.To confirm invitation press button below': 'Klicka på inbjudan nedanför för att bekräfta inbjudan',
  'hcp.Today': 'Idag',
  'hcp.today': 'idag',
  'hcp.Tomorrow': 'Imorgon',
  'hcp.tomorrow': 'imorgon',
  'hcp.Total points': 'Poängfördelning',
  'hcp.Total time': 'Total tid',
  'hcp.totalPatients.counter.plural': 'patienter',
  'hcp.totalPatients.counter.singular': 'patient',
  'hcp.transcription from': 'Original transkription',
  'hcp.Transcription of answers': 'Transkription',
  'hcp.transcription to': 'Ny transkription',
  'hcp.try': 'Försök',
  'hcp.Tuesday': 'Tisdag',
  'hcp.Type here...': 'Skriv här...',
  'hcp.Type to search patient': 'Skriv för att söka efter patient',
  'hcp.Typical authentic patient cases, not scientifically validated': 'Typiska autentiska patientfall, ej vetenskapligt validerade',
  'hcp.Underweight': 'Undervikt',
  'hcp.upcomingMeetings': 'Kommande möten',
  'hcp.username': 'Användarnamn',
  'hcp.va': 'Visuospatial förmåga',
  'hcp.va-description': 'Visuospatial förmåga avser din förmåga att identifiera objekt i ett utrymme och hur de relaterar till utrymmet.',
  'hcp.Vascular dementia - VaD': 'Vaskulär demens - VaD',
  'hcp.Very severely underweight': 'Mycket allvarlig undervikt',
  'hcp.video-appointment': 'Videosamtal',
  'hcp.video-call': 'Videosamtal',
  'hcp.voucherCode': 'Generate Voucher Code',
  'hcp.voucherCodeStats': 'Voucher Code Statistic',
  'hcp.Waiting for user to join...': 'Väntar på att användare ska ansluta...',
  'hcp.Walking pattern': ' Gångmönster',
  'hcp.We have e-mailed your password reset link': 'Om ditt konto finns i vårt system kommer du att få ett e-postmeddelande för att återställa lösenordet.',
  'hcp.We strongly recommend do not reload the page.': 'Vi rekommenderar starkt att du inte laddar om sidan.',
  'hcp.Wednesday': 'Onsdag',
  'hcp.Week': 'Vecka',
  'hcp.weight': 'Vikt',
  'hcp.welcome': 'Välkommen',
  'hcp.welcome_geras_1': 'Välkommen till Geras Solutions',
  'hcp.welcome_geras_2': 'Vårdgivarportal',
  'hcp.Why did you download Geras app?': 'Varför laddade du ner Geras-appen?',
  'hcp.whyGeras': 'Varför laddade du ner Geras appen?',
  'hcp.will start in': 'börjar om',
  'hcp.with': 'med',
  'hcp.wm': 'Uppmärksamhet, koncentration och arbetsminne',
  'hcp.wm-description': 'Dessa är relaterade till ditt korttidsminne och avser din förmåga att manipulera och organisera information som lagras i ditt korttidsminne.',
  'hcp.words:': 'Ord: ',
  'hcp.Write patient’s name, personal number, or email': 'Skriv patientens namn, personnummer eller patient-id',
  'hcp.Write your message here': 'Skriv ditt meddelande här',
  'hcp.Write your opinion, which will be sent to the patient': 'Skriv din åsikt, vilken kommer att skickas till patienten',
  'hcp.writeComment': 'Skriv en kommentar',
  'hcp.Writing ability': ' Skrivförmåga',
  'hcp.years': 'år',
  'hcp.yearsOld': 'år',
  'hcp.Yes': 'Ja',
  'hcp.You can restore this user later': 'Du kan återställa användare senare',
  'hcp.You successfully changed your password': 'Du har ändrat ditt lösenord',
  'hcp.zh': '中文',
  'lastName': 'Ditt efternamn (anhörigs efternamn om du är vårdpersonal)',
  'loader.retrievingRecords': 'Kontrollerar anslutning...',
  'notFoundPage.header': 'Det verkar som att sidan inte finns',
  'notFoundPage.helpMessage': 'Vänligen kontrollera länken',
  'patient.search.personalNumberHeading': 'Sök via personnummer',
  'patientEmail': 'Patientens e-post (endast om personnummer saknas)',
  'patientSearch.hint.emailFormat': 'Vänligen säkerställ korrekt format',
  'patientSearch.hint.persNumberFormat': '12 tecken',
  'patientSearch.provideEmailOrPnr': 'Vänligen skriv in personens personnummer eller e-post',
  'personalNumber': 'Patientens personnummer',
  'phone': 'Ditt telefonnummer',
  'relative.relativeForm.heading': 'Berätta om dig själv',
  'relative.relativeForm.headingIfAuth': 'Symtomenkät för',
  'relative.relativeForm.headingIfAuthDescriptionAdditional': 'Personen i fråga kan av olika skäl ha svårt att lämna uppriktiga och/eller utförliga beskrivningar av sitt tillstånd, varför en närståendes perspektiv är av stort värde i bedömningen.',
  'relative.relativeForm.headingIfAuthDescriptionMain1': 'Du har blivit tillfrågad av',
  'relative.relativeForm.headingIfAuthDescriptionMain2': 'att svara på en Symtomenkät i samband med dennes kognitiva bedömning som görs genom appen Minnesmottagningen.',
  'relative.relativeForm.headingSearch': 'Vänligen fyll i nedan för att starta Symtomenkäten',
  'relative.relativeForm.saveButton': 'Starta',
  'startPage.button.getStarted': 'Starta',
  'startPage.button.goToSearch': 'Gå till sök',
  'startPage.heading.linkNotValid': 'Tyvärr är den angivna länken inte längre aktiv',
  'startPage.heading.noQuestionsFromServer': 'Ett fel inträffade',
  'startPage.heading.noQuestionsTip1': 'Försök att ladda om sidan',
  'startPage.heading.noQuestionsTip2': 'Vänligen se över och korrigera personnummer',
  'startPage.heading.searchOption': 'Vänligen se över och korrigera personnummer',
  'startPage.heading.welcome': 'Välkommen till symtomenkäten',
  'startPage.welcomeMessage1': 'Vänligen svara på frågor gällande',
  'successPage.heading': 'Svaren är nu sparade.',
  'successPage.thanksMessage': 'Tack för din återkoppling. Informationen är av stort värde för den fortsatta utredningen.',
  'survey.buttons.back': 'Tillbaka',
  'survey.buttons.next': 'Nästa',
  'survey.buttons.save': 'Skicka',
  'sv': 'Svenska',
  'symptom-survey.answer.child': 'Barn',
  'symptom-survey.answer.difficult-to-discuss-with-patienten': 'Svårt att diskutera detta med patienten',
  'symptom-survey.answer.do-not-know-not-relevant': 'Vet ej / ej relevant',
  'symptom-survey.answer.dont-know-cant-be-assessed': 'Vet inte / kan inte bedöma',
  'symptom-survey.answer.friend': 'Vän',
  'symptom-survey.answer.gradually-transition-in-a-faster-pace': 'Gradvis - snabbare förlopp',
  'symptom-survey.answer.gradually-transition-in-an-insidious': 'Gradvis - på ett smygande sätt',
  'symptom-survey.answer.half-one-year-ago': '1⁄2 - 1 år',
  'symptom-survey.answer.less-than-six-months-ago': 'Mindre än 6 månader',
  'symptom-survey.answer.more-than-five-years-ago': 'mera än 5 år',
  'symptom-survey.answer.no': 'Nej',
  'symptom-survey.answer.often': 'Ofta',
  'symptom-survey.answer.one-two-years-ago': '1 - 2 år',
  'symptom-survey.answer.other-relationship': 'Annan relation',
  'symptom-survey.answer.parent': 'Förälder',
  'symptom-survey.answer.partner': 'Partner',
  'symptom-survey.answer.periodically-returning-symptoms-with-normal-functioning-periods': 'Periodvis återkommande symtom, däremellan fungerande som tidigare',
  'symptom-survey.answer.sibling': 'Syskon',
  'symptom-survey.answer.sometimes': 'Ibland',
  'symptom-survey.answer.somewhat': 'Delvis',
  'symptom-survey.answer.sudden-change-thereafter-gradual-deterioration': 'Plötslig förändring, - därefter stegvis försämring',
  'symptom-survey.answer.sudden-change-thereafter-improved': 'Plötslig förändring, - därefter förbättring',
  'symptom-survey.answer.sudden-change-thereafter-insidious-deterioration': 'Plötslig förändring, - därefter smygande försämring',
  'symptom-survey.answer.sudden-change-thereafter-unchanged': 'Plötslig förändring, - därefter oförändrat',
  'symptom-survey.answer.text': 'Skriv här..',
  'symptom-survey.answer.three-five-years-ago': '3 - 5 år',
  'symptom-survey.answer.two-three-years-ago': '2 - 3 år',
  'symptom-survey.answer.yes': 'Ja',
  'symptom-survey.answer.yes-pronounced-often': 'Ja, uttalat / ofta',
  'symptom-survey.answer.yes-somewhat-sometimes': 'Ja, något / ibland',
  'symptom-survey.question.assessing-distance': '<strong>bedöma avstånd och inta rätt kroppsläge,</strong> exempelvis genom att sätta sig på en stol eller lägga sig i en säng med kroppen rätt placerad',
  'symptom-survey.question.become-distrustful': 'har blivit <strong>misstänksam</strong> och kan exempelvis komma med grundlösa anklagelser, visa obefogad svartsjuka eller känna sig förföljd. Hen kan även på annat sätt ge uttryck för <strong>vanföreställningar</strong>',
  'symptom-survey.question.changed-behavior': '<strong>förändrat sitt beteende.</strong> Detta kan leda till genanta eller problematiska situationer i sällskapslivet eller samlivet',
  'symptom-survey.question.changed-eating-habits': '<strong>förändrat sina matvanor.</strong> Hunger, mättnadskänsla eller val av födoämnen är annorlunda än tidigare och bordsskicket kan vara försämrat',
  'symptom-survey.question.changed-facial-expressions': 'har <strong>annorlunda ansiktsuttryck.</strong> Minspelet är mindre spontant och varierat, vilket gör att det blivit svårare att avläsa känsloläge och reaktioner',
  'symptom-survey.question.changed-sense-of-humor': '<strong>förändrat sinne för humor.</strong> Har svårt att förstå skämt, misstolkar, tar åt sig eller skämtar själv på ett sätt som är olikt hen. Kan verka road och upprymd utan att det tycks finnas orsak',
  'symptom-survey.question.changed-sleeping-habits': 'har <strong>förändrat sina sovvanor,</strong> exempelvis om hen sover sämre, sover mera eller sover vid andra tider på dygnet än tidigare',
  'symptom-survey.question.changed-walking': 'har <strong>förändrat sitt sätt att gå</strong> och stegen är korta, trippande eller mera klumpiga',
  'symptom-survey.question.clumsier-or-trembling': 'är <strong>fumligare</strong> eller har blivit mera <strong>darrhänt</strong>',
  'symptom-survey.question.counting': '<strong>räkna</strong> och förstå sin ekonomi och pengars värde, använda betalkort, betala räkningar och kontrollera kontoutdrag',
  'symptom-survey.question.difficulty-remembering-what-was-said': '<strong>komma ihåg</strong> vad hen alldeles nyligen gjort',
  'symptom-survey.question.evident-specific-event-symptoms': 'Känner Du/Ni till någon <strong>speciell händelse</strong> som kan sättas i samband med symtomen eller närmare ange <strong>tidpunkten</strong> då symtomen började visa sig?',
  'symptom-survey.question.experienced-impaired-awareness': '<strong>försämrad insikt,</strong> t ex om de problem som symtomen leder till för den egna personen eller för andra',
  'symptom-survey.question.feeling-more-down': 'är mera <strong>nedstämd</strong> och kan vara dyster, grubblande, ledsen med nedsatt ”gnista” eller livslust',
  'symptom-survey.question.finding-direction': '<strong>hitta rätt väg</strong> vid bilfärd, promenad, köprunda i varuhus eller toalettbesök hemma hos bekanta',
  'symptom-survey.question.finding-words': '<strong>hitta rätt ord.</strong> Försöker ofta finna ett annat sätt att förklara vad hen menar eller talar osammanhängande',
  'symptom-survey.question.first-noticed-symptoms': '<strong>Vilket/vilka symtom</strong> av de som markerats i enkäten var det/de som <strong>först</strong> observerades?',
  'symptom-survey.question.first-symptoms-noticed': '<strong>För hur länge sedan</strong> noterades de första symtomen av de som markerats i enkäten?',
  'symptom-survey.question.following-instructions-and-manuals': '<strong>följa instruktioner och bruksanvisningar</strong> med gott resultat, som vid hopsättning av möbler eller vid montering av apparater/redskap',
  'symptom-survey.question.hard-to-manage-symptoms': 'Berättar patienten att hen <strong>upplever något symtom som särskilt svårt?</strong>',
  'symptom-survey.question.has-been-confused': 'har stundtals eller periodvis blivit <strong>förvirrad.</strong> Exempelvis genom att hastigt blivit mera oklar i tankarna, fått svårare att orientera sig i tid och rum, visat ett förändrat beteende och fått svårt att komma till ro',
  'symptom-survey.question.has-hallucinations': 'har <strong>hallucinationer.</strong> Exempelvis kan hen se, höra eller känna sådant som inte existerar i verkligheten, men som hen upplever tydligt',
  'symptom-survey.question.how-related-with-patienten': '<strong>Hur är du släkt med eller kopplad till patienten?</strong>',
  'symptom-survey.question.impaired-balance': 'har <strong>försämrad balans</strong> och faller lättare omkull',
  'symptom-survey.question.impaired-judgement': '<strong>försämrat omdöme.</strong> Kan göra sådant som innebär risker eller måste redas ut och är <strong>mindre noggrann,</strong> exempelvis vid skötsel av hemmet eller den personliga hygienen',
  'symptom-survey.question.increased-difficulty-getting-involved': '<strong>svårare att engagera sig och visa medkänsla</strong> i det som tidigare varit viktigt, både vad gäller familjen och samhället i stort. Känsloreaktionerna är svagare, otydligare eller avvikande',
  'symptom-survey.question.increased-difficulty-planning': '<strong>svårare att planera,</strong> exempelvis gällande att sköta hemmet, göra inköp eller välja lämpliga kläder',
  'symptom-survey.question.increased-difficulty-remembering-what-day-it-is': 'veta <strong>vilken dag</strong> det är',
  'symptom-survey.question.increased-difficulty-remembering-what-has-happened': '<strong>komma ihåg</strong> det som ligger längre tillbaka i tiden och kan ha svårt att minnas i vilken ordning olika minnesvärda händelser inträffat',
  'symptom-survey.question.increased-difficulty-remembering-what-time-it-is': 'veta <strong>vilken tid på dygnet</strong> det är',
  'symptom-survey.question.increased-difficulty-remembering-what-to-do': '<strong>komma ihåg</strong> vad som ska göras eller kommer att inträffa, exempelvis beställa tid för något eller uppmärksamma en högtidsdag',
  'symptom-survey.question.increased-difficulty-remembering-what-was-done': '<strong>komma ihåg</strong> vad som alldeles nyligen sagts',
  'symptom-survey.question.increased-difficulty-remembering-without-being-reminded': '<strong>komma ihåg</strong> utan att bli påmind eller använda minneslappar, almanacka eller dylikt',
  'symptom-survey.question.increased-difficulty-stopping': 'har svårare att hejda sig då hen blir arg och kan bli <strong>hotfull</strong> eller <strong>våldsam</strong>',
  'symptom-survey.question.increased-difficulty-taking-initiative': '<strong>förändrad initiativförmåga.</strong> Har exempelvis svårare att ta sig för sysslor som tidigare varit rutiner eller har blivit överaktiv på ett sätt som skapar problem eller oordning',
  'symptom-survey.question.interpreting-maps': '<strong>tolka kartor, planskisser, mönster eller ritningar</strong>',
  'symptom-survey.question.light-hearted': 'är mera <strong>lättrörd</strong> ( gråtmild/”blödig” ) eller <strong>växlar humör hastigt</strong> utan tydlig orsak',
  'symptom-survey.question.missed-toilet': '<strong>har svårare att känna när hen behöver gå på toaletten,</strong> vilket kan leda till att hen kissar på sig',
  'symptom-survey.question.moments-of-absence': 'har korta <strong>stunder av frånvaro,</strong> där hen inte reagerar på tilltal eller ”fastnar” i en rörelse eller aktivitet på ett sätt som är svårt att bryta',
  'symptom-survey.question.more-sensitive-to-stress': 'är mera <strong>stresskänslig</strong> och kan dra sig undan sällskap eller intensiva aktiviteter. Har ett ökat behov av att göra en sak i taget.',
  'symptom-survey.question.more-taciturn': 'blivit mer <strong>fåordig</strong> och <strong>talet flyter sämre.</strong> Använder <strong>samma ord eller uttryck</strong> gång på gång',
  'symptom-survey.question.more-worried': 'är mera <strong>orolig, rastlös</strong> eller har blivit <strong>rädd</strong> för vissa situationer',
  'symptom-survey.question.most-noticeable-symptoms': '<strong>Vilket/vilka symtom</strong> av de som markerats i enkäten är det/de som nu märks <strong>tydligast?</strong>',
  'symptom-survey.question.moves-slower': '<strong>rör sig långsammare</strong>',
  'symptom-survey.question.moves-stiffer': '<strong>rör sig stelare och mera osmidigt</strong>',
  'symptom-survey.question.nuanced-opinions': '<strong>mera onyanserade åsikter,</strong> värderingar och synpunkter. Har svårare att ta till sig argument och <strong>resonerar ologiskt</strong>',
  'symptom-survey.question.patienten-agree-with-symptom-survey': '<strong>Håller patienten med om att de symtom Du/Ni angivit i enkäten stämmer?</strong>',
  'symptom-survey.question.performing-everyday-tasks': '<strong>utföra vardagliga praktiska sysslor</strong> trots tillräcklig rörlighet, t ex påklädning, hushållsarbete, handarbete, bilkörning eller användning av olika apparater såsom kaffebryggare, tvättmaskin, fjärrkontroll, mobil eller dator',
  'symptom-survey.question.reacts-slowly': '<strong>reagerar långsammare.</strong> Hinner inte med att öka tempot och göra det som behövs då något händer plötsligt',
  'symptom-survey.question.recognizing-objects': '<strong>känna igen</strong> vardagliga föremål, bilder, symboler eller ansikten på välkända personer. Hitta rätt ikon på dator eller mobil',
  'symptom-survey.question.space-for-comments': '<strong>Plats för kommentarer</strong>',
  'symptom-survey.question.speaks-less': '<strong>talar otydligare</strong> för att uttalet blivit sluddrigt eller för att rösten blivit svagare',
  'symptom-survey.question.stress-for-the-family': 'Har några av de markerade symtomen lett till <strong>påfrestningar för anhöriga?</strong>',
  'symptom-survey.question.symptoms-variations-day-to-day': '<strong>Varierar symtomen från dag till dag?</strong>',
  'symptom-survey.question.thinks-slower': '<strong>tänker trögare</strong> och behöver fundera längre innan hen svarar',
  'symptom-survey.question.understanding-heard-read': '<strong>förstå vad hen hör eller läser,</strong> t ex innehållet i meddelanden, tidningar eller program på radio/TV/mobil/dator',
  'symptom-survey.question.way-symptoms-develop': '<strong>På vilket sätt har symtomen utvecklats?</strong>',
  'symptom-survey.question.writing': '<strong>skriva,</strong> exempelvis genom att skriva brev, meddelanden eller fylla i blanketter',
  'symptom-survey.results.evident-specific-event-symptoms': 'Specifik händelse',
  'symptom-survey.results.first-noticed-symptoms': 'Första symtom',
  'symptom-survey.results.hard-to-manage-symptoms': 'Symtom svåra att hantera',
  'symptom-survey.results.most-noticeable-symptoms': 'Mest märkbara symtom',
  'symptom-survey.results.patienten-agree-with-symptom-survey': 'Patienten instämmer',
  'symptom-survey.results.stress-for-the-family': 'Symtom som är påfrestande för andra',
  'symptom-survey.section.mental-reactions-section.subtitle': 'Patienten ...',
  'symptom-survey.section.mental-reactions-section.title': 'Vanliga psykiska reaktioner och andra associerade symtom vid sjukdomar som påverkar hjärnan',
  'symptom-survey.section.other-questions-section.subtitle': 'Patienten ...',
  'symptom-survey.section.other-questions-section.title': 'Övriga, kompletterande/förtydligande frågor om observerade symtom',
  'symptom-survey.section.subcortical-regions-of-brain-section.subtitle': 'Patienten ...',
  'symptom-survey.section.subcortical-regions-of-brain-section.title': 'Vanliga symtom vid nedsatt funktion i djupare delar av hjärnan, under hjärnbarken',
  'symptom-survey.section.symptoms-of-impaired-function-in-frontal-lobes-of-brain.subtitle': 'Patienten har...',
  'symptom-survey.section.symptoms-of-impaired-function-in-frontal-lobes-of-brain.title': 'Vanliga symtom vid nedsatt funktion i hjärnans pannlober',
  'symptom-survey.section.symptoms-of-impaired-function-in-parietal-section.subtitle': 'Patienten har fått svårare att...',
  'symptom-survey.section.symptoms-of-impaired-function-in-parietal-section.title': 'Vanliga symtom vid nedsatt funktion i hjärnans hjäss- och tinninglober',
  'symptom-survey.section.symptoms-of-impaired-memory-section.subtitle': 'Patienten har svårare att...',
  'symptom-survey.section.symptoms-of-impaired-memory-section.title': 'Vanliga symtom på minnesstörning',
  'thePatient': 'patienten',
  'thePatientCapitalized': 'Patienten',
  'word.behaviour': 'beteende',
  'word.of': 'av',
  'word.pleaseWait': 'Vänligen vänta',
  'word.section': 'Sektion',
};
 export default trans;