/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InstitutionDTO } from '../models/InstitutionDTO';
import type { InstitutionStatisticDTO } from '../models/InstitutionStatisticDTO';
import type { ManageInstitutionDTO } from '../models/ManageInstitutionDTO';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class InstitutionService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all institutions
     * @returns InstitutionDTO
     * @throws ApiError
     */
    public getAllInstitutions(): CancelablePromise<Array<InstitutionDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/institution',
        });
    }
    /**
     * Create new institution
     * @param requestBody
     * @returns InstitutionDTO
     * @throws ApiError
     */
    public createInstitution(
        requestBody: ManageInstitutionDTO,
    ): CancelablePromise<InstitutionDTO> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/institution',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get all institutions statistic
     * @returns InstitutionStatisticDTO
     * @throws ApiError
     */
    public getStatistic(): CancelablePromise<Array<InstitutionStatisticDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/institution/statistic',
        });
    }
    /**
     * Update existent institution
     * @param id
     * @param requestBody
     * @returns InstitutionDTO
     * @throws ApiError
     */
    public updateInstitution(
        id: string,
        requestBody: ManageInstitutionDTO,
    ): CancelablePromise<InstitutionDTO> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/institution/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
