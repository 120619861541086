/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ManageMedStaffDto } from '../models/ManageMedStaffDto';
import type { MedStaffDto } from '../models/MedStaffDto';
import type { UserIdDto } from '../models/UserIdDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MedStaffService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param id
     * @param requestBody
     * @returns MedStaffDto
     * @throws ApiError
     */
    public updateMedStaffProfile(
        id: string,
        requestBody: ManageMedStaffDto,
    ): CancelablePromise<MedStaffDto> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/med-staff/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Instantly delete already archived med-staff
     * @param id
     * @returns UserIdDto
     * @throws ApiError
     */
    public deleteArchivedMedStaff(
        id: string,
    ): CancelablePromise<UserIdDto> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/med-staff/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @returns MedStaffDto
     * @throws ApiError
     */
    public getMedStaffProfile(
        id: string,
    ): CancelablePromise<MedStaffDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/med-staff/{id}',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param requestBody
     * @returns MedStaffDto
     * @throws ApiError
     */
    public createMedStaffProfile(
        requestBody: ManageMedStaffDto,
    ): CancelablePromise<MedStaffDto> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/med-staff',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get all med-staff
     * @returns MedStaffDto
     * @throws ApiError
     */
    public getAllMedStaff(): CancelablePromise<Array<MedStaffDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/med-staff',
        });
    }
    /**
     * Get archived med-staff
     * @returns MedStaffDto
     * @throws ApiError
     */
    public getArchivedMedStaff(): CancelablePromise<Array<MedStaffDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/med-staff/archived',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    public getLastViewed(): CancelablePromise<Array<Record<string, any>>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/med-staff/last-viewed',
        });
    }
    /**
     * @param id
     * @returns any
     * @throws ApiError
     */
    public rememberView(
        id: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/med-staff/{id}/remember-view',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @returns UserIdDto
     * @throws ApiError
     */
    public archiveMedStaff(
        id: string,
    ): CancelablePromise<UserIdDto> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/med-staff/{id}/archive',
            path: {
                'id': id,
            },
        });
    }
    /**
     * @param id
     * @returns UserIdDto
     * @throws ApiError
     */
    public restoreAchievedMedStaff(
        id: string,
    ): CancelablePromise<UserIdDto> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/med-staff/{id}/restore',
            path: {
                'id': id,
            },
        });
    }
}
