/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TranslationApp } from '../models/TranslationApp';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TranslationsService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get translations
     * @param app
     * @returns any
     * @throws ApiError
     */
    public get(
        app: TranslationApp,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/translations',
            query: {
                'app': app,
            },
        });
    }
    /**
     * Get translations batch
     * @param apps
     * @returns any
     * @throws ApiError
     */
    public getBatch(
        apps: Array<TranslationApp>,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/translations/batch',
            query: {
                'apps': apps,
            },
        });
    }
    /**
     * Force refresh translations
     * @returns boolean
     * @throws ApiError
     */
    public forceRefresh(): CancelablePromise<boolean> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/translations/refresh',
        });
    }
}
