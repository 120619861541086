export enum RouteNames {
    Survey = '/survey',
    RelativeForm = '/relative',
    PatientSearch = '/patient-search',
    NotFound = '/not-found',
    AccessDenied = '/access-denied',
    FinalSuccess = '/success',
    FreeResults = '/results',
    Blank = '/'
}
