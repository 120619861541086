/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class WellKnownService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Provides configuration files for Android/iOS deep linking.
     * @param filename
     * @returns any
     * @throws ApiError
     */
    public getFile(
        filename: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/.well-known/{filename}',
            path: {
                'filename': filename,
            },
        });
    }
}
