import {RelativeActionTypes, RelativeState, RelativeTypes} from "./types";

const initialState: RelativeState = {
    firstName: '',
    lastName: '',
    phone: '',
    date: ''
}

export function RelativeFormReducer (
    state = initialState,
    action: RelativeTypes
): RelativeState {
    switch(action.type) {
        case RelativeActionTypes.SAVE_RELATIVE_DATA:
            return {
                ...state,
                ...action.data
                };
        case RelativeActionTypes.CLEAR_RELATIVE_DATA:
            return {
                ...state,
                firstName: '',
                lastName: '',
                phone: ''
            };
        default:
            return state
    }
}
