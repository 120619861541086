import React, { useState } from "react";
import {ReactComponent as InfoIcon} from "../../assets/img/icons/information-outline.svg";
import {translate} from "../../store/Translations/translations";
import ProgressLinePointer from "../ProgressLinePointer";

type FamilyProgressLineProps = {
    maxIntervalValue: number;
    progressValue: number;
    legend: string;
    infoLegendKey: string;
}

const FamilyProgressLine: React.FC<FamilyProgressLineProps> = (props: FamilyProgressLineProps) => {
    const { maxIntervalValue, progressValue, legend, infoLegendKey } = props;
    const [showInfo, setShowInfo] = useState(false);
    
    return (
        <>
            <div className="card-header-results-header">
                <div className="risk_progress-legend flex align-center">
                    <div dangerouslySetInnerHTML={{ __html: legend }}></div>
                    <div 
                        className='flex-item--shrink' 
                        style={{ marginLeft: '8px', marginRight: '4px' }}
                        onMouseEnter={() => setShowInfo(true)}
                        onMouseLeave={() => setShowInfo(false)}
                    >
                        <InfoIcon className="info-icon" />
                    </div>
                    <div className="info_list" style={{ display: showInfo ? 'block' : 'none' }}>
                        {translate(infoLegendKey)}
                    </div>
                </div>
                <div className='card-header-duration_box'>
                    <ProgressLinePointer
                        progressValue={progressValue}
                        maxIntervalValue={maxIntervalValue}
                    />
                </div>
            </div>
            <div className='card-header-duration_box'>
                <div className="risk_progress-chart">
                    <span className='progress-line progress-line-family'></span>
                </div>
            </div>
        </>
    );
};

export default FamilyProgressLine;
