/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VerifyMobileNumberDto } from '../models/VerifyMobileNumberDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UserService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Mark that current user allowed us to export his data
     * @param dataExportAllowed
     * @returns any
     * @throws ApiError
     */
    public acceptTermsAndConditions(
        dataExportAllowed: string,
    ): CancelablePromise<Record<string, any>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/user/accept-terms-and-conditions',
            query: {
                'dataExportAllowed': dataExportAllowed,
            },
        });
    }
    /**
     * Get basic profile info for mobile app
     * @returns any
     * @throws ApiError
     */
    public getProfile(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/profile',
        });
    }
    /**
     * @returns string
     * @throws ApiError
     */
    public getAllPatientFiles(): CancelablePromise<Array<string>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/get-all-patient-files',
        });
    }
    /**
     * Used to prevent CORS issue due to fetch request to another domain
     * @param fileId
     * @returns any
     * @throws ApiError
     */
    public downLoadPatientFile(
        fileId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/user/download-patient-file/{fileId}',
            path: {
                'fileId': fileId,
            },
        });
    }
    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public verifyMobileNumber(
        requestBody: VerifyMobileNumberDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/user/verify-mobile-number',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns any
     * @throws ApiError
     */
    public mobileNumberVerified(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/user/mobile-number-verified',
        });
    }
}
