import React, { useEffect, useState } from "react"
import { ReactComponent as BrainTop } from "../../assets/img/brain-top.svg"
import { useSelector } from "react-redux"
import './SymptomSurveyResults.scss'
import {
  asCategory,
  fCategory,
  getBrainConfig,
  mCategory,
  ptCategory,
  skCategory, surveyCharts2,
  symptomSurveyScoreConfigs
} from "../../types/survey"
import { base64BrainImg } from "../../types/constants"

import {
  InputType,
  QuestionerAnswersDTOOutput,
  QuestionerDTO, QuestionScreenDTO,
  QuestionScreenMetadata,
  RadioGroupInputMetadata, ScreenType, SectionTitleScreenDTO, UniqueScreenDTO,
  UserDto
} from "../../sdk"
import classNames from "classnames"
import FamilyProgressLine from "../FamilyProgressLine"
import { isDefined } from "../../sdk/core/request";
import QuestionAnswerComponent from "../QuestionAnswer/QuestionAnswerComponent";
import useTranslate from "../../hooks/useTranslate";

export function isQuestionScreen(screen: QuestionScreenDTO | SectionTitleScreenDTO | UniqueScreenDTO): screen is QuestionScreenDTO {
  return screen.kind == ScreenType.QUESTION
}

export const getAnswersForSection = (questioner: QuestionerDTO, answers: QuestionerAnswersDTOOutput, section: number) =>
  questioner.sections[section].screens
    .map(sectionScreen => answers.answers
      .find(answer => answer.screen === sectionScreen.screen.id)).filter(isDefined)

export const getDementiaDuration = (questioner: QuestionerDTO, answers: QuestionerAnswersDTOOutput) => {
  let dementiaDuration: string[] = []
  const dementiaQuestion = findScreenBySlug(questioner, 'first-symptoms-noticed')
  const dementiaDurationAnswer = answers.answers.find(e => e.screen === dementiaQuestion?.id)
  // @ts-ignore
  const dementiaAnswerNotRelevant = dementiaDurationAnswer?.value === 'do-not-know-not-relevant'

  if (!dementiaAnswerNotRelevant) {
    const possibleValues = ((dementiaQuestion?.metadata as QuestionScreenMetadata).input.metadata as RadioGroupInputMetadata).values
      .filter(e => e.value !== 'do-not-know-not-relevant')
    // @ts-ignore
    const answerIndex = possibleValues.findIndex(e => e.value === dementiaDurationAnswer?.value)
    dementiaDuration = possibleValues.map((e, i) => dementiaDurationAnswer && i <= answerIndex ? 'active' : '')
  }

  return {dementiaDuration, dementiaAnswerNotRelevant}
}

export const getSymptomsDevelopChartPicture = (questioner: QuestionerDTO, answers: QuestionerAnswersDTOOutput) => {
  const symptomsManifestationsQuestion = findScreenBySlug(questioner, 'way-symptoms-develop')
  const symptomsManifestationsAnswer = answers.answers.find(e => e.screen === symptomsManifestationsQuestion?.id)
  // @ts-ignore
  return surveyCharts2[symptomsManifestationsAnswer?.value]
}

const normalizeAnswerValue2 = (answerValue: SsAnswerVariant) => {
  switch (answerValue) {
    case SsAnswerVariant.idk:
      return 'white'
    case SsAnswerVariant.no:
      return 'green'
    case SsAnswerVariant.maybe:
      return 'yellow'
    case SsAnswerVariant.yes:
      return 'red'
  }
}

const getAnswerClass = (answerData: string) => {
  return normalizeAnswerValue2(answerData as SsAnswerVariant)
}

const toZeroFirstFormat = (strToFormat: string | number): string => {
  if (strToFormat.toString().length > 1) {
    return strToFormat.toString()
  }
  return `0${strToFormat}`.substring(-2)
};

const formatToDate = (date: string | Date): string => {
  if (!date) {
    return '';
  }
  const newDate = typeof date === 'string' ? new Date(date) : date;
  return [
    `${toZeroFirstFormat(newDate.getDate())}/${toZeroFirstFormat(newDate.getMonth() + 1)}/${newDate.getFullYear()}`,
  ].join(' ');
}

export enum SsAnswerVariant {
  yes = 'yes-pronounced-often',
  maybe = 'yes-somewhat-sometimes',
  no = 'no',
  idk = 'dont-know-cant-be-assessed'
}

export type AnswerDTO = {
  screen: string;
  value: any;
  timeUsed?: Array<number>;
  points?: Array<number>;
  validValue?: Record<string, any>;
};


type SymptomSurveyResultsProps = {
  survey: QuestionerAnswersDTOOutput;
  patient: Pick<UserDto, 'firstName' | 'lastName'>;
  relative: { firstName: string, lastName: string, date: string };
  selectedLanguage: string;
  freeVersion: boolean;
}

const calculateActualMaxSymptomSurveyScore = (
  answers: Pick<AnswerDTO, 'value'>[] = [],
): number => {
  return answers.reduce((acc, item) => item.value !== SsAnswerVariant.idk ? acc + 2 : acc, 0)
}

const calculateSymptomSurveyScore = (answers: Pick<AnswerDTO, 'value'>[] = []): number => {
  return answers.reduce((accumValue, answer): number => {
    let score = 0
    if (answer.value === SsAnswerVariant.yes) {
      score = 2
    }
    if (answer.value === SsAnswerVariant.maybe) {
      score = 1
    }
    accumValue += score
    return accumValue
  }, 0)
}

const findScreenBySlug = (questioner: QuestionerDTO, slug: string) => questioner.sections[5].screens.find(e => e.screen.slug === slug)?.screen
export const getRelativeDegree = (questioner: QuestionerDTO, answers: QuestionerAnswersDTOOutput) => {
  const relativeQuestion = findScreenBySlug(questioner, 'how-related-with-patienteny')
  return answers.answers.find(e => e.screen === relativeQuestion?.id)?.value
}

const SymptomSurveyResults: React.FC<SymptomSurveyResultsProps> = (props: SymptomSurveyResultsProps) => {
  const {survey, patient, freeVersion, relative} = props
  const translateLocalazy = useTranslate()
  const patientName = freeVersion ? 'Patient' : patient.firstName + ' ' + patient.lastName
  const surveyConfigs = useSelector(getBrainConfig)
  const [base64Image, setBase64Image] = useState('')
  const parietal = getAnswersForSection(survey.questioner, survey, 0)
  const frontal = getAnswersForSection(survey.questioner, survey, 1)
  const subcortical = getAnswersForSection(survey.questioner, survey, 2)
  const memory = getAnswersForSection(survey.questioner, survey, 3)
  const associatedSymptoms = getAnswersForSection(survey.questioner, survey, 4)

  const symptomsManifestationsAnswer = getSymptomsDevelopChartPicture(survey.questioner, survey)
  const {dementiaDuration, dementiaAnswerNotRelevant} = getDementiaDuration(survey.questioner, survey)

  const getBase64Image = (img: HTMLImageElement) => {
    const canvas = document.createElement('canvas')
    canvas.width = img.width
    canvas.height = img.height
    const ctx = canvas.getContext('2d')!
    ctx.drawImage(img, 0, 0)
    return canvas.toDataURL('image/png')
  }

  useEffect(() => {
    const image = new Image()
    image.src = symptomsManifestationsAnswer
    image.onload = () => {
      const base64 = getBase64Image(image).replaceAll('image/png', 'image//png')
      setBase64Image(base64)
    }
  }, [symptomsManifestationsAnswer])


  const freeTextEnabled = true
  const selectorsEnabled = true
  const allQuestions = survey.questioner.sections
    .flatMap(e => e.screens)
    .map(e => e.screen)
    .filter(isQuestionScreen)

  const inputAnswers =
    allQuestions
      .filter((screen) => {
        if (screen?.metadata.input.kind === InputType.FREE_TEXT && freeTextEnabled) {
          return true
        }
        return (screen?.metadata.input.kind === InputType.RADIO_GROUP || screen?.metadata.input.kind === InputType.SELECTOR)
          && selectorsEnabled
      })
      .map((screen, index) => {
        const answer = survey.answers.find(e => e.screen === screen.id)

        return {
          number: index! + 1,
          screen: screen!,
          answer: answer || {value: ''},
        }
      })

  const diagnostics = [
    {
      answers: frontal,
      illness: fCategory.map((v, i) => ({...v, grade: surveyConfigs[parseInt('0')].frontal[i]}))
    },
    {
      answers: parietal,
      illness: ptCategory.map((v, i) => ({...v, grade: surveyConfigs[parseInt('0')].temporal[i]}))
    },
    {
      answers: subcortical,
      illness: skCategory.map((v, i) => ({...v, grade: surveyConfigs[parseInt('0')].subcortical[i]}))
    },
    {
      answers: memory,
      illness: mCategory.map((v, i) => ({...v, grade: surveyConfigs[parseInt('0')].memory[i]}))
    },
    {
      answers: associatedSymptoms,
      illness: asCategory.map((v, i) => ({...v, grade: surveyConfigs[parseInt('0')].associatedSymptoms[i]}))
    },
  ]
  const maxScore = calculateActualMaxSymptomSurveyScore([
    ...parietal,
    ...frontal,
    ...subcortical,
    ...memory,
    ...associatedSymptoms,
  ])
  const maxScorePossible = symptomSurveyScoreConfigs.maxScoreAnswerValue * (symptomSurveyScoreConfigs.endIndex + 1)
  const currentScore = calculateSymptomSurveyScore([
    ...parietal,
    ...frontal,
    ...subcortical,
    ...memory,
    ...associatedSymptoms,
  ])

  return <div className="diagnostic_test_body">
    <div className="survey-test-top-content">
      <section className="test_general-info_box mt-10">
        <div className="flex justify-between">
          <div className="inline-block">
            <div className="common-title">{translateLocalazy('hcp.Answered by')}</div>
            <div className="info-body">{`${relative.firstName ?? ''}
                           ${relative?.lastName ?? ''}`}</div>
          </div>

          <div className="inline-block">
            <div className="common-title">{translateLocalazy('hcp.Relationship')}</div>
            {/*@ts-ignore*/}
            <div className="info-body">{translateLocalazy('hcp.' + getRelativeDegree(survey.questioner, survey))}</div>
          </div>

          <div className="inline-block">
            <div className="common-title">{translateLocalazy('hcp.Date answered')}</div>
            <div className="info-body">{formatToDate(relative.date)}</div>
          </div>
        </div>
      </section>

      <section className="preliminary-results_box">
        <div className="flex justify-between">
          <div className="inline-block">
            <div className="common-title">{translateLocalazy('hcp.Duration in years')}</div>
            {!dementiaAnswerNotRelevant &&
              <div className="dementia-duration_box">
                <ul className="dementia_sections-list">
                  {dementiaDuration.map((item, index) => {
                    return <li key={`dementia-${index}`} className={item}>&nbsp;</li>
                  })
                  }
                </ul>
                <ul className="dementia_values-list">
                  <li>&lt;1/2</li>
                  <li>1/2-1</li>
                  <li>1-2</li>
                  <li>2-3</li>
                  <li>3-5</li>
                  <li>&gt;5</li>
                </ul>
              </div>
            }
            {dementiaAnswerNotRelevant &&
              <div className="dementia-duration_box">
                <p className="">{translateLocalazy('hcp.answered_as_not_relevant')}</p>
              </div>
            }
            {
              <div style={{marginTop: 10}}>
                <FamilyProgressLine
                  infoLegendKey={'hcp.symptom_survey.riskLegend.hint'}
                  legend={`${translateLocalazy('hcp.Points')}: <strong>${currentScore}</strong> / <strong>${maxScore}</strong> (${translateLocalazy('hcp.max_if_all_answered')}. ${maxScorePossible})`}
                  progressValue={currentScore}
                  maxIntervalValue={maxScore}
                />
              </div>
            }
          </div>
          <div className="inline-block">
            <div className="common-title">{translateLocalazy('hcp.Total points')}</div>
            <ul className="commot-list">
              <li><strong>{calculateSymptomSurveyScore(frontal)}</strong>&nbsp;{translateLocalazy('hcp.Frontal')}</li>
              <li><strong>{calculateSymptomSurveyScore(parietal)}</strong>&nbsp;{translateLocalazy('hcp.Parietal / Temporal')}
              </li>
              <li><strong>{calculateSymptomSurveyScore(subcortical)}</strong>&nbsp;{translateLocalazy('hcp.Subcortical')}</li>
              <li><strong>{calculateSymptomSurveyScore(memory)}</strong>&nbsp;{translateLocalazy('hcp.Memory')}</li>
              <li>
                <strong>{calculateSymptomSurveyScore(associatedSymptoms)}</strong>&nbsp;{translateLocalazy('hcp.Associated symptoms')}
              </li>
            </ul>
          </div>
          <div className="inline-block">
            <div className="course-сhart_wrapper">
              <div className="common-title">{translateLocalazy('hcp.Course')}</div>
              <img className="courseChart" src={base64Image} alt="chart-picture"/>
            </div>
          </div>
        </div>
      </section>
    </div>

    <>
      <section>
        <div className="flex-container disfunctions_analize">
          <div className="brains-analize-wrapper">
            <div className="brain_map_box">
              <div className="brain_layer brain_layer--profil">
                <div className="additional-wrapper">
                  <img className="BrainProfil" src={base64BrainImg}/>
                  <div className="brain_points-list">
                    {frontal.map((a, i) => (
                      <div className={`brain_point-item f${i} ${getAnswerClass(a.value as any)}`}
                           key={`f${i}`} data-content={translateLocalazy('hcp.' + fCategory[i].title)}>
                        <span>{`F${i + 1}`}</span>
                      </div>
                    ))}
                    {parietal.map((a, i) => (
                      <div
                        className={`brain_point-item pt${i} ${getAnswerClass(a.value as any)}`}
                        key={`pt${i}`} data-content={translateLocalazy('hcp.' + ptCategory[i].title)}>
                        <span>{`PT${i + 1}`}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="brain_map_box">
              <div className="brain_layer brain_layer--top">
                <BrainTop/>
                <ul className="brain_points-list">
                  {subcortical.map((a, i) => (
                    <div className={`brain_point-item sk${i} ${getAnswerClass(a.value as any)}`}
                         key={i}
                         data-content={translateLocalazy('hcp.' + skCategory[i].title)}>
                      <span>{`SK${i + 1}`}</span>
                    </div>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="spoiler_body brain_graph_analize">
        <div className="survey-diagnostic-header">
          <span>{translateLocalazy('hcp.Frontal')}</span>
          <span>{translateLocalazy('hcp.Parietal / Temporal')}</span>
          <span>{translateLocalazy('hcp.Subcortical')}</span>
          <span>{translateLocalazy('hcp.Memory')}</span>
          <span>{translateLocalazy('hcp.Associated symptoms')}</span>
        </div>
        <div className="flex-container diagnostic_question_body">
          <div className="survey-diagnostic-wrapper">
            {
              diagnostics.map((diagnostic, index) => <ul className="survey-diagnostic-list" key={index}>
                {diagnostic.illness.map((item, i) => (
                  <li key={i}>
                    <div
                      className={`brain-zone_color ${getAnswerClass(diagnostic.answers[i].value as any)}`}>{item.type}</div>
                    <p className="m-0 brain-zone_title">{translateLocalazy('hcp.' + item.title)}</p>
                  </li>
                ))}
              </ul>)
            }
          </div>
        </div>
      </div>
      <div className="disfunctions_legend">
        <div className="legend-item_wrapper">
          <div
            className={classNames(['legend-item', 'red'])}>
          </div>
          <span>{translateLocalazy('hcp.p-1')}</span>
        </div>
        <div className="legend-item_wrapper">
          <div className={classNames(['legend-item', 'yellow'])}/>
          <span>{translateLocalazy('hcp.p-2')}</span>
        </div>
        <div className="legend-item_wrapper">
          <div className={classNames(['legend-item', 'green'])}/>
          <span>{translateLocalazy('hcp.p-3')}</span>
        </div>
        <div className="legend-item_wrapper">
          <div className="legend-item white"/>
          <span>{translateLocalazy('hcp.p-4')}</span>
        </div>
      </div>
    </>
    {
      inputAnswers ? inputAnswers.map((el) => <QuestionAnswerComponent
        key={el.screen.id}
        patientName={patientName}
        question={el.screen}
        questionNumber={el.number}
        answer={el.answer as any}
      />) : null
    }
    <p style={{fontSize: '9px'}}>
      **Åstrand R, Rolstad S, Wallin A. Cognitive Impairment Questionnaire (CIMP-QUEST): reported topographic
      symptoms in MCI and dementia. Acta Neurol Scand. 2010:121:384-391.
      <br/>
      Åstrand R. Neurokognitiv Symtomenkät: Hjärnregionalt orienterad och grafiskt vägledande presentation av
      rapporterade symtom vid diagnostik av demenssjukdom. Svensk Geriatrik.
      <br/>
      2015;2:22-6.<a rel="noopener noreferrer" target="_blank"
                     href="https://www.minnesmottagningen.se/forskning-evidens">www.gerassolutions.com</a>
    </p>
  </div>
}

export default SymptomSurveyResults
