import { SurveyActionTypes, SendTrackingEvent } from "./types"
import { all, put, select, takeLatest } from "redux-saga/effects"
import {
    clearAnswersState,
    clearQuestionsState,
    saveSurveyAnswersFail,
    saveSurveyAnswersSuccess,
    setSurveyErrorMsg,
} from "./action"
import { getAnswersState, questionerIdSelector } from "./surveyStateSelectors"
import { logout } from "../Auth/action"
import { clearRelativeData } from "../Relative/action"
import { showErrorNotification } from "../App/action"
import { getRelativeDataDto } from "../Relative/relativeData"
import { getAuthCredsData } from "../Auth/authSelectors"
import { apiV2, SdkResponse, wrapResponse } from "../../sdk/api"
import { AnswerDTO } from "../../sdk"
import { RelativeDto } from '../Relative/types'

function* handleSaveAnswersToServer(): Generator<any> {
    const answers = (yield select(getAnswersState)) as AnswerDTO[]
    const questionerId = (yield select(questionerIdSelector)) as string
    const authData: any = yield select(getAuthCredsData)
    const userData = (yield select(getRelativeDataDto)) as RelativeDto
    const options = {
        headers: {},
        data: {
            userData,
            answers
        }
    }

    if (authData.token) {
        options.headers = {
            ['Authorization']: `Bearer ${authData.token}`
        }
    } else {
        if (authData.personalNumber) {
            options.headers = {
                ['personalNumber']: authData.personalNumber
            }
        }
        if (authData.email) {
            options.headers = {
                ['patientEmail']: authData.email
            }
        }
    }

    const updateInfo = apiV2.oneTimeAuth.updateInfo.bind(apiV2.oneTimeAuth)
    yield wrapResponse(updateInfo(userData))

    const saveAnswer = apiV2.questionerAnswer.saveSymptomSurvey.bind(apiV2.oneTimeAuth)
    const today = new Date()
    const currentTime = today.getUTCHours() + ':' + today.getUTCMinutes() + ':' + today.getUTCSeconds()
    const saveAnswerRes = (yield wrapResponse(saveAnswer({
        questioner: questionerId,
        completed: true,
        answers,
        customDate: userData.date + ' ' + currentTime,
    }))) as SdkResponse<typeof saveAnswer>
    if (!saveAnswerRes.success) {
        yield put(saveSurveyAnswersFail())
        yield put(setSurveyErrorMsg(saveAnswerRes.error))
        yield put(showErrorNotification(saveAnswerRes.error))
        return
    }
    yield put(saveSurveyAnswersSuccess())
    yield put(logout())
    yield put(clearRelativeData())
    yield put(clearAnswersState())
    yield put(clearQuestionsState())
}

function* handleSaveTrackingEvent(action: SendTrackingEvent): Generator<any> {
    const trackingEvent = action.payload;
    const trackEvent = apiV2.tracking.trackEvent.bind(apiV2.tracking);
    
    try {
        yield wrapResponse(trackEvent({
            eventType: trackingEvent
        }));
    } catch (error) {
        console.error('Failed to track event:', error);
    }
}

function* surveySaga(): Generator {
    yield all([
        takeLatest(SurveyActionTypes.SAVE_SURVEY_ANSWERS_TO_SERVER, handleSaveAnswersToServer),
        takeLatest(SurveyActionTypes.SEND_TRACKING_EVENT, handleSaveTrackingEvent),
    ])
}

export default surveySaga
