/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum InputType {
    FREE_TEXT = 'free-text',
    RADIO_GROUP = 'radio-group',
    TWO_CHOICE = 'two-choice',
    CHECKBOX_GROUP = 'checkbox-group',
    SELECTOR = 'selector',
}
