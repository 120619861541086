import {RegularAnswerPayload, SurveyActionTypes, SurveyTypes} from "./types";
import { TrackingEventType } from "../../sdk";

export const getSurveyQuestionsRequest = (): SurveyTypes => ({ type: SurveyActionTypes.GET_SURVEY_DATA });
export const setSurveyQuestionsSet  = (success: any): SurveyTypes => ({ type: SurveyActionTypes.SET_SURVEY_QUESTIONS, data: success });
export const fetchQuestionsSuccess = (): SurveyTypes => ({ type: SurveyActionTypes.POST_SUCCESS_SURVEY_QUESTIONS });
export const fetchQuestionsFail = (): SurveyTypes => ({ type: SurveyActionTypes.POST_FAIL_SURVEY_QUESTIONS });
export const setSurveyErrorMsg = (errorMsg: string): SurveyTypes => ({ type: SurveyActionTypes.SET_SURVEY_ERROR_MSG, errorMsg });
export const answerRegularQuestion = (payload: { id: string, value: string }): SurveyTypes => ({ type: SurveyActionTypes.ANSWER_REGULAR_QUESTION, payload });
export const saveRegularQuestionAnswer = (payload: RegularAnswerPayload): SurveyTypes => ({ type: SurveyActionTypes.SAVE_REGULAR_QUESTION_ANSWER, payload });
export const saveSurveyAnswersToServer = (): SurveyTypes => ({ type: SurveyActionTypes.SAVE_SURVEY_ANSWERS_TO_SERVER });
export const showFreeResults = (): SurveyTypes => ({ type: SurveyActionTypes.SHOW_FREE_RESULTS });
export const sendTrackingEvent = (payload: TrackingEventType): SurveyTypes => ({ type: SurveyActionTypes.SEND_TRACKING_EVENT, payload });
export const clearAnswersState = (): SurveyTypes => ({ type: SurveyActionTypes.CLEAR_ANSWERS });
export const clearQuestionsState = (): SurveyTypes => ({ type: SurveyActionTypes.CLEAR_QUESTIONS });
export const saveSurveyAnswersFail = (): SurveyTypes => ({ type: SurveyActionTypes.POST_ANSWERS_TO_SERVER_FAIL });
export const saveSurveyAnswersSuccess = (): SurveyTypes => ({ type: SurveyActionTypes.POST_ANSWERS_TO_SERVER_SUCCESS });
