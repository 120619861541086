/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppVersionDTO } from '../models/AppVersionDTO';
import type { AppVersionSettingDTO } from '../models/AppVersionSettingDTO';
import type { LatestQuestionerVersionsSettingDTO } from '../models/LatestQuestionerVersionsSettingDTO';
import type { ProjectSettingDTO } from '../models/ProjectSettingDTO';
import type { UpdateAppModalDto } from '../models/UpdateAppModalDto';
import type { UpdateAppModalVisibleDto } from '../models/UpdateAppModalVisibleDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ProjectSettingService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param requestBody
     * @returns ProjectSettingDTO
     * @throws ApiError
     */
    public upsertQuestionerVersion(
        requestBody: LatestQuestionerVersionsSettingDTO,
    ): CancelablePromise<ProjectSettingDTO> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/project-setting',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get all settings
     * @returns ProjectSettingDTO
     * @throws ApiError
     */
    public getAll(): CancelablePromise<Array<ProjectSettingDTO>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project-setting',
        });
    }
    /**
     * @param requestBody
     * @returns AppVersionDTO
     * @throws ApiError
     */
    public upsertAppVersion(
        requestBody: AppVersionSettingDTO,
    ): CancelablePromise<AppVersionDTO> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/project-setting/app-version',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @returns AppVersionDTO
     * @throws ApiError
     */
    public getAppVersions(): CancelablePromise<AppVersionDTO> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project-setting/app-version',
        });
    }
    /**
     * Check for mobile app whether new app available or not
     * @param buildNumber
     * @param platform
     * @returns UpdateAppModalDto
     * @throws ApiError
     */
    public isNewAppAvailable(
        buildNumber: string,
        platform: string,
    ): CancelablePromise<UpdateAppModalDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project-setting/new-app-available/{buildNumber}/{platform}',
            path: {
                'buildNumber': buildNumber,
                'platform': platform,
            },
        });
    }
    /**
     * @param requestBody
     * @returns UpdateAppModalVisibleDto
     * @throws ApiError
     */
    public setUpdateAppModal(
        requestBody: ProjectSettingDTO,
    ): CancelablePromise<UpdateAppModalVisibleDto> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/project-setting/new-app-available',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get swagger file
     * @returns any
     * @throws ApiError
     */
    public getSwaggerFile(): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/project-setting/swagger',
        });
    }
}
