const trans = {
  'accessDeniedPage.header': 'Ups, noget gik galt',
  'accessDeniedPage.helpMessage': 'Kontroller, at linket er korrekt',
  'da': 'Danish',
  'date': 'Dato for passering',
  'en': 'engelsk',
  'errors.generalAuthPNRError': 'Det oplyste personnummer kan ikke bruges til godkendelse',
  'errors.generalAuthTokenError': 'Det angivne link kan ikke bruges til godkendelse',
  'errors.invalidOrNoToken': 'Det angivne link er ikke gyldigt eller indeholder ikke nødvendige data',
  'errors.invalidPNRError': 'Ugyldigt personnummer angivet',
  'errors.noCredsToSaveError': 'Angivne legitimationsoplysninger er ikke gyldige eller indeholder ikke nødvendige data',
  'errors.noPatientError': 'Ingen data fundet',
  'errors.noPNRError': 'Intet personnummer angivet',
  'errors.noUserWithPNR': 'Der er ingen patient med det oplyste personnummer',
  'errors.someDataIsNotValidError': 'Nogle af de angivne data er ikke gyldige',
  'errors.tokenWasAlreadyAnswered': 'Det ser ud til, at undersøgelsen allerede blev besvaret via dette link',
  'errors.unexpectedError': 'Der opstod en uventet fejl',
  'errors.unknownError': 'Der opstod en ukendt fejl',
  'firstName': 'Dit fornavn',
  'form.error.emailFormat': 'Tjek venligst formatet på e-mail',
  'form.error.mustNotEmpty': 'Feltet må ikke være tomt',
  'form.error.persNumberFormat': 'Tjek venligst længden og formatet på personnummeret',
  'form.hint.fieldIsOptional': 'Valgfrit',
  'form.hint.mustNotEmpty': 'Obligatorisk',
  'form.placeholder.select': 'Vælg dit svar...',
  'form.placeholder.typeHere': 'Skriv her...',
  'hcp.Absence attack': 'Fraværsangreb',
  'hcp.account.search.placeholder': 'Søg på navn...',
  'hcp.accounts': 'Konti',
  'hcp.actions': 'handlinger',
  'hcp.activateTestsInApp': 'Aktiver i app',
  'hcp.Add care provider for referral / consultation': 'Brug plejer-id til at henvise (f.eks. GSAPP)',
  'hcp.Add comment': 'Tilføj note og log af',
  'hcp.Add note': 'Tilføj note',
  'hcp.Add recommendation': 'Tilføj anbefaling',
  'hcp.Add summary': 'Tilføj oversigt',
  'hcp.Add-voucher': 'Ny kode',
  'hcp.add_referal': 'Tilføj',
  'hcp.add_sign_note_button': 'Tilføj / Signer',
  'hcp.addComment': 'Tilføj en kommentar',
  'hcp.admin': 'Admin',
  'hcp.administrator': 'Administrator',
  'hcp.age': 'Alder',
  'hcp.Aggressivness': 'Aggressivitet',
  'hcp.agreement': 'Jeg accepterer vilkårene og betingelserne',
  'hcp.agreement_1': 'Jeg accepterer',
  'hcp.agreement_2': 'Vilkår og betingelser',
  'hcp.agreement_3': 'Fortrolighedspolitik',
  'hcp.agreement_4': 'Forskningsbetingelserne',
  'hcp.alcoholNoteRisk': 'Kvinder, der scorer 3 eller højere, drikker over anbefalede grænser og har øget risiko for skade, mens mænd skal have 4 eller højere for det samme. Kilde:<i> Survey of Healthcare Experiences of Patients, 2008, VA Office of Quality and Performance.</i><br /><br /> Når punkterne alle er fra Q1 alene (hvor Q2 og Q3 er 0), kan det antages, at patienten drikker under anbefalede grænser, og yderligere undersøgelse bør udføres med resultater fra de sidste par måneder for at bekræfte nøjagtigheden.',
  'hcp.Alkohol (Audit-C) och rökning': 'Alkohol (Audit-C) og rygning',
  'hcp.all': 'Alle',
  'hcp.All data related to this user will be erased. This action can be undone.': 'Alle data relateret til denne bruger vil blive slettet. Denne handling kan fortrydes.',
  'hcp.allAccounts': 'Alle konti',
  'hcp.Allmänt': 'Generelt',
  'hcp.allPatients': 'Alle patienter',
  'hcp.Alzheimer\'s disease with cerebrovascular injury - AD + VaD': 'Alzheimers sygdom med cerebrovaskulær skade - AD + VaD',
  'hcp.Alzheimer\'s disease, mild dementia - Early onset': 'Alzheimers sygdom, let demens - Tidlig debut',
  'hcp.Alzheimer\'s disease, mild-moderate dementia - Late onset': 'Alzheimers sygdom, let-moderat demens - Sen debut',
  'hcp.Alzheimer\'s disease, prodromal - Amnestic MCI': 'Alzheimers sygdom, prodromal - Amnestisk MCI',
  'hcp.amnesis': 'Amnesi',
  'hcp.anamnesis': 'Anamnese',
  'hcp.Anamnesis': 'Anamnese',
  'hcp.anamnesisFlex': 'Anamnese',
  'hcp.anamnesisFlexNew': 'Anamnese',
  'hcp.anamnesisFlexNew.categories.habits': 'Levevaner',
  'hcp.anamnesisFlexNew.categories.medical': 'Medicinsk baggrund',
  'hcp.anamnesisFlexNew.categories.screening': 'Screening',
  'hcp.anamnesisFlexNew.categories.social': 'Livsstil og symptomer',
  'hcp.anamnesisSettings': 'Anamneseafsnit skal medtages',
  'hcp.and': 'og',
  'hcp.Android_version': 'Android app',
  'hcp.Answer': 'Svar',
  'hcp.answer': 'Svar',
  'hcp.Answer was not provided!': 'Svar blev ikke givet!',
  'hcp.answer_skipped_idk': 'Sprang over på grund af svaret &quot;Jeg ved det ikke&quot; på foregående spørgsmål',
  'hcp.answer_skipped_no': 'Sprang over på grund af svaret &quot;Nej&quot; på spørgsmålet',
  'hcp.Answered by': 'Besvaret af',
  'hcp.answered_as_not_relevant': 'Svar: Ved ikke/ikke relevant',
  'hcp.Anxiety': 'Angst',
  'hcp.Appetite control': 'Appetitkontrol',
  'hcp.appointment-with': 'Aftale med',
  'hcp.appointments': 'Aftaler',
  'hcp.April': 'April',
  'hcp.Archive user': 'Arkiv bruger',
  'hcp.Are you sure you want to archive this user?': 'Er du sikker på, at du vil arkivere brugeren?',
  'hcp.Are you sure you want to completely delete this user?': 'Er du sikker på, at du vil slette denne bruger fuldstændigt?',
  'hcp.Are you sure you want to delete this user?': 'Er du sikker på, at du vil slette brugeren?',
  'hcp.Are you sure you want to restore this user?': 'Er du sikker på, at du vil gendanne denne bruger?',
  'hcp.assistant_nurse': 'Hjælpesygeplejerske',
  'hcp.Associated symptoms': 'Associerede symptomer',
  'hcp.At': 'Ved',
  'hcp.attempt': 'Opgave',
  'hcp.Attempt': 'Forsøg',
  'hcp.atTime': 'Kl.',
  'hcp.August': 'august',
  'hcp.availability': 'Tilgængelighed',
  'hcp.Availability': 'Tilgængelighed',
  'hcp.availability-page.avails': 'Ledige tider',
  'hcp.availability-page.text': 'Vælg plus-ikonet for at indstille din tilgængelighed for en valgt dag. Fjernelse af tilgængelighed for et slot, som allerede har en bekræftet patientaftale, vil ikke fjerne patientens aftale. Aftaler skal aflyses',
  'hcp.availability-page.title': 'Mine aftaler',
  'hcp.available times': 'Ledige tider',
  'hcp.Average of similar healthy patients': 'Gennemsnit af lignende raske patienter',
  'hcp.back': 'Tilbage',
  'hcp.Balance': 'balance',
  'hcp.bankId': 'Bank-id',
  'hcp.basicInformation': 'Grundlæggende oplysninger',
  'hcp.Behaviour': 'Opførsel',
  'hcp.birthDate': 'Fødselsdato',
  'hcp.Bladder control': 'Blærekontrol',
  'hcp.Body perception': 'Kropsopfattelse',
  'hcp.booked meetings': 'Bookede møder',
  'hcp.calendar': 'Kalender',
  'hcp.Call': 'Ring',
  'hcp.Call now': 'Ring nu',
  'hcp.Cam': 'Cam',
  'hcp.cancel': 'Afbestil',
  'hcp.Care provider with access to patient account': 'Plejeudbyder med adgang til patientkonto',
  'hcp.careGiver': 'Medicinsk personale',
  'hcp.Category': 'Kategori',
  'hcp.change': 'ændre',
  'hcp.child': 'Barn',
  'hcp.clear': 'Klar',
  'hcp.clinical_pharmacologist': 'Klinisk farmakolog',
  'hcp.close-panel': 'Luk panelet',
  'hcp.coding test hint': '1 point for hver 10 rigtige',
  'hcp.cognitive': 'Kognitiv test (GSCT)',
  'hcp.Cognitive': 'Kognitiv test (GSCT)',
  'hcp.Cognitive Disease': 'Kognitiv sygdom',
  'hcp.Cognitive test': 'Kognitiv test',
  'hcp.Cognitive test completed': 'Kognitiv test gennemført',
  'hcp.Collapse': 'Minimer',
  'hcp.common_error_message': 'Der opstod en ukendt fejl.',
  'hcp.Complete': 'Færdiggør',
  'hcp.Completed': 'Færdiggjort',
  'hcp.Computability': 'Beregnelighed',
  'hcp.Confirm': 'Bekræfte',
  'hcp.Confirm new password': 'Bekræft ny adgangskode',
  'hcp.Confirm password': 'Bekræft adgangskode',
  'hcp.Confusion episodes': 'Forvirringsepisoder',
  'hcp.Consent obtained': 'Samtykke opnået',
  'hcp.Construction': 'Konstruktion',
  'hcp.contacts': 'Kontaktpersoner',
  'hcp.contacts.heading.clinicalAssessmentsAndEvidence': 'Kliniske vurderinger og evidens',
  'hcp.contacts.heading.otherInquiries': 'Andre forespørgsler',
  'hcp.contacts.heading.patientAdministrationAndAccounts': 'Administration &amp; patientdata',
  'hcp.contacts.heading.technologyProductPatientData': 'Teknologi, produkt- og patientdata',
  'hcp.contacts.position.chiefMedicalOfficer': 'Medicinsk ansvarlig læge',
  'hcp.contacts.position.headOfClinicalOperationsAndImplementations': 'Ansvarlig for implementeringer',
  'hcp.contacts.position.headOfTechnologyAndProductDevelopment': 'Chef for teknologi og produktudvikling',
  'hcp.contactSupport.btn': 'Kontakt support',
  'hcp.contactSupport.text': 'Kontakt os, så svarer vi hurtigst muligt',
  'hcp.Correct': 'Korrekt',
  'hcp.country': 'Land',
  'hcp.country-sv': 'Sverige',
  'hcp.country-us': 'USA',
  'hcp.country-zh': 'Kina',
  'hcp.Course': 'Symptomforløb',
  'hcp.Create': 'skab',
  'hcp.create_fm': 'Opret en konto for at gennemføre Symptom Survey',
  'hcp.createCareGiveAccount': 'Opret konto til plejepersonale',
  'hcp.createdAt': 'Registreringsdato',
  'hcp.createNewAccount': 'Opret konto',
  'hcp.createNewAccountSuccessMessage': 'Der er oprettet en konto',
  'hcp.createNewPatient': 'Opret patient',
  'hcp.createNewPatientSuccessMessage': 'Du har oprettet en ny patient',
  'hcp.da': 'Danish',
  'hcp.date': 'Dato',
  'hcp.Date answered': 'Dato besvaret',
  'hcp.dateAdded': 'Dato tilføjet',
  'hcp.dateScheduled': 'Dato planlagt',
  'hcp.Day': 'dag',
  'hcp.December': 'december',
  'hcp.Delete user': 'Slet bruger',
  'hcp.deleteAccount': 'Arkiver konto',
  'hcp.deletedAccounts': 'Arkiverede konti',
  'hcp.deletedPatients': 'Arkiverede patienter',
  'hcp.deletedPatients.counter.plural': 'Arkiverede patienter',
  'hcp.deletedPatients.counter.singular': 'Arkiveret patient',
  'hcp.deletePatient': 'Arkiver patient',
  'hcp.Delusions': 'Vrangforestillinger',
  'hcp.Dementia': 'Demens',
  'hcp.Depression': 'Depression',
  'hcp.deselect-all-and-save': 'Fravælg alt og gem',
  'hcp.Details': 'detaljer',
  'hcp.diagnosis': 'Diagnose',
  'hcp.diastolic-pressure': 'Mit sædvanlige diastoliske tryk',
  'hcp.doctor': 'Læge',
  'hcp.documents': 'Understøttende dokumenter',
  'hcp.documents.researchAndEvidence': 'Forskning og evidens',
  'hcp.Download PDF': 'Download PDF',
  'hcp.download_pdf_report': 'PDF rapport',
  'hcp.downloaded': 'Downloadet',
  'hcp.downloadReportModalBtn': 'Download rapport',
  'hcp.Downloads': 'Downloads',
  'hcp.Draw': 'Tegne',
  'hcp.Duration in years': 'Symptomvarighed (år)',
  'hcp.edit history': 'Rediger historik',
  'hcp.Edit transcription': 'Rediger transskription',
  'hcp.Edit-answer': 'Rediger svar',
  'hcp.editAccount': 'Rediger konto',
  'hcp.editAccountSuccessMessage': 'Redigeringer gemt',
  'hcp.editor': 'redigerende person',
  'hcp.editPatient': 'Rediger patient',
  'hcp.editPatientDetails': 'Administration',
  'hcp.editPatientSuccessMessage': 'Redigeringer gemt',
  'hcp.ef': 'Eksekutiv funktion',
  'hcp.ef-description': 'EF refererer til din evne til funktioner af højere orden, såsom planlægning, organisering, evaluering osv.',
  'hcp.email': 'E-mail',
  'hcp.Email': 'E-mail',
  'hcp.emailInstructions': 'E-mail-instruktioner',
  'hcp.emailLoginCodeInstructions': 'Patientens login-kode',
  'hcp.emailLoginInstructions': 'E-mail-instruktioner og loginoplysninger',
  'hcp.emailNote': 'Bemærk: Sørg for, at e-mail-korrespondance med patienten er godkendt',
  'hcp.emailSettings': 'E-mail-indstillinger',
  'hcp.Emotional Instability': 'Følelsesmæssig ustabilitet',
  'hcp.Emotional variation': 'Følelsesmæssig variation',
  'hcp.en': 'engelsk',
  'hcp.endocrinologist': 'Endokrinolog',
  'hcp.Enter-code': 'Indtast kode',
  'hcp.EnterRelativeEmail': 'Indtast e-mail til pårørende for invitation',
  'hcp.EnterRelativePhoneNumber': 'Indtast telefonnummer til pårørende for at sende invitation',
  'hcp.error_confirm': 'Adgangskoderne er ikke ens',
  'hcp.error_password': 'Adgangskoden skal have mindst 8 tegn og også indeholde specielle symboler (såsom ! og ?)',
  'hcp.errors.loginForm.invalidPNRFormat': 'Forkert personnummer',
  'hcp.errors.loginForm.invalidPNRLength': 'Brug 12 numre i dit personnummer',
  'hcp.excelReport': 'Hent Excel-rapport',
  'hcp.Expect': 'Forvent',
  'hcp.familyFormNote1': 'For at bruge Geras Solutions-appen og gennemføre Symptomundersøgelsen skal du oprette en konto.',
  'hcp.familyFormNote2': ' Vi har brug for din godkendelse til at håndtere dine oplysninger nedenfor.',
  'hcp.familyMember': 'Familiemedlem',
  'hcp.familySurvey': 'Familieundersøgelse',
  'hcp.February': 'februar',
  'hcp.Female': 'Kvinde',
  'hcp.female': 'Kvinde',
  'hcp.Field must not be empty': 'Feltet er obligatorisk',
  'hcp.File was generated. Please click button below to download it': 'Filen blev genereret. Klik venligst på knappen nedenfor for at downloade den.',
  'hcp.Filter patients by name': 'Filtrer patienter efter navn',
  'hcp.filter-patients-form.gender.title': 'Filtrer patienter efter køn',
  'hcp.filter-patients-form.reset-filter': 'Nulstil filter til standard',
  'hcp.filter-patients-form.status.title': 'Filtrer patienter efter status',
  'hcp.filter.accounts.admins': 'Admin',
  'hcp.filter.accounts.all': 'Alle konti',
  'hcp.filter.accounts.doctors': 'Læge',
  'hcp.filter.accounts.nurses': 'Sygeplejerske',
  'hcp.filter.accounts.superAdmins': 'Super admin',
  'hcp.filter.all': 'Alle patienter',
  'hcp.filter.appointments': 'Har næste møde',
  'hcp.filter.cognitive': 'Gennemført test',
  'hcp.filter.month': 'Måned',
  'hcp.filter.recommendations': 'Har anbefalinger',
  'hcp.filter.week': 'Uge',
  'hcp.filter.year': 'År',
  'hcp.filters': 'Filtre',
  'hcp.Fine motor skills': 'Finmotorik, færdigheder',
  'hcp.First name': 'Fornavn',
  'hcp.firstName': 'Fornavn',
  'hcp.focusNoteRisk': 'Dette er baseret på valideret ADHD Selvrapporterimgsskala (ASRS), hvor hvert spørgsmål har sine egne bedømmelseskriterier. Spørgsmål 1-3 giver 1 point hver, hvis patienten svarede "Sommetider" eller oftere. Spørgsmål 4-6 giver 1 point hver, hvis patienten svarede "Ofte" eller "Meget ofte". Med en samlet score på 4 eller mere viser patienten symptomer, der minder meget om ADHD hos voksne og berettiger yderligere undersøgelse',
  'hcp.Forget your password?': 'Glemt din adgangskode?',
  'hcp.Forgot Password': 'Glemt adgangskode',
  'hcp.Friday': 'Fredag',
  'hcp.friend': 'Ven',
  'hcp.Frontal': 'Frontal',
  'hcp.Frontal temporal dementia, behavioral variant - FTDbv': 'Frontal temporal demens, adfærdsvariant - FTDbv',
  'hcp.Fysisk hälsa': 'Somatisk status',
  'hcp.gender': ' Køn',
  'hcp.generalInformation': 'Generel information',
  'hcp.Generating PDF file process starting...': 'Genererering af PDF-fil proces starter...',
  'hcp.geras_forget_pass': 'Skift din adgangskode her',
  'hcp.Go to video call': 'Gå til videoopkald',
  'hcp.greetingComponent.text': 'Søg, opret eller følg en patientteststatus',
  'hcp.greetingComponent.title': 'Hej',
  'hcp.Hallucinations': 'Hallucinationer',
  'hcp.Hand on screen': 'Hånd på skærmen',
  'hcp.Have you obtained verbal consent from the patient to create an account on their behalf?': 'Har du fået mundtligt samtykke fra patienten til at oprette en konto på deres vegne?',
  'hcp.HCP_version': 'Portal',
  'hcp.headerCreatePatient': 'Opret ny patient',
  'hcp.headerCreatePatientSteps.step1': 'Patientoplysninger',
  'hcp.headerCreatePatientSteps.step2': 'Vælg opgaver til patienten',
  'hcp.headerCreatePatientSteps.step3': 'Skræddersy sygehistorie spørgeskema',
  'hcp.heavy-alcohol': 'stærk alkohol',
  'hcp.height': 'Højde',
  'hcp.hide preliminary results': 'Skjul foreløbige resultater',
  'hcp.high': 'Høj',
  'hcp.home': 'Hjem',
  'hcp.Humor': 'Humor',
  'hcp.idk': 'Jeg ved det ikke',
  'hcp.ieWarning': 'Denne funktion virker ikke på din nuværende browser. Brug venligst en ny version af Edge, Firefox, Chrome eller Safari for at bruge denne funktion.',
  'hcp.If': 'Hvis',
  'hcp.image': 'billede',
  'hcp.Incorrect': 'Ukorrekt',
  'hcp.Indicative reference library': 'Vejledende referencebibliotek',
  'hcp.information_gathering': 'Informationsindsamling',
  'hcp.initialQuestionnaire': 'Indledende spørgeskema',
  'hcp.Initiative': 'Initiativ',
  'hcp.Insight': 'Indsigt',
  'hcp.institution': 'Institution',
  'hcp.institutionSettings': 'Fælles institution',
  'hcp.iOS_version': 'iOS app',
  'hcp.January': 'januar',
  'hcp.journalNotes': 'Journalnotater',
  'hcp.July': 'juli',
  'hcp.June': 'juni',
  'hcp.l': 'Sprog',
  'hcp.l-description': 'Dette refererer til din evne til at forstå og udtrykke mundtligt og skriftligt sprog og finde ord og navne efter kategori eller lyd.',
  'hcp.landingPage.tile.cta': 'Opret patient og send test',
  'hcp.landingPage.tile.heading.createPatient': 'Opret ny patient',
  'hcp.landingPage.tile.heading.searchPatient': 'Søg efter patient',
  'hcp.landingPage.tile.searchInputPlaceholder': 'Skriv patientens navn, personnummer eller patient-id',
  'hcp.Language understanding': 'Sprogforståelse',
  'hcp.Language variation': 'Sprogvariation',
  'hcp.Last name': 'Efternavn',
  'hcp.Last Update': 'Seneste ændring',
  'hcp.lastName': 'Efternavn',
  'hcp.latest score': 'Seneste testresultat',
  'hcp.latest-patients': 'Seneste patienter',
  'hcp.Leave call': 'Forlad opkaldet',
  'hcp.Lewybodydemens - DLB': 'Lewybodydemens - DLB',
  'hcp.light-alcohol': 'svag alkohol',
  'hcp.Logging in...': 'Logger ind...',
  'hcp.Logic': 'Logik',
  'hcp.Login': 'Log ind',
  'hcp.Login with': 'Login med',
  'hcp.loginCode.expires': 'Udløber',
  'hcp.loginCode.prolong': 'Forny login-kode',
  'hcp.loginCode.refreshSuccessMessage': 'Loginkoden blev opdateret',
  'hcp.loginCode.title': 'Login kode',
  'hcp.loginForm.errors.pnrIsNotValid': 'Brug 12 numre i dit personnummer',
  'hcp.loginForm.errors.pwdIsInvalid': 'Forkert adgangskode',
  'hcp.logout': 'Log ud',
  'hcp.low': 'Lav',
  'hcp.Male': 'Mand',
  'hcp.male': 'Mand',
  'hcp.manager': 'Manager',
  'hcp.March': 'marts',
  'hcp.Mark as correct': 'Marker som korrekt',
  'hcp.Mark as incorrect': 'Marker som forkert',
  'hcp.mark-as-read': 'Marker som læst',
  'hcp.max': 'Maks',
  'hcp.max_if_all_answered': 'maks. hvis alle svarede',
  'hcp.maxScoreExceeded': 'Maksimal score er 59',
  'hcp.May': 'Maj',
  'hcp.MCI.abbreviationDecoding': 'Mild kognitiv svækkelse',
  'hcp.Medicin': 'Medicin',
  'hcp.medicinNoteRisk': 'Se Socialstyrelsens liste over<a href="https://www.socialstyrelsen.se/globalassets/sharepoint-dokument/dokument-webb/ovrigt/aldre-olampliga-lakemedel-for-aldre-lista.pdf" target="_blank"> Medicin, der bør undgås hos ældre</a> og den<a href="https://www.socialstyrelsen.se/globalassets/sharepoint-dokument/artikelkatalog/ovrigt/2018-12-37.pdf" target="_blank"> Diagnostisk tjekliste for nødvendigt stofbrug hos ældre</a> .<br /><br /> Lægemidler med antikolinerge virkninger: Akineton, Alimazin, Amitriptylin, Anafrani, l Atarax, Atropin, Buscopan, Calma, Clozapin, Detrusitol, Ditropan, Durbis, Egazil, Emselex, Glykopyrroniumbromid, Hydroxizin, Hydroxyzine, Kentera, Keminpratogan, Lepongian, Klomipratogan, Klomipratogan, Kentera, Levomepromazin, Ludiomil, Metylskopolamin, Morfin-Skopolamin, Norflex, Norgesic, Nozinan, Oxikodon-hyoscin, Oxybutynin, Pargitan, Postafen, Proklorperazin, Prometazin, Robinul, Saroten, Scopoderm, Sensaval, Sialanar, Spasmofen, Tolvegteral, Tolin, Tolin, Tolveger TOVIAZ, Truxal, Vesicare og Vesikur',
  'hcp.medium-heavy-alcohol': 'mellemstærk alkohol',
  'hcp.Meeting with': 'Møde med',
  'hcp.meetings': 'Møder',
  'hcp.Meetings': 'Møder',
  'hcp.meetings today': 'Møder i dag',
  'hcp.meetings-page.title': 'Mine møder',
  'hcp.Memory': 'Hukommelse',
  'hcp.Mental hälsa (PHQ-9)': 'Mental sundhed/depression (PHQ-9)',
  'hcp.mentalNoteRisk': 'Spørgsmål 9: &quot;Tanker om, at du ville være bedre stillet død eller at skade dig selv på en eller anden måde?&quot; - Er blevet besvaret positivt, vurder selvmordsrisiko.',
  'hcp.Mic': 'Mic',
  'hcp.mildRisk': 'Mild',
  'hcp.Mimic': 'Mimik',
  'hcp.min': 'min',
  'hcp.minimalRisk': 'Minimal eller ingen',
  'hcp.minutes': 'minutter',
  'hcp.modal.bankId.description': 'For at logge ind skal du starte Mobil BankID-applikationen på din enhed',
  'hcp.modal.bankId.title': 'Åbn Mobil BankID',
  'hcp.modal.confirmation.refreshLoginCode': 'Vil du virkelig udføre denne handling?',
  'hcp.moderateRisk': 'Moderat',
  'hcp.moderateSeverRisk': 'Moderat svær',
  'hcp.Monday': 'Mandag',
  'hcp.Month': 'Måned',
  'hcp.Muscle tone': 'Muskeltonus',
  'hcp.name': 'Navn',
  'hcp.Naming ability': 'Navngivningsevne',
  'hcp.neuropsychologist': 'Neuropsykolog',
  'hcp.New password': 'Ny adgangskode',
  'hcp.new_physiotherapist': 'Fysioterapeut',
  'hcp.newAccount': 'Ny konto',
  'hcp.newPatient': 'Ny patient',
  'hcp.next meeting': 'næste møde',
  'hcp.Next meeting in': 'Næste møde om',
  'hcp.Next meeting with': 'Næste møde med',
  'hcp.No': 'Ingen',
  'hcp.No answer': 'Intet svar',
  'hcp.No meeting found': 'Ingen møder fundet',
  'hcp.No more meetings for today': 'Ikke flere møder i dag',
  'hcp.no results': 'Ingen resultater',
  'hcp.No upcoming meetings': 'Ingen kommende møder',
  'hcp.no-avail-set-for-this-time': 'Ingen tilgængelighed angivet for dette tidspunkt',
  'hcp.no-patients': 'Ingen patienter fundet',
  'hcp.no-referrals-currently': 'Ingen henvisninger i øjeblikket',
  'hcp.Normal': 'Normal',
  'hcp.Not completed': 'Ikke afsluttet',
  'hcp.Not Requested': 'Ikke anmodet',
  'hcp.not_relevant': 'Ikke relevant',
  'hcp.note': 'Note',
  'hcp.note-form.text': 'Noter vil kun blive vist til fagfolk, ikke patienten',
  'hcp.note_type_1': 'Det maksimale antal point for denne øvelse er 3 point. Alle forsøg vil blive bedømt',
  'hcp.note_type_10': 'Det maksimale antal point for denne øvelse er 2 point. Alle forsøg vil blive bedømt',
  'hcp.note_type_11': 'Det maksimale antal point for denne øvelse er 5 point. Alle forsøg vil blive bedømt',
  'hcp.note_type_12': 'Det maksimale antal point for denne øvelse er 2 point. Alle forsøg vil blive bedømt',
  'hcp.note_type_13': 'Det maksimale antal point for denne øvelse er 6 point. Kun det andet forsøg vil blive bedømt. Det første forsøg er en prøveprøve.',
  'hcp.note_type_14': 'Det maksimale antal point for denne øvelse er 5 point. Alle forsøg vil blive bedømt',
  'hcp.note_type_15': 'Det maksimale antal point for denne øvelse er 5 point. Alle forsøg vil blive bedømt',
  'hcp.note_type_16': 'Det maksimale antal point for denne øvelse er 5 point. Alle forsøg vil blive bedømt',
  'hcp.note_type_2': 'Det maksimale antal point for denne øvelse er 1 point. Kun den bedste blandt alle forsøg vil blive bedømt',
  'hcp.note_type_3': 'Det maksimale antal point for denne øvelse er 5 point. Alle forsøg vil blive bedømt',
  'hcp.note_type_4': 'Det maksimale antal point for denne øvelse er 1 point. Alle svar skal være rigtige for at opnå 1 point.',
  'hcp.note_type_5': 'Det maksimale antal point for denne øvelse er 5 point. Alle svar skal være rigtige for at opnå 1 point. Kun første forsøg vil blive bedømt.',
  'hcp.note_type_6': 'Det maksimale antal point for denne øvelse er 4 point. Alle forsøg vil blive bedømt',
  'hcp.note_type_7': 'Det maksimale antal point for denne øvelse er 4 point. Alle forsøg vil blive bedømt',
  'hcp.note_type_8': 'Det maksimale antal point for denne øvelse er 5 point. Alle forsøg vil blive bedømt',
  'hcp.note_type_9': 'Det maksimale antal point for denne øvelse er 5 point. Kun første forsøg vil blive bedømt.',
  'hcp.NotEmptyAndValid': 'Feltet må ikke være tomt og skal være gyldigt',
  'hcp.notes': 'Noter',
  'hcp.November': 'november',
  'hcp.nurse': 'Sygeplejerske',
  'hcp.o': 'Orientering',
  'hcp.o-description': 'Orientering refererer til din evne til korrekt at lokalisere dig selv i rum og tid',
  'hcp.Obesity Class I': 'Fedmeklasse I',
  'hcp.Obesity Class II': 'Fedmeklasse II',
  'hcp.Obesity Class III': 'Fedmeklasse III',
  'hcp.October': 'oktober',
  'hcp.of': 'af',
  'hcp.Operating speed': 'Bevægelseshastighed',
  'hcp.Opinion': 'Mening',
  'hcp.other': 'Andet',
  'hcp.other-relationship': 'Anden relation',
  'hcp.Overweight': 'Overvægtig',
  'hcp.p-1': 'Ja, udtalt / ofte = 2 pt',
  'hcp.p-2': 'Ja, noget / nogle gange = 1 point',
  'hcp.p-3': 'Nej = 0 point',
  'hcp.p-4': 'Ved ikke / kan ikke bedømme = Ikke relevant',
  'hcp.parent': 'Forælder',
  'hcp.Parietal / Temporal': 'Parietal / Temporal',
  'hcp.Parkinson\'s disease with dementia - PDD': 'Parkinsons sygdom med demens - PDD',
  'hcp.partner': 'Partner',
  'hcp.Password': 'Adgangskode',
  'hcp.passwordNote': 'Din adgangskode skal have 8 tegn og indeholde mindst 1 specialtegn',
  'hcp.past available times': 'Tidligere ledige tider',
  'hcp.past meetings': 'Tidligere møder',
  'hcp.Patient left test ahead of time': 'Patienten forlod testen før tid',
  'hcp.patient-appointments': 'Patientaftaler',
  'hcp.patient.note.edit': 'Rediger note',
  'hcp.patient.questionnaire-result.completed': 'Færdiggjort',
  'hcp.patient.questionnaire-result.not-completed': 'Ikke afsluttet',
  'hcp.patient.questionnaire-result.title': 'Opgavehistorik for patient før diagnose',
  'hcp.patientAccount': 'Patientkonto',
  'hcp.patientAccount.newPatient.patientTasks.cognitiveTest': 'Kognitiv test (GSCT)',
  'hcp.patientAccount.newPatient.patientTasks.deselectAll': 'Fravælg alle',
  'hcp.patientAccount.newPatient.patientTasks.medicalHistory': 'Sygehistorie',
  'hcp.patientAccount.newPatient.patientTasks.selectAll': 'Vælg alle',
  'hcp.patientAccount.newPatient.patientTasks.symptomSurvey': 'Symptomundersøgelse',
  'hcp.patientEmails': 'Patient e-mails',
  'hcp.Patientens koncentration - ADHD/ADD (ASRS)': 'Patientens evne til at fokusere - ADHD/ADD (ASRS)',
  'hcp.Patientens upplevda symtom': 'Opfattede kognitive symptomer',
  'hcp.patientForm.errors.emailIsNotValid': 'E-mail er ikke gyldig',
  'hcp.patientForm.errors.pnrIsNotValid': 'Personnummer skal være 12 tal',
  'hcp.patientInfo.patientStatus.assessmentReviewed': 'Vurdering gennemgået',
  'hcp.patientInfo.patientStatus.complementaryInfo': 'Supplerende info',
  'hcp.patientInfo.patientStatus.informationGathering': 'Informationsindsamling',
  'hcp.patientInfo.patientStatus.prelAssessment': 'Præl. vurdering',
  'hcp.patientInfo.patientStatus.reportGenerated': 'Rapport genereret',
  'hcp.patientInfo.patientStatus.resultsNotReviewed': 'Resultater ikke gennemgået',
  'hcp.patientInfo.patientStatus.reviewedResults': 'Gennemgåede resultater',
  'hcp.patientPhoneNumber': 'Patientens telefonnummer',
  'hcp.patientPhoneNumber.lastSentTextMessage': 'Senest sendt besked',
  'hcp.patientPhoneNumber.noMobileNumber': 'Intet mobilnummer tilgængeligt',
  'hcp.patientPhoneNumber.sendLoginCode': 'Login kode og link til app',
  'hcp.patientPhoneNumber.successMessage': 'SMS\'en til patienten er nu sendt',
  'hcp.patientPhoneNumber.title': 'SMS-beskeder til patienten',
  'hcp.patients': 'Patienter',
  'hcp.patientsProfile': 'Patientens profil',
  'hcp.patientTasks': 'Patientopgaver',
  'hcp.pdf.age': 'Alder',
  'hcp.pdf.ageLowercase': 'Alder',
  'hcp.pdf.anamnesis': 'Anamnese',
  'hcp.pdf.cognitive': 'Kognitiv test',
  'hcp.pdf.cognitive.copyright': '*Digital Screening for Cognitive Impairment – A Proof ofConcept Study V. Bloniecki1,2, G. Hagman1,3, M. Ryden3 ,M. Kivipelto1,3,4,5,6, The Journal of Prevention of Alzheimer’s Disease – JPAD, in press Jan 2021,CorrespondingAuthor: Victor Bloniecki, Karolinska Institute, Karolinska University Hospital, Eugeniavägen',
  'hcp.pdf.cognitive.header': 'Lægeerklæring - Digital undersøgelse af kognitive funktioner',
  'hcp.pdf.cognitive.instruction-1': '<b>SCI</b> = Subjektiv kognitiv svækkelse, testpersonen oplever forringelse af hukommelse og andre kognitive funktioner, men test indikerer ikke nedsat kognitiv evne,<b> MCI</b> = Mild kognitiv svækkelse. Det betyder, at der kan ses en vis svækkelse af hukommelsesfunktionerne, men det er ikke så udtalt, at det i øjeblikket taler for demens/kognitiv lidelse.<b> Mistænkt kognitiv lidelse</b> = testresultater i overensstemmelse med kognitiv svækkelse på grund af demens, men andre mulige behandlingsbare årsager skal udelukkes',
  'hcp.pdf.cognitive.instruction-1.title': 'Forkortelser',
  'hcp.pdf.cognitive.instruction-2': '<b>SCI:</b> &gt; 45.0 point, 17:00 – 19:00 min<br/><b> MCI:</b> 35.5 – 45.0 point, 19:00 – 24:00 min<br/><b> Mistænkt kognitiv lidelse:</b> &lt;35.5 point, 28:00 – 48:00 min',
  'hcp.pdf.cognitive.instruction-2.title': 'Limit values',
  'hcp.pdf.createdThrough': 'Oprettet gennem',
  'hcp.pdf.diagnos': 'Diagnose',
  'hcp.pdf.initialQuestionnaire': 'Indledende spørgsmål – Test hjernens sundhed',
  'hcp.pdf.main': 'Grundlag for undersøgelse',
  'hcp.pdf.name': 'Navn',
  'hcp.pdf.recommendations': 'Anbefalinger',
  'hcp.pdf.summary': 'Opsummeret resultat og vurdering',
  'hcp.pdf.symptomSurvey': 'Symptomundersøgelse',
  'hcp.pdf.symptomSurvey.allFreeTextAnswers': 'Alle fritekstsvar',
  'hcp.pdf.symptomSurvey.allMultipleChoice': 'Alle flervalgssvar',
  'hcp.pdf.symptomSurvey.symptomSurveyPage': 'Side med symptomundersøgelse',
  'hcp.pdfReport': 'Generer rapport',
  'hcp.personalNumber': 'Personnummer',
  'hcp.phone': 'Telefonnummer',
  'hcp.physiotherapist': 'Fysioterapeut',
  'hcp.Planning': 'Planlægning',
  'hcp.Planning memory 1': 'Planlægningshukommelse 1',
  'hcp.Planning memory 2': 'Planlægningshukommelse 2',
  'hcp.Play drawing process': 'Afspil tegneproces',
  'hcp.Please obtain verbal consent from the patient to create an account on their behalf.': 'Indhent venligst mundtligt samtykke fra patienten til at oprette en konto på deres vegne.',
  'hcp.Please wait': 'Vent venligst',
  'hcp.Please, click on the image below if you already have an app': 'Klik venligst på billedet nedenfor, hvis du allerede har en app',
  'hcp.Please, click on the image below to download app': 'Klik venligst på billedet nedenfor for at downloade appen',
  'hcp.pnumberFormat': 'ÅÅÅÅMMDD-XXXX',
  'hcp.Points': 'Points',
  'hcp.policy': 'Privatlivspolitik',
  'hcp.Posterior cortical atrophy - PCA': 'Posterior kortikal atrofi - PCA',
  'hcp.Practical ability': 'Praktisk evne',
  'hcp.previousMeetings': 'Tidligere møder',
  'hcp.Primary progressive non-fluid aphasia - PNFA': 'Primær progressiv ikke-flydende afasi - PNFA',
  'hcp.profile-view-doctor': 'Dr.',
  'hcp.profile-view-neuropsychologist': 'Dr.',
  'hcp.profile-view-new_physiotherapist': 'PT.',
  'hcp.profile-view-nurse': 'Ingen.',
  'hcp.profile-view-psychologist': 'Dr.',
  'hcp.profile-view-therapist': 'OT',
  'hcp.Proximity memory 1': 'Korttidshukommelse 1',
  'hcp.Proximity memory 2': 'Korttidshukommelse 2',
  'hcp.psychologist': 'Psykolog',
  'hcp.Question was skipped due to previous question answer': 'Spørgsmålet blev sprunget over på grund af tidligere svar på spørgsmålet ',
  'hcp.questionnaire': 'Spørgeskema',
  'hcp.Questionnaire': 'Spørgeskema',
  'hcp.Questions': 'spørgsmål',
  'hcp.quick_pick_button': 'Hurtigt valg',
  'hcp.radiologist': 'Radiolog',
  'hcp.Reaction time': 'Reaktionstid',
  'hcp.Recognition ability': 'Genkendelsesevne',
  'hcp.recommendation': 'Anbefaling',
  'hcp.Recording not found': 'Optagelsen blev ikke fundet',
  'hcp.Recording of answers': 'Registrering af svar',
  'hcp.referrals': 'Henvisninger',
  'hcp.Regional Dysfunktionsprofil Patient': 'Regional dysfunktionsprofil',
  'hcp.Relationship': 'Relation',
  'hcp.relationship': 'Vælg relation',
  'hcp.relativeDegrees': '["Partner","Forælder","Barn","Søskende","Anden relation","Ven"]',
  'hcp.Remote memory': 'Fjernhukommelse',
  'hcp.report-header': 'Foreløbige resultater',
  'hcp.report-header-cognitive': 'Kognitive funktioner',
  'hcp.report-high': 'Høj',
  'hcp.report-low': 'Lav',
  'hcp.report-tasks': 'Testøvelser',
  'hcp.reportExport': 'Rapportér eksport',
  'hcp.Requested': 'Anmodet',
  'hcp.requestFamilyPhoneLabel': 'Pårørendes mobilnummer',
  'hcp.requestFamilySurvey': 'Symptomundersøgelse',
  'hcp.Required': 'Påkrævet',
  'hcp.Reset counts': 'Slettede pile',
  'hcp.Reset password': 'Nulstil adgangskode',
  'hcp.Responsiveness': 'Reaktionsevne',
  'hcp.Restore user': 'Gendan bruger',
  'hcp.restoreAccount': 'Gendan konto',
  'hcp.restorePatient': 'Gendan patient',
  'hcp.restorePWDForm.errors.emailIsInvalid': 'Forkert e-mail',
  'hcp.Results': 'resultater',
  'hcp.Results for Review': 'Resultater til gennemgang',
  'hcp.riskIndicator': 'Risikoindikator',
  'hcp.riskIndicators': 'Risikoindikatorer',
  'hcp.riskInterval': 'Risikointerval',
  'hcp.riskLegend': 'Bemærk: Se klinisk undersøgelse under "Dokumenter" for mere info',
  'hcp.role': 'Rolle',
  'hcp.Room orientation': 'Rumorientering',
  'hcp.Saturday': 'lørdag',
  'hcp.save': 'Gem',
  'hcp.save-and-send': 'Gem og send',
  'hcp.SCI.abbreviationDecoding': 'Subjektiv kognitiv svækkelse',
  'hcp.search': 'Søg',
  'hcp.search.results': 'resultater',
  'hcp.searchPatient': 'Skriv for at søge patient...',
  'hcp.seconds': 'sekunder',
  'hcp.Select profile': 'vælg profil',
  'hcp.Select the recommended path': 'Vælg den anbefalede vej',
  'hcp.select-all-and-save': 'Vælg alle og gem',
  'hcp.Select-discount': 'Vælg rabat',
  'hcp.selectCommentType': 'Vælg type kommentar',
  'hcp.selectCountry': 'Vælg land',
  'hcp.selectGender': 'Vælg køn',
  'hcp.selectLanguage': 'Vælg sprog',
  'hcp.selectRole': 'Vælg rolle',
  'hcp.Semantic dementia - SD': 'Semantisk demens - SD',
  'hcp.send': 'Send',
  'hcp.Send': 'Send',
  'hcp.Send symptom survey to relative': 'Send symptomundersøgelse til pårørende',
  'hcp.Sending email...': 'Sender e-mail...',
  'hcp.sendSymptomSurveySuccessMessage': 'Symptomundersøgelse sendt',
  'hcp.sendToPatientModalBtn': 'Del med patienten',
  'hcp.sentToPatient': 'sendt til patienten',
  'hcp.September': 'september',
  'hcp.setNewPassword': 'Indstil ny adgangskode',
  'hcp.Severely underweight': 'Svært undervægtig',
  'hcp.severRisk': 'Alvorlig',
  'hcp.Shape sense': 'Formsans',
  'hcp.show preliminary results': 'Vis foreløbige resultater',
  'hcp.show_all_records': 'Vis alle poster',
  'hcp.showArchivedTests': 'Se arkiverede tests',
  'hcp.showNewTests': 'Vend tilbage til nye tests',
  'hcp.sibling': 'Søskende',
  'hcp.sidePanel.navigation.contact': 'Kontakt',
  'hcp.Sleep disturbance': 'Søvnforstyrrelser',
  'hcp.specialist': 'Specialist',
  'hcp.specialistComments': 'Specialistkommentarer',
  'hcp.specialistReviewing': 'Visningshistorik',
  'hcp.Speech clarity': 'Taletydelighed',
  'hcp.speech_therapist': 'Logopæd',
  'hcp.start': 'Start',
  'hcp.startPage.button.createPatient': 'Opret patient',
  'hcp.startPage.searchHint': 'Søg efter patient',
  'hcp.starts-in': 'starter om',
  'hcp.status': 'Status',
  'hcp.stm': 'Hukommelse',
  'hcp.stm-description': 'STM refererer til din evne til at holde en lille mængde information i tankerne i en kort periode',
  'hcp.Stress sensitivity': 'Stressfølsomhed',
  'hcp.stressNoteRisk': 'Enhver, der får mere end 18 point, kan risikere at lide af stress/træthedssyndrom. Det anbefales herefter, at patienten kontakter sit/sin sundhedscenter/bedriftssundhedspleje eller leder for at drøfte testresultaterne.',
  'hcp.Subcortical': 'Subkortikal',
  'hcp.summary_results_and_assessment': 'Sammenfattende resultater og vurdering',
  'hcp.Sunday': 'Søndag',
  'hcp.superAdmin': 'Super admin',
  'hcp.survey': 'Undersøgelse',
  'hcp.Suspected': 'Mistænkt',
  'hcp.sv': 'svensk',
  'hcp.symptom survey': 'Symptomundersøgelse',
  'hcp.Symptom Survey for relatives': 'Symptomundersøgelse for pårørende',
  'hcp.symptom_survey.riskLegend.hint': 'Opsummerede pointer er vist i forhold til de spørgsmål, som en pårørende har besvaret. Høje point indikerer flere områder med forværring af kognitiv status. &quot;Ved ikke/kan ikke dømme&quot;-svar er ikke inkluderet. Maksimal point, når alle spørgsmål er besvaret, vises i parentes.',
  'hcp.SymptomSurvey_version': 'Symptomundersøgelse',
  'hcp.systolic-pressure': 'Mit sædvanlige systoliske tryk',
  'hcp.termsAndConditions': 'Vilkår og betingelser',
  'hcp.Test duration': 'Testvarighed',
  'hcp.testSettings': 'Indstillinger for applikationstests',
  'hcp.testStatus.abortedOrError': 'Afbrudt',
  'hcp.testStatus.done': 'Tilgængelige resultater',
  'hcp.testStatus.initiated': 'Påbegyndt',
  'hcp.testStatus.notSent': 'Ikke aktiveret',
  'hcp.testStatus.sent': 'Aktiveret',
  'hcp.testStatus.viewed': 'Gennemgået',
  'hcp.testStatus.viewedWithError': 'Afbrudt/Gennemgået',
  'hcp.Text chat': 'SMS-chat',
  'hcp.text-chat': 'SMS-chat',
  'hcp.Text-message history': 'SMS-historik',
  'hcp.textMessageToPatient.BrainHealth': 'Hjernens sundhed',
  'hcp.textMessageToPatient.BrainHealth.messageExample': 'Via dette link kan du få adgang til værdifuld information om hjernens sundhed</br> https://www.minnesmottagningen.se/hall-hjarnan-frisk/hallhjarnanfrisk</br> Bemærk venligst! Du kan ikke svare på denne sms.',
  'hcp.textMessageToPatient.FeedBack': 'Anmod om feedback',
  'hcp.textMessageToPatient.FeedBack.messageExample': 'Det ville være værdifuldt for os, hvis du kunne besvare et par korte spørgsmål om din kontakt med os. Spørgsmålene tager maksimalt 2 minutter at besvare, og besvarelserne er fuldstændig anonyme.</br> Du besvarer spørgsmålene ved at klikke på dette link:</br> https://forms.gle/1vvzJU5Z6qfidV9J7</br> Venlig hilsen,</br> Minnesmottagningen.se</br> Bemærk: Du kan ikke besvare denne besked',
  'hcp.textMessageToPatient.loginCode.messageExample': 'Hent app&#39;en - minnesmottagningen.se/app</br> Log ind med kode: XXXXXX</br> Bemærk: Du kan ikke besvare denne besked',
  'hcp.textMessageToPatient.MissedAppointment': 'Gået glip af videomøde',
  'hcp.textMessageToPatient.MissedAppointment.messageExample': 'Du gik glip af dit planlagte videomøde i dag.</br> For at undgå yderligere gebyrer, bedes du kontakte os for at flytte din tid.</br> Du kan kontakte os på 010-750 07 26, mandag til fredag, fra 09:00 til 15:00 eller på info@minnesmottagningen.se.</br> Venlig hilsen,</br> Minnesmottagningen.se</br> Bemærk: Du kan ikke besvare denne besked',
  'hcp.textMessageToPatient.NoFreeCard': 'Intet gratis kort - betalingslink',
  'hcp.textMessageToPatient.NoFreeCard.messageExample': 'Manglende betaling af patientgebyr.</br> Ved bestilling af tid blev oplysninger om frikort angivet forkert.</br> Derfor beder vi dig betale et patientgebyr på 300 kroner via følgende link:</br> https://buy.stripe.com/4gw17fa4r8eG8ScfZ3</br> Venlig hilsen,</br> Minnesmottagningen.se</br> Bemærk: Du kan ikke besvare denne besked',
  'hcp.textMessageToPatient.RefundForPatient': 'Refusion til patient - grundet frikort',
  'hcp.textMessageToPatient.RefundForPatient.messageExample': 'Refusion af patientgebyr.</br> Vi refunderer patientgebyret på 300 kroner, fordi vores check afslørede, at du har et gyldigt frikort.</br> Bemærk venligst, at det kan tage et par dage, før tilbagebetalingen vises på den betalingsmetode, du valgte ved bestilling af din tid.</br> Venlig hilsen,</br> Minnesmottagningen.se</br> Bemærk: Du kan ikke besvare denne besked',
  'hcp.textMessageToPatient.ReminderForMeeting': 'Mødepåmindelse',
  'hcp.textMessageToPatient.ReminderForMeeting.messageExample': 'Mødepåmindelse.</br> Dit videomøde begynder snart.</br> Log ind her for at starte videomødet:</br> Minnesmottagningen.se/patientportal</br> Venlig hilsen,</br> Minnesmottagningen.se</br> Bemærk: Du kan ikke besvare denne besked',
  'hcp.textMessageToPatient.ReviewedByDoctor': 'Analyseret af læge',
  'hcp.textMessageToPatient.ReviewedByDoctor.messageExample': 'Dine svar og testresultater er blevet gennemgået af en læge.</br> Du vil modtage resultaterne, vurderingen og anbefalingerne under dit planlagte videomøde.</br> Venlig hilsen,</br> Minnesmottagningen.se</br> Bemærk: Du kan ikke besvare denne besked',
  'hcp.textMessageToPatient.TestReminder': 'Testpåmindelse',
  'hcp.textMessageToPatient.TestReminder.messageExample': 'Handling påkrævet.</br> Der er ufuldstændige opgaver, der skal udføres. Log ind på appen for at afslutte de resterende dele.</br> www.Minnesmottagningen.se/app</br> Venlig hilsen,</br> Minnesmottagningen.se</br> Bemærk: Du kan ikke besvare denne besked',
  'hcp.textMessageToPatient.ThankYou': 'Gennemført hukommelsesvurdering',
  'hcp.textMessageToPatient.ThankYou.messageExample': 'Information er nu tilgængelig på patientportalen via følgende link:</br> www.minnesmottagningen.se/patientportal</br> Bemærk: Du kan ikke besvare denne besked',
  'hcp.textMessageToPatient.TriedToReachOut': 'Forsøgte at få kontakt',
  'hcp.textMessageToPatient.TriedToReachOut.messageExample': 'Vi har forsøgt at nå dig.</br> Du kan kontakte os på 010-750 07 26, mandag til fredag, fra 09:00 til 15:00 eller på info@minnesmottagningen.se.</br> Venlig hilsen,</br> Minnesmottagningen.se</br> Bemærk: Du kan ikke besvare denne besked',
  'hcp.Thank you for choosing Geras Solutions!': 'Tak fordi du valgte Geras Solutions!',
  'hcp.The email to the patient has now been sent': 'Mailen til patienten er nu sendt',
  'hcp.therapist': 'Ergoterapeut',
  'hcp.Thought tempo': 'Tanketempo',
  'hcp.threeDots': '...',
  'hcp.Thursday': 'torsdag',
  'hcp.Time perception 1': 'Tidsopfattelse 1',
  'hcp.Time perception 2': 'Tidsopfattelse 2',
  'hcp.Time to take test': 'Tid til at tage testen',
  'hcp.time-used': 'Tid brugt',
  'hcp.title': 'Patientoplysninger',
  'hcp.To confirm invitation press button below': 'Tryk på knappen nedenfor for at bekræfte invitationen',
  'hcp.Today': 'i dag',
  'hcp.today': 'i dag',
  'hcp.Tomorrow': 'I morgen',
  'hcp.tomorrow': 'i morgen',
  'hcp.Total points': 'Points i alt',
  'hcp.Total time': 'Tid i alt',
  'hcp.totalPatients.counter.plural': 'patienter',
  'hcp.totalPatients.counter.singular': 'patient',
  'hcp.transcription from': 'Original transskription',
  'hcp.Transcription of answers': 'Transskription af svar',
  'hcp.transcription to': 'Ny transskription',
  'hcp.try': 'Forsøg',
  'hcp.Tuesday': 'tirsdag',
  'hcp.Type here...': 'Skriv her...',
  'hcp.Type to search patient': 'Skriv for at søge patient',
  'hcp.Typical authentic patient cases, not scientifically validated': 'Typiske autentiske patienttilfælde, ikke videnskabeligt validerede',
  'hcp.Underweight': 'Undervægtig',
  'hcp.upcomingMeetings': 'Kommende møder',
  'hcp.username': 'Brugernavn',
  'hcp.va': 'Visuospatiale evner',
  'hcp.va-description': 'VSA henviser til din evne til at identificere objekter i et rum, og hvordan de er rumligt relaterede.',
  'hcp.Vascular dementia - VaD': 'Vaskulær demens - VaD',
  'hcp.Very severely underweight': 'Meget svært undervægtig',
  'hcp.video-appointment': 'Videoaftale',
  'hcp.video-call': 'Videoopkald',
  'hcp.voucherCode': 'Generer kuponkode',
  'hcp.voucherCodeStats': 'Kuponkodestatistik',
  'hcp.Waiting for user to join...': 'Venter på, at brugeren deltager...',
  'hcp.Walking pattern': 'Gangmønster',
  'hcp.We have e-mailed your password reset link': 'Hvis din konto findes i vores system, vil du modtage en e-mail med nulstilling af adgangskode.',
  'hcp.We strongly recommend do not reload the page.': 'Vi anbefaler på det kraftigste ikke at genindlæse siden.',
  'hcp.Wednesday': 'onsdag',
  'hcp.Week': 'Uge',
  'hcp.weight': 'Vægt',
  'hcp.welcome': 'Velkomst',
  'hcp.welcome_geras_1': 'Velkommen til Geras Solutions',
  'hcp.welcome_geras_2': 'Webportal for sundhedspersonale',
  'hcp.Why did you download Geras app?': 'Hvorfor downloadede du Geras-appen?',
  'hcp.whyGeras': 'Hvorfor downloadede du Geras-appen?',
  'hcp.will start in': 'starter om',
  'hcp.with': 'med',
  'hcp.wm': 'Opmærksomhed, koncentration, arbejdshukommelse',
  'hcp.wm-description': 'Disse er relateret til din korttidshukommelse og henviser til din evne til at manipulere og organisere information, der er gemt i din korttidshukommelse.',
  'hcp.words:': 'Ord: ',
  'hcp.Write patient’s name, personal number, or email': 'Skriv patientens navn, personnummer eller patient-id',
  'hcp.Write your message here': 'Skriv din besked her',
  'hcp.Write your opinion, which will be sent to the patient': 'Skriv din mening, som sendes til patienten',
  'hcp.writeComment': 'Skriv en kommentar',
  'hcp.Writing ability': 'Skriveevne',
  'hcp.years': 'år',
  'hcp.yearsOld': 'år gammel',
  'hcp.Yes': 'Ja',
  'hcp.You can restore this user later': 'Du kan genoprette patienten senere',
  'hcp.You successfully changed your password': 'Du har ændret din adgangskode',
  'hcp.zh': '中文',
  'lastName': 'Dit efternavn',
  'loader.retrievingRecords': 'Henter poster og kontrollerer data...',
  'notFoundPage.header': 'Det ser ud til, at den side, du anmodede om, ikke eksisterer',
  'notFoundPage.helpMessage': 'Kontroller, at linket er korrekt',
  'patient.search.personalNumberHeading': 'Søg på patientens personnummer',
  'patientEmail': 'Patientens e-mail (kun hvis personnummer mangler)',
  'patientSearch.hint.emailFormat': 'Angiv e-mail med korrekt format',
  'patientSearch.hint.persNumberFormat': '12 tegn',
  'patientSearch.provideEmailOrPnr': 'Angiv venligst personens personnummer eller e-mail',
  'personalNumber': 'Patientens personnummer',
  'phone': 'Dit telefonnummer',
  'relative.relativeForm.heading': 'Fortæl os om dig selv',
  'relative.relativeForm.headingIfAuth': 'Symptomundersøgelse vedr.',
  'relative.relativeForm.headingIfAuthDescriptionAdditional': 'Den udredte kan af forskellige årsager opleve vanskeligheder med at give en korrekt og fuldstændig beskrivelse af tilstanden og symptomerne. Derfor har de pårørendes perspektiv stor værdi i vurderingen.',
  'relative.relativeForm.headingIfAuthDescriptionMain1': 'Du er blevet bedt om at udfylde en Symptomundersøgelse vedr.',
  'relative.relativeForm.headingIfAuthDescriptionMain2': 'som en del af en kognitiv vurdering udført gennem The Memory Clinic app.',
  'relative.relativeForm.headingSearch': 'Angiv venligst data for at starte undersøgelsen',
  'relative.relativeForm.saveButton': 'Fortsæt',
  'startPage.button.getStarted': 'Kom i gang',
  'startPage.button.goToSearch': 'Gå til søg',
  'startPage.heading.linkNotValid': 'Det ser ud til, at det link, du angav, ikke er gyldigt',
  'startPage.heading.noQuestionsFromServer': 'Der opstod en fejl under hentning af data til undersøgelse',
  'startPage.heading.noQuestionsTip1': 'Prøv at opdatere siden',
  'startPage.heading.noQuestionsTip2': 'Tjek og rediger det angivne personnummer, og prøv igen',
  'startPage.heading.searchOption': 'Du kan også udfylde undersøgelsen med patientens personnummer',
  'startPage.heading.welcome': 'Velkommen til Symptoundersøgelsessiden',
  'startPage.welcomeMessage1': 'Svar venligst på spørgsmål vedr.',
  'successPage.heading': 'Undersøgelsesresultater er blevet gemt uden problemer!',
  'successPage.thanksMessage': 'Tak for dine svar! Oplysningerne er af stor værdi for undersøgelsen.',
  'survey.buttons.back': 'Tilbage',
  'survey.buttons.next': 'Næste',
  'survey.buttons.save': 'Send resultater',
  'sv': 'Svensk',
  'symptom-survey.answer.child': 'Barn',
  'symptom-survey.answer.difficult-to-discuss-with-patienten': 'Svært at diskutere med patienten',
  'symptom-survey.answer.do-not-know-not-relevant': 'Ved ikke/ikke relevant',
  'symptom-survey.answer.dont-know-cant-be-assessed': 'Ved ikke/kan ikke vurderes',
  'symptom-survey.answer.friend': 'Ven',
  'symptom-survey.answer.gradually-transition-in-a-faster-pace': 'Gradvis - i et hurtigere tempo',
  'symptom-survey.answer.gradually-transition-in-an-insidious': 'Gradvis - i et snigende og langsommere tempo',
  'symptom-survey.answer.half-one-year-ago': '1⁄2 - 1 år siden',
  'symptom-survey.answer.less-than-six-months-ago': 'For mindre end 6 måneder siden',
  'symptom-survey.answer.more-than-five-years-ago': 'for mere end 5 år siden',
  'symptom-survey.answer.no': 'Nej',
  'symptom-survey.answer.often': 'Ofte',
  'symptom-survey.answer.one-two-years-ago': '1-2 år siden',
  'symptom-survey.answer.other-relationship': 'Andet forhold',
  'symptom-survey.answer.parent': 'Forælder',
  'symptom-survey.answer.partner': 'Partner',
  'symptom-survey.answer.periodically-returning-symptoms-with-normal-functioning-periods': 'Periodisk tilbagevendende symptomer, med normale funktionsperioder',
  'symptom-survey.answer.sibling': 'Søskend',
  'symptom-survey.answer.sometimes': 'Sommetider',
  'symptom-survey.answer.somewhat': 'Noget',
  'symptom-survey.answer.sudden-change-thereafter-gradual-deterioration': 'Pludselig ændring, - derefter gradvis forværring',
  'symptom-survey.answer.sudden-change-thereafter-improved': 'Pludselig ændring, - derefter forbedret',
  'symptom-survey.answer.sudden-change-thereafter-insidious-deterioration': 'Pludselig ændring, - derefter snigende forværring',
  'symptom-survey.answer.sudden-change-thereafter-unchanged': 'Pludselig ændring, - derefter uændret',
  'symptom-survey.answer.text': 'Skriv her...',
  'symptom-survey.answer.three-five-years-ago': '3-5 år siden',
  'symptom-survey.answer.two-three-years-ago': '2-3 år siden',
  'symptom-survey.answer.yes': 'Ja',
  'symptom-survey.answer.yes-pronounced-often': 'Ja, udtalt / ofte',
  'symptom-survey.answer.yes-somewhat-sometimes': 'Ja, noget / sommetider',
  'symptom-survey.question.assessing-distance': '<strong>vurdere afstand og komme i den korrekte kropsposition,</strong> fx ved at sidde på en stol eller ligge i en seng med kroppen korrekt placeret',
  'symptom-survey.question.become-distrustful': 'er blevet<strong> mistroisk</strong> og kan for eksempel komme med falske anklager, vise uberettiget jalousi eller opleve en følelse af at blive forfulgt. Han/hun kan også give udtryk for<strong> vrangforestillinger</strong> på andre måder',
  'symptom-survey.question.changed-behavior': '<strong>ændret adfærd.</strong> Dette kan føre til pinlige eller problematiske situationer i det sociale liv eller i samlivet',
  'symptom-survey.question.changed-eating-habits': '<strong>ændrede spisevaner.</strong> Sult, mæthed eller valg af mad er anderledes end før, og bordskik kan være forringet',
  'symptom-survey.question.changed-facial-expressions': '<strong>har ændret ansigtsudtryk.</strong> Ansigtsudtryk er mindre spontane og varierede, hvilket gør det sværere at aflæse følelsesmæssig tilstand og reaktioner',
  'symptom-survey.question.changed-sense-of-humor': '<strong>ændret sans for humor.</strong> Har svært ved at forstå vittigheder, misfortolker, tager tingene mere personligt eller joker på en anderledes måde. Kan virke underholdt og opstemt uden nogen klar grund',
  'symptom-survey.question.changed-sleeping-habits': '<strong>har ændret sovevaner,</strong> for eksempel at sove mere eller sove på et andet tidspunkt af dagen i forhold til før',
  'symptom-survey.question.changed-walking': '<strong>har ændret måden at gå på</strong> og skridtene er kortere, trippende eller mere klodsede',
  'symptom-survey.question.clumsier-or-trembling': '<strong>er mere klodset</strong> eller er ryster mere<strong> skælvende</strong>',
  'symptom-survey.question.counting': '<strong>tæller</strong> og forstå hans/hendes økonomi, som for eksempel at veksle penge, betale regninger, bruge checks og betalinlgskort, tjekke kontoudtog og forstå værdien af penge',
  'symptom-survey.question.difficulty-remembering-what-was-said': '<strong>havde svært ved at huske</strong> hvad der for nylig blev sagt',
  'symptom-survey.question.evident-specific-event-symptoms': '<strong>skrive</strong> , for eksempel at skrive breve, beskeder eller udfylde skemaer',
  'symptom-survey.question.experienced-impaired-awareness': '<strong>oplevet nedsat bevidsthed,</strong> for eksempel de problemer, som hans/hendes symptomer fører til, eller hvordan de påvirker andre mennesker',
  'symptom-survey.question.feeling-more-down': 'føler<strong> mere nede,</strong> dyster, grublende med tab af "gnist" eller livlighed',
  'symptom-survey.question.finding-direction': '<strong>at finde den rigtige vej</strong> når man kører bil, går, handler i stormagasiner eller er på toiletbesøg hjemme hos bekendte',
  'symptom-survey.question.finding-words': '<strong>finde de rigtige ord.</strong> Forsøger ofte at finde en anden måde at forklare, hvad han/hun mener eller taler usammenhængende',
  'symptom-survey.question.first-noticed-symptoms': '<strong>Hvilke af de nævnte symptomer</strong> i undersøgelsen ville du sige <strong> først blev bemærket?</strong>',
  'symptom-survey.question.first-symptoms-noticed': '<strong>Hvornår blev de første af de symptomer, der er nævnt i undersøgelsen, først bemærket?</strong>',
  'symptom-survey.question.following-instructions-and-manuals': '<strong>følge instruktioner og manualer</strong> med gode resultater, såsom ved samling af møbler eller installation af hårde hvidevarer/værktøj',
  'symptom-survey.question.hard-to-manage-symptoms': '<strong>Har patienten fortalt dig, om nogle symptomer har været særligt svære at håndtere?</strong>',
  'symptom-survey.question.has-been-confused': '<strong>er blevet forvirret</strong> til tider eller periodisk. For eksempel ved hurtigt at have uklare tanker, opleve orienteringsbesvær i tid og rum, ved at vise en ændring i adfærd og føle sig urolig',
  'symptom-survey.question.has-hallucinations': '<strong>har hallucinationer.</strong> For eksempel kan han/hun se, høre eller føle ting, der ikke eksisterer i virkeligheden, men som han/hun tydeligt følte som en oplevelse',
  'symptom-survey.question.how-related-with-patienten': '<strong>Hvordan er du i familie med eller kender patienten?</strong>',
  'symptom-survey.question.impaired-balance': '<strong>har svækket balance</strong> og falder lettere omkuld',
  'symptom-survey.question.impaired-judgement': '<strong>nedsat dømmekraft.</strong> Kan gøre ting, der medfører risici eller skal ordnes og er<strong> mindre forsigtig,</strong> for eksempel ved pasning af hjemmet eller personlig hygiejne',
  'symptom-survey.question.increased-difficulty-getting-involved': '<strong>havde i stigende grad svært ved at blive involveret og vise medfølelse</strong> i det, der tidligere har været vigtigt, både i forhold til familien og samfundet som helhed. De følelsesmæssige reaktioner er svagere, uklare eller afvigende',
  'symptom-survey.question.increased-difficulty-planning': '<strong>havde i stiggende grad  problemer med at planlægge,</strong> fx med hensyn til at passe hjemmet, købe ind eller vælge passende tøj',
  'symptom-survey.question.increased-difficulty-remembering-what-day-it-is': 'havde i stigende grad svært ved at huske<strong> hvilken dag det er</strong>',
  'symptom-survey.question.increased-difficulty-remembering-what-has-happened': '<strong>havde i stigende grad svært ved at huske,</strong> hvad der er sket i fortiden og kan have svært ved at huske rækkefølgen, hvori forskellige mindeværdige begivenheder fandt sted',
  'symptom-survey.question.increased-difficulty-remembering-what-time-it-is': 'havde i stigende grad svært ved at huske,<strong> hvilken tid på dagen det er</strong>',
  'symptom-survey.question.increased-difficulty-remembering-what-to-do': '<strong>havde i stigende grad svært ved at huske,</strong> hvad man skal gøre, eller hvad der skal ske, såsom at lave en aftale eller huske en særlig lejlighed',
  'symptom-survey.question.increased-difficulty-remembering-what-was-done': '<strong>havde i stigende grad svært ved at huske,</strong> hvad der blev gjort for nylig',
  'symptom-survey.question.increased-difficulty-remembering-without-being-reminded': '<strong>havde i stigende grad svært ved at huske</strong> uden at blive mindet om det eller bruge huskesedler, kalender eller lignende',
  'symptom-survey.question.increased-difficulty-stopping': 'har i stigende grad svært ved at stoppe, når man bliver vred og kan være<strong> truende</strong> eller<strong> voldsom</strong>',
  'symptom-survey.question.increased-difficulty-taking-initiative': '<strong>havde i stigende grad svært ved at tage initiativ.</strong> Det er for eksempel sværere at påtage sig opgaver, der tidligere har været rutiner, eller er blevet overaktive på en måde, der skaber problemer eller rod',
  'symptom-survey.question.interpreting-maps': '<strong>fortolkning af kort, tegninger eller mønstre</strong>',
  'symptom-survey.question.light-hearted': 'er mere<strong> let berørt (let til tårer/"nærtagende" </strong> eller <strong> har pludselige humørsvingninger</strong> uden en klar årsag',
  'symptom-survey.question.missed-toilet': '<strong>har sværere ved at finde ud af, hvornår der er behov for toiletbesøg,</strong> hvilket kan resultere i at han/hun tisser i bukserne',
  'symptom-survey.question.moments-of-absence': 'har kort<strong> øjeblikke af fravær</strong> hvor han/hun ikke reagerer på tiltale eller "fastlåses" i en bevægelse eller aktivitet på en måde, der er svær at stoppe',
  'symptom-survey.question.more-sensitive-to-stress': 'er mere<strong> følsom over for stress</strong> og kan trække sig fra socialt samvær eller intense aktiviteter. Har et øget behov for at gøre én ting ad gangen',
  'symptom-survey.question.more-taciturn': 'blive mere<strong> fåmælt</strong> og<strong> talen flyder mindre</strong> . Bruger<strong> de samme ord eller sætninger</strong> igen og igen',
  'symptom-survey.question.more-worried': 'er<strong> mere bekymret, rastløs</strong> og kan evt.<strong> føle sig bange</strong> i visse situationer',
  'symptom-survey.question.most-noticeable-symptoms': '<strong>Hvilke af de nævnte symptomer</strong> i undersøgelsen ville du sige har været<strong> mest iøjnefaldende?</strong>',
  'symptom-survey.question.moves-slower': '<strong>bevæger sig langsommere</strong>',
  'symptom-survey.question.moves-stiffer': '<strong>bevæger sig på en stivere og mere ufleksibel måde</strong>',
  'symptom-survey.question.nuanced-opinions': '<strong>mindre nuancerede meninger,</strong> værdier og synspunkter. Har i stigende grad svært ved at opfatte argumenter og<strong> ræsonnerer ulogisk</strong>',
  'symptom-survey.question.patienten-agree-with-symptom-survey': '<strong>Er patienten enig i de punkter, du har angivet i symptomundersøgelsen?</strong>',
  'symptom-survey.question.performing-everyday-tasks': '<strong>udfører daglige praktiske opgaver</strong> tilstrækkeligt på trods af mobilitet såsom påklædning, husarbejde, bilkørsel eller brug af forskellige husholdningsapparater',
  'symptom-survey.question.reacts-slowly': '<strong>reagerer langsommere.</strong> Har svært ved at øge tempoet og gøre det, der skal til, når der pludselig sker noget',
  'symptom-survey.question.recognizing-objects': '<strong>genkende</strong> hverdagsgenstande, billeder, symboler eller ansigter af velkendte personer',
  'symptom-survey.question.space-for-comments': '<strong>Plads til kommentarer</strong>',
  'symptom-survey.question.speaks-less': '<strong>taler mindre</strong> tydeligt, fordi talen er blevet sløret eller fordi stemmen er blevet svagere',
  'symptom-survey.question.stress-for-the-family': 'Har nogen af de fremhævede symptomer forårsaget nogen<strong> stress for familien eller de pårørende?</strong>',
  'symptom-survey.question.symptoms-variations-day-to-day': '<strong>Varierer symptomerne fra dag til dag?</strong>',
  'symptom-survey.question.thinks-slower': '<strong>tænker langsommere</strong> og skal tænke længere, før der svares',
  'symptom-survey.question.understanding-heard-read': '<strong>forstå, hvad der høres eller læses,</strong> for eksempel indholdet af breve, aviser eller programmer på radio/tv',
  'symptom-survey.question.way-symptoms-develop': '<strong>På hvilken måde udviklede symptomerne sig?</strong>',
  'symptom-survey.question.writing': '<strong>skrivning</strong> , for eksempel at skrive breve, beskeder eller udfylde formularer',
  'symptom-survey.results.evident-specific-event-symptoms': 'Specifik hændelse',
  'symptom-survey.results.first-noticed-symptoms': 'Første symptomer',
  'symptom-survey.results.hard-to-manage-symptoms': 'Symptomer, der er svære at håndtere',
  'symptom-survey.results.most-noticeable-symptoms': 'Mest mærkbare symptomer',
  'symptom-survey.results.patienten-agree-with-symptom-survey': 'Patienten er enig i beskrivelsen',
  'symptom-survey.results.stress-for-the-family': 'Symptomer er stressende for andre',
  'symptom-survey.section.mental-reactions-section.subtitle': 'Patienten...',
  'symptom-survey.section.mental-reactions-section.title': 'Almindelige mentale reaktioner og andre associerede symptomer på sygdomme, der påvirker hjernen',
  'symptom-survey.section.other-questions-section.subtitle': 'Patienten...',
  'symptom-survey.section.other-questions-section.title': 'Andre supplerende spørgsmål vedrørende observerede symptomer',
  'symptom-survey.section.subcortical-regions-of-brain-section.subtitle': 'Patienten...',
  'symptom-survey.section.subcortical-regions-of-brain-section.title': 'Almindelige symptomer på nedsat funktion i de subkortikale områder af hjernen',
  'symptom-survey.section.symptoms-of-impaired-function-in-frontal-lobes-of-brain.subtitle': 'Patienten har...',
  'symptom-survey.section.symptoms-of-impaired-function-in-frontal-lobes-of-brain.title': 'Almindelige symptomer på nedsat funktion i hjernens frontallapper',
  'symptom-survey.section.symptoms-of-impaired-function-in-parietal-section.subtitle': 'Patienten har svært ved...',
  'symptom-survey.section.symptoms-of-impaired-function-in-parietal-section.title': 'Almindelige symptomer på nedsat funktion i hjernens parietal- og temporallapper',
  'symptom-survey.section.symptoms-of-impaired-memory-section.subtitle': 'Patienten har...',
  'symptom-survey.section.symptoms-of-impaired-memory-section.title': 'Almindelige symptomer på nedsat hukommelsesfunktion',
  'thePatient': 'patienten',
  'thePatientCapitalized': 'patienten',
  'word.behaviour': 'adfærd',
  'word.of': 'af',
  'word.pleaseWait': 'Vent venligst',
  'word.section': 'Afsnit',
};
 export default trans;