import React from "react"
import { AnswerDTO, InputType, QuestionScreenDTO, SelectorInputMetadata } from "../../sdk"
import useTranslate from "../../hooks/useTranslate"
import { translate } from "../../store/Translations/translations"

type QuestionAnswerComponentProps = {
  question: QuestionScreenDTO;
  answer: Pick<AnswerDTO, 'value'>;
  questionNumber: number;
  patientName: string;
}

const QuestionAnswerComponent: React.FC<QuestionAnswerComponentProps> = (props: QuestionAnswerComponentProps) => {
  const translateLocalazy = useTranslate()
  const {questionNumber, answer, question, patientName} = props
  const selectedAnswer = (question.metadata.input.kind === InputType.FREE_TEXT ? answer.value :
    (question.metadata.input.metadata as SelectorInputMetadata).values.find(e => e.value === answer.value as any )?.label) || 'hcp.Answer was not provided!'
  const title = translateLocalazy(question.metadata.title).replaceAll('<strong>', '').replaceAll('</strong>', '')
  const needsPatientName = title.trim().charAt(0).toUpperCase() !== title.trim().charAt(0)
  return (
    <div className="question-answer-wrapper break_inside--avoid_page" data-slug={question.slug}>
      <h3>
        {`${questionNumber ? questionNumber + '.' : ''} ${needsPatientName ? patientName : ''} ${title}`}
      </h3>
      <div className="answers-wrapper">
        <dl className="answer-item">
          <dt style={{color: '#000'}}>{translateLocalazy(String(selectedAnswer))}</dt>
        </dl>
      </div>
    </div>
  )
}

export default QuestionAnswerComponent
